import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  Modal,
  Alert,
  Platform
} from 'react-native'
import { THEME } from '../../../theme'
import {
  Collapse,
  CollapseHeader,
  CollapseBody
} from 'accordion-collapse-react-native'
import Slider from '@react-native-community/slider'
import { Formik } from 'formik'
import { DatePickerField } from '../DatePickerField'
import { AntDesign } from '@expo/vector-icons'
import {
  getTargetsBySectorId,
  removeTargetById
} from '../../../screens/wheel/wheelService'
import { WheelContext } from '../../../context/wheel/wheelContext'
import { AppLoader } from '../AppLoader'
import moment from 'moment'
import { SCButton } from '../button/SCButton'

export const SCTargetAccordion = ({
  style,
  value,
  title,
  color,
  circleBalanceSectorId,
  id,
  score,
  userId,
  ...props
}) => {
  const { wheel, wheelUpdateSliderValue, wheelUpdateTargets } =
    useContext(WheelContext)

  const [isLoading, setIsLoading] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const [sliderValue, setSliderValue] = useState(value)
  const [tasksValue, setTasksValue] = useState([])

  const [localError, setLocalError] = useState('')

  const dataFormater = (arrayFromAPI) => {
    const updatedArray = []
    arrayFromAPI.forEach((x) => {
      const date =
        new Date(x.deadlineDate).getTime() > new Date(2000, 1, 1).getTime()
          ? new Date(x.deadlineDate)
          : moment().add(1, 'y').date()

      updatedArray.push({
        id: x.id,
        localId: x.id,
        title: x.description,
        deadlineDate: date
      })
    })
    return updatedArray
  }

  const loadCurrentSector = useCallback(async () => {
    setIsLoading(true)
    const response = await getTargetsBySectorId(circleBalanceSectorId)

    const tasksFromAPI = dataFormater(response)

    setTasksValue(tasksFromAPI)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    loadCurrentSector()
  }, [])

  const onChange = (inputValue) => {
    setSliderValue(inputValue)
    value = inputValue
  }
  const onAccodrionToggle = (inputValue) => {
    setIsExpanded(inputValue)
  }
  const onTargetTextChanged = (id, text) => {
    const editedArray = [...tasksValue]
    editedArray.find((x) => x.localId === id).title = text
    setTasksValue(editedArray)
  }

  const onDateChanged = (id, deadlineDate) => {
    // проверка даты, дата должна быть больше 1 года, от текущей даты
    const rightDate = moment().add(1, 'y')
    const selectedDate = moment(new Date(deadlineDate))

    if (selectedDate.isBetween(moment(), rightDate)) {
      const editedArray = [...tasksValue]
      editedArray.find((x) => x.localId === id).deadlineDate = deadlineDate
      setTasksValue(editedArray)

      if (tasksValue.find((x) => x.localId === id).title !== '') {
        updateTasks()
      }
    } else {
      if (Platform.OS === 'web') {
        alert('Вы должны выбрать дату, которая будет в пределах одного года.')
      } else {
        Alert.alert(
          'Ошибка',
          'Вы должны выбрать дату, которая будет в пределах одного года.'
        )
      }
    }
  }

  const onAdd = () => {
    if (tasksValue.length < 3) {
      setTasksValue([
        ...tasksValue,
        {
          id: null,
          localId: generateKey(),
          title: '',
          deadlineDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1
          )
        }
      ])
    }
  }
  const onRemove = async (id) => {
    setIsLoading(true)
    const removedItem = tasksValue.find((x) => x.localId === id)
    if (removedItem.id) {
      await removeTargetById(removedItem.id)
    }

    const updatedTasksValue = tasksValue.filter((x) => x.localId !== id)
    setTasksValue(updatedTasksValue)

    setIsLoading(false)
  }

  // BACK LOGIC
  const updateSlider = async () => {
    setIsLoading(true)
    const data = {
      score: sliderValue,
      sectorBalanceId: circleBalanceSectorId
    }
    const updatedWheel = [...wheel]

    updatedWheel.find(
      (x) => x.circleBalanceSectorId === circleBalanceSectorId
    ).score = sliderValue
    updatedWheel.find(
      (x) => x.circleBalanceSectorId === circleBalanceSectorId
    ).isSliderChanged = true
    await wheelUpdateSliderValue(data, updatedWheel)
    setIsLoading(false)
  }

  const updateTasks = async () => {
    setIsLoading(true)
    setLocalError('')
    const data = []
    tasksValue.forEach((x) => {
      data.push({
        id: x.id,
        description: x.title,
        deadlineDate:
          moment(
            new Date(
              new Date(x.deadlineDate).getFullYear(),
              new Date(x.deadlineDate).getMonth(),
              new Date(x.deadlineDate).getDate()
            )
          ).format('YYYY-MM-DDT00:00:00.000') + 'Z',
        sectorBalanceId: circleBalanceSectorId
      })
    })
    const targetsUpdated = await wheelUpdateTargets(data)
    console.log('xxxxxxxxxxxxxxxxxxxxxxx', targetsUpdated)
    if (targetsUpdated.status === 'Error') {
      let errorMessage = ''
      console.log(Object.entries(targetsUpdated.message))

      for (const [key, value] of Object.entries(targetsUpdated.message)) {
        targetsUpdated.message[key].forEach((error) => {
          errorMessage += `${error}; `
        })
      }

      setLocalError(errorMessage)
    } else {
      const tasksFromAPI = dataFormater(targetsUpdated)
      setTasksValue(tasksFromAPI)
      console.log('good')
    }
    setIsLoading(false)
  }

  const generateKey = () => {
    let firstPart = (Math.random() * 46656) | 0
    let secondPart = (Math.random() * 46656) | 0
    firstPart = ('000' + firstPart.toString(36)).slice(-3)
    secondPart = ('000' + secondPart.toString(36)).slice(-3)
    return firstPart + secondPart
  }

  return (
    <View>
      {localError !== '' && !isLoading && (
        <Modal
          animationType="none"
          transparent={true}
          visible={localError !== ''}
        >
          <View style={styles.center}>
            <View style={styles.modalError}>
              <Text style={styles.modalErrorContent}>{localError}</Text>
              <SCButton onPress={() => setLocalError('')} text={'Ок'} />
            </View>
          </View>
        </Modal>
      )}

      {isLoading && (
        <Modal animationType="none" transparent={true} visible={isLoading}>
          <View style={styles.center}>
            <View style={styles.modalLoader}>
              <AppLoader />
            </View>
          </View>
        </Modal>
      )}

      <Collapse style={styles.accordion} onToggle={onAccodrionToggle}>
        <CollapseHeader>
          <View style={styles.headerRow}>
            <View style={styles.headerTitle}>
              <View style={[styles.circle, { backgroundColor: color }]}></View>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={styles.titleWidth}
              >
                {title}
              </Text>
            </View>
            <Text>
              {isExpanded
                ? (
                  <AntDesign name="up" size={16} color="black" />
                )
                : (
                  <AntDesign name="down" size={16} color="black" />
                )}
            </Text>
          </View>
        </CollapseHeader>
        <CollapseBody style={styles.accordionBody}>
          <Slider
            style={{ width: '100%', height: 40 }}
            step={1}
            minimumValue={1}
            maximumValue={10}
            minimumTrackTintColor="black"
            maximumTrackTintColor="black"
            value={sliderValue}
            onValueChange={onChange}
            onSlidingComplete={updateSlider}
          />
          <Text style={styles.currentTargetValue}>
            Текущая оценка: {sliderValue}
          </Text>
          <View>
            {tasksValue.map((task) => (
              <View key={task.localId} style={styles.targetRow}>
                <View style={styles.dataBlock}>
                  <TextInput
                    allowFontScaling={false}
                    style={styles.inputText}
                    placeholder="Введите цель на год"
                    onChangeText={(text) =>
                      onTargetTextChanged(task.localId, text)
                    }
                    onBlur={updateTasks}
                    value={task.title}
                  />
                  <View style={styles.dateStyles}>
                    <Text style={styles.dateRowStyles}>Дата достижения</Text>
                    <Formik
                      initialValues={{
                        deadlineDate: task.deadlineDate
                          ? new Date(task.deadlineDate)
                          : moment().date()
                      }}
                    >
                      {({ handleChange, handleBlur, handleSubmit, values }) => (
                        <DatePickerField
                          inputStyle={styles.inputStyle}
                          inputValueStyle={styles.inputValueStyle}
                          inputPlaceholderStyle={styles.inputPlaceholderStyle}
                          style={styles.marginInputs}
                          onChangeText={handleChange('deadlineDate')}
                          onSelectOption={(date) =>
                            onDateChanged(task.localId, date)
                          }
                          onBlur={handleBlur('deadlineDate')}
                          value={values.deadlineDate}
                          name="deadlineDate"
                        />
                      )}
                    </Formik>
                  </View>
                </View>
                <TouchableOpacity
                  onPress={() => onRemove(task.localId)}
                  style={styles.targetRowAdd}
                >
                  <AntDesign name="close" size={16} color="black" />
                </TouchableOpacity>
              </View>
            ))}
          </View>
          {tasksValue.length !== 3 && (
            <TouchableOpacity onPress={onAdd} style={styles.targetRowAdd}>
              <Text style={styles.targetRowAddText}>
                Добавить цель на год (максимум 3)
              </Text>
              <Text style={styles.targetRowAddText}>+</Text>
            </TouchableOpacity>
          )}
        </CollapseBody>
      </Collapse>
    </View>
  )
}

const styles = StyleSheet.create({
  accordion: {
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 2,
    marginBottom: 15,
    paddingHorizontal: 14,
    paddingVertical: 7
  },
  accordionBody: {
    paddingBottom: 10,
    paddingTop: 26
  },
  action: {
    alignItems: 'center',
    backgroundColor: '#eae9e9',
    elevation: 24,
    height: 120,

    paddingTop: 30,
    shadowColor: '#eae9e9',
    shadowOffset: {
      width: 0,
      height: -70
    },
    shadowOpacity: 1,
    shadowRadius: 40,

    width: '100%'
  },
  center: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginTop: 22
  },
  circle: {
    backgroundColor: 'black',
    borderRadius: 5,
    height: 10,
    marginRight: 5,
    width: 10
  },
  currentTargetValue: {
    marginBottom: 20
  },
  dataBlock: {
    marginBottom: 6,
    width: '80%'
  },
  dateRowStyles: {
    marginRight: 5
  },
  dateStyles: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerTitle: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  input: {
    height: 20,
    textAlign: Platform.OS === 'android' ? 'left' : 'left',
    width: Platform.OS === 'android' ? '80%' : '80%'
  },
  inputPlaceholderStyle: {},
  inputStyle: {
    borderWidth: 0,
    height: 20,
    marginBottom: 0,
    paddingLeft: 0,
    paddingVertical: 0,
    width: '100%'
  },
  inputText: {
    fontSize: 14,
    // width: Platform.OS === "android" ? "80%" : "80%",
    textAlign: Platform.OS === 'android' ? 'left' : 'left',
    // height: 20,
    width: 300
  },
  inputValueStyle: {},
  modalError: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    elevation: 5,
    height: 300,
    margin: 20,
    padding: 35,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    width: '90%'
  },
  modalErrorContent: {
    flex: 1
  },
  modalLoader: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    elevation: 5,
    height: 150,
    margin: 20,
    padding: 35,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    width: 150
  },
  targetRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  targetRowAdd: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  targetRowAddText: {
    fontSize: 16,
    paddingTop: 14
  },
  titleWidth: {
    flexWrap: 'nowrap',
    fontSize: 14,
    maxWidth: 260
  }
})
