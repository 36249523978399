import React, { useContext, useEffect } from 'react'
import { View, Text, StyleSheet, Platform, ScrollView } from 'react-native'
import { THEME } from '../../../theme'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { AntDesign } from '@expo/vector-icons'
import { AppLoader } from '../../../components/ui/AppLoader'
import { InsideContext } from '../../../context/inside/insideContext'
import moment from 'moment'

export const InsitesScreen = ({ navigation }) => {
  const { insideList, getInsidelist, loading } = useContext(InsideContext)

  useEffect(() => {
    getInsidelist()
  }, [])

  const toInsite = (item) => {
    navigation.navigate('Insite', { currentItemId: item.id })
  }

  if (loading) {
    return <AppLoader />
  } else {
    console.log('INSIDES: ', insideList)
  }

  return (
    <View>
      <ScrollView style={styles.taskList}>
        {insideList &&
          insideList.map((item) => (
            <TouchableOpacity
              key={item.id}
              onPress={() => toInsite(item)}
              style={styles.taskRow}
              style={styles.taskRow}
            >
              <Text>{item.title}</Text>
              <Text>{moment(item.dateCreated).format('DD.MM.YYYY')}</Text>
            </TouchableOpacity>
          ))}
      </ScrollView>
    </View>
  )
}

InsitesScreen.navigationOptions = ({ navigation }) => {
  const onAdd = () => {
    navigation.navigate('InsiteAdd', {
      isEdit: false
    })
  }

  return {
    headerTitle: 'Инсайты',
    headerRight: () => (
      <TouchableOpacity style={styles.headerRight} onPress={onAdd}>
        <AntDesign
          name="plus"
          size={24}
          color={Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR}
        />
      </TouchableOpacity>
    )
  }
}
const styles = StyleSheet.create({
  blur: {
    backgroundColor: 'black',
    height: 200
  },
  headerRight: {
    marginRight: 26
  },
  taskList: {
    flexDirection: 'column',
    padding: 18
  },
  taskRow: {
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 2,
    color: THEME.MAIN_DARK,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 18,
    padding: 14
  },
  taskRowActive: {
    color: THEME.ACCENT_PINK
  },
  taskRowCompleted: {
    color: '#C9C9C9'
  }
})
