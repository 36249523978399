import React, { useContext, useEffect } from 'react'
import {
  View,
  TextInput,
  StyleSheet,
  ScrollView,
  Keyboard,
  Alert,
  Platform
} from 'react-native'
import { SCButton } from '../../../components/ui/button/SCButton'
import { InsideContext } from '../../../context/inside/insideContext'
import bodyStyles from '../../../styles/bodyStyles'
import { AppLoader } from '../../../components/ui/AppLoader'

export const InsiteAddScreen = ({ navigation }) => {
  const { addInsideItem, loading, error, success, clearSuccess, clearError } =
    useContext(InsideContext)
  const [value, onChangeText] = React.useState()

  const apply = () => {
    console.log('VALUES', value)
    const title = value.split('\n')[0].substring(0, 17) + '...'
    const data = {
      dateCreated: new Date().toISOString(),
      title,
      notice: value
    }
    addInsideItem(data, navigation)
    Keyboard.dismiss()
  }

  useEffect(() => {
    if (error) {
      if (Platform.OS === 'web') {
        alert(error)
        clearError()
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  useEffect(() => {
    if (success) {
      if (Platform.OS === 'web') {
        alert('Данные успешно обновлены!')
        clearSuccess()
        navigation.navigate('Insites')
      } else {
        Alert.alert('Успешно!', 'Данные успешно обновлены!', [
          {
            text: 'Ок',
            onPress: () => {
              clearSuccess()
              navigation.navigate('Insites')
            },
            style: 'default'
          }
        ])
      }
    }
  }, [success])

  if (loading) {
    return <AppLoader />
  }

  return (
    <View style={bodyStyles.main}>
      <ScrollView style={styles.scrollView}>
        <View style={styles.minHeight}>
          <TextInput
            multiline
            onChangeText={(text) => onChangeText(text)}
            placeholder={'Напишите свои мысли...'}
            value={value}
            allowFontScaling={false}
            numberOfLines={20}
          ></TextInput>
        </View>
      </ScrollView>

      <View style={styles.action}>
        <SCButton onPress={apply} text="Сохранить" />
      </View>
    </View>
  )
}

InsiteAddScreen.navigationOptions = {
  headerTitle: 'Создание инсайта'
}

const styles = StyleSheet.create({
  // new
  scrollView: {
    paddingHorizontal: 10,
    paddingTop: 20
  },
  minHeight: {
    height: 620
  },
  action: {
    alignItems: 'center',
    backgroundColor: '#eae9e9',
    elevation: 24,
    height: 120,

    paddingTop: 30,
    shadowColor: '#eae9e9',
    shadowOffset: {
      width: 0,
      height: -70
    },
    shadowOpacity: 1,
    shadowRadius: 40,

    width: '100%'
  }
})
