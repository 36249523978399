export class Http {
  static HEADERS = { 'Content-Type': 'application/json' }

  static async fetchBlob (url, token) {
    const method = 'GET'
    let headers
    if (token) {
      headers = {
        Authorization: `Bearer ${token}`
      }
    }

    const config = { method, headers }

    const response = await fetch(url, config)
    const blob = await response.blob()
    const objectUrl = URL.createObjectURL(blob)
    return objectUrl
  }

  static async get (url, token) {
    console.log(
      `====================== GET  TO: ${url} =============================`
    )
    try {
      return await request(url, 'GET', null, token)
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  static async post (url, data = {}, token) {
    try {
      console.log(
        `====================== POST TO: ${url} =============================`
      )
      return await request(url, 'POST', data, token)
    } catch (e) {
      console.log('ERROR: ', e)
      throw e
    }
  }

  static async uploadFile (url, data = {}, token) {
    try {
      console.log(
        `====================== POST TO: ${url} =============================`
      )
      return await requestMultipart(url, data, token)
    } catch (e) {
      console.log('ERROR: ', e)
      throw e
    }
  }

  static async downloadFile (url, token) {
    console.log(
      `====================== DOWNLOAD FILE TO: ${url} =============================`
    )
    try {
      return await requestDownload(url, token)
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  static async put (url, data = {}, token) {
    try {
      console.log(
        `====================== PUT TO: ${url} =============================`
      )
      return await request(url, 'PUT', data, token)
    } catch (e) {
      console.log('ERROR: ', e)
      throw e
    }
  }

  static async delete (url, token) {
    try {
      console.log(
        `====================== DELETE TO: ${url} =============================`
      )
      await request(url, 'DELETE', null, token)
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  static async path (url, data = {}, token) {
    try {
      return await request(url, 'PATCH', data, token)
    } catch (e) {
      console.log(e)
      throw e
    }
  }
}

async function request (url, method = 'GET', data, token) {
  let headers
  if (token) {
    headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  } else {
    headers = Http.HEADERS
  }
  console.log('HEADERS: ', headers)

  const config = { method, headers }

  if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
    if (data !== null) {
      config.body = JSON.stringify(data)
      console.log('BODY: ', JSON.stringify(data))
    }
  }

  const response = await fetch(url, config)

  if (method !== 'DELETE') return await response.json()
}

async function requestMultipart (url, data, token) {
  const method = 'POST'
  let headers
  if (token) {
    headers = {
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  }

  console.log('HEADERS: ', headers)

  const config = { method, headers, body: data }

  // config.body = data // мультипарт, в JSON не надо складывать
  console.log('BODY: ', JSON.stringify(data))

  const response = await fetch(url, config)
  console.log('-----------------------------------')
  console.log(response)
  console.log('-----------------------------------')
  return await response.json()
}

async function requestDownload (url, token) {
  const method = 'GET'
  let headers
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`
    }
  }

  console.log('HEADERS: ', headers)

  const config = { method, headers }

  const response = await fetch(url, config)
  return response.text()
}
