import React, { useContext, useEffect } from 'react'
import {
  View,
  StyleSheet,
  ScrollView,
  Alert,
  Platform,
  useWindowDimensions
} from 'react-native'
import { AppLoader } from '../../../components/ui/AppLoader'
import { SCButton } from '../../../components/ui/button/SCButton'
import { SCLabel } from '../../../components/ui/inputs/SCLabel'
import { DiaryContext } from '../../../context/diary/diaryContext'
import moment from 'moment'

export const TargetViewScreen = ({ navigation }) => {
  const {
    updateTargetItemStatus,
    success,
    error,
    clearError,
    clearSuccess,
    loading
  } = useContext(DiaryContext)

  const currentItem = navigation.getParam('currentItem')
  const viewType = navigation.getParam('viewType')
  const { height } = useWindowDimensions()

  console.log('NAVIGATION OPTION ITEM: ', currentItem)
  console.log('NAVIGATION OPTION VIEW: ', viewType)

  const complete = async () => {
    await updateTargetItemStatus(currentItem.id, 'complete', null, null)
  }

  const fail = async () => {
    await updateTargetItemStatus(currentItem.id, 'fail', null, null)
  }

  const close = () => {
    navigation.navigate('Targets')
  }

  useEffect(() => {
    if (success) {
      if (Platform.OS === 'web') {
        alert('Данные успешно обновлены!')
        clearSuccess()
        navigation.navigate('Targets')
      } else {
        Alert.alert('Успешно!', 'Данные успешно обновлены!', [
          {
            text: 'Ок',
            onPress: () => {
              clearSuccess()
              navigation.navigate('Targets')
            },
            style: 'default'
          }
        ])
      }
    }
  }, [success])

  useEffect(() => {
    if (error) {
      if (Platform.OS === 'web') {
        alert(error)
        clearError()
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  const completeView = (
    <ScrollView contentContainerStyle={{
      paddingTop: height < 700 ? 40 : 0
    }}>
      <View style={{ ...styles.body, maxHeight: height < 700 ? 500 : 'auto' }}>
        <View style={styles.form}>
          <SCLabel
            style={styles.marginInputs}
            label="Название"
            disabled={true}
            value={currentItem.description}
          />
          <SCLabel
            style={styles.marginInputs}
            label="Область"
            disabled={true}
            value={currentItem.circleBalanceSectorName}
          />
          <SCLabel
            label="Дата дедлайна"
            value={moment(currentItem.deadlineDate).format('DD.MM.YYYY')}
            disabled={true}
            style={styles.marginInputs}
          />
          <SCLabel
            label="Статус"
            value={currentItem.status}
            disabled={true}
            style={styles.marginButton}
          />
          <View>
            <SCButton
              text="Закрыть"
              style={styles.marginInputs}
              onPress={close}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  )

  const inprogressView = (
    <ScrollView contentContainerStyle={{
      paddingTop: height < 700 ? 40 : 0
    }}>
      <View style={{ ...styles.body, maxHeight: height < 700 ? 500 : 'auto' }}>
        <View style={styles.form}>
          <SCLabel
            style={styles.marginInputs}
            label="Название"
            disabled={true}
            value={currentItem.description}
          />
          <SCLabel
            style={styles.marginInputs}
            label="Область"
            disabled={true}
            value={currentItem.circleBalanceSectorName}
          />
          <SCLabel
            label="Дата дедлайна"
            value={moment(currentItem.deadlineDate).format('DD.MM.YYYY')}
            disabled={true}
            style={styles.marginInputs}
          />
          <SCLabel
            label="Статус"
            value={currentItem.status}
            disabled={true}
            style={styles.marginButton}
          />

          <View>
            <SCButton
              text="Не сделал"
              style={styles.marginInputs}
              onPress={fail}
            />
            <SCButton
              text="Сделал"
              style={styles.marginInputs}
              onPress={complete}
            />

            <SCButton
              text="Закрыть"
              style={styles.marginInputs}
              onPress={close}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  )

  if (loading) {
    return <AppLoader />
  }

  return (
    <View style={styles.center}>
      {viewType === 'inprogress' && inprogressView}
      {viewType === 'completed' && completeView}
    </View>
  )
}

TargetViewScreen.navigationOptions = ({ navigation }) => {
  return {
    headerTitle: 'Просмотр Цели'
  }
}

const styles = StyleSheet.create({
  center: {
    display: 'flex',
    padding: 20,
    width: '100%'
  },
  title: {
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 30,
    textAlign: 'center'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  // NEW

  body: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 40
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%'
  },
  form: {
    alignItems: 'center',
    minHeight: 300,
    width: '100%'
  },
  marginButton: {
    marginBottom: 41
  },
  marginInputs: {
    marginBottom: 13
  }
})
