import {
  GET_USERS_USE_PROMO_COD,
  GET_COUNT_USE_PROMO_COD,
  GET_PROMO_CODS,
  UPDATE_PRICE,
  HIDE_LOADER,
  SHOW_LOADER,
  SHOW_SUCCESS,
  CLEAR_SUCCESS,
  SHOW_ERROR,
  CLEAR_ERROR
} from '../types'

const handlers = {
  [GET_USERS_USE_PROMO_COD]: (state, action) => ({
    ...state,
    usersList: action.usersList
  }),
  [GET_COUNT_USE_PROMO_COD]: (state, action) => ({
    ...state,
    countUsers: action.countUsers
  }),
  [GET_PROMO_CODS]: (state, action) => ({
    ...state,
    promoCodsList: action.promoCodsList
  }),
  [UPDATE_PRICE]: (state, action) => ({
    ...state,
    price: action.price
  }),

  [SHOW_LOADER]: (state) => ({ ...state, loading: true }),
  [HIDE_LOADER]: (state) => ({ ...state, loading: false }),
  [CLEAR_ERROR]: (state) => ({ ...state, error: null }),
  [SHOW_ERROR]: (state, { error }) => ({ ...state, error }),
  [SHOW_SUCCESS]: (state) => ({ ...state, success: true }),
  [CLEAR_SUCCESS]: (state) => ({ ...state, success: false }),
  DEFAULT: (state) => state
}

export const adminReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT
  return handler(state, action)
}
