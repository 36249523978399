import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View, StyleSheet, ScrollView, Alert, Platform } from 'react-native'
import { ExerciseContext } from '../../context/exercise/exerciseContext'
import { AppLoader } from '../../components/ui/AppLoader'
import { SCButton } from '../../components/ui/button/SCButton'
import bodyStyles from '../../styles/bodyStyles'
import { TaskVideoCard } from './TaskVideoCard'
import { useFocusEffect } from 'react-navigation-hooks'

export const TasksScreen = ({ navigation }) => {
  const [isUnfocusedParentProp, setIsUnfocused] = useState(false)
  const exerciseNumber = navigation.getParam('exerciseNumber')
  const groupId = navigation.getParam('groupId')
  const isComplete = navigation.getParam('isComplete')

  const {
    exerciseTasks,
    completeTask,
    success,
    error,
    clearError,
    clearSuccess,
    getExerciseTasksList,
    loading
  } = useContext(ExerciseContext)

  const loadTasks = useCallback(
    async () => await getExerciseTasksList(exerciseNumber),
    [getExerciseTasksList]
  )

  useEffect(() => {
    loadTasks()
  }, [])

  useEffect(() => {
    if (success) {
      if (Platform.OS === 'web') {
        alert('Вы открыли новые упражнения!')
        clearSuccess()
      } else {
        Alert.alert('Успешно!', 'Вы открыли новые упражнения!', [
          {
            text: 'Ок',
            onPress: () => {
              clearSuccess()
            },
            style: 'default'
          }
        ])
      }
    }
  }, [success])

  useEffect(() => {
    if (error) {
      if (Platform.OS === 'web') {
        alert(error)
        clearError()
        navigation.navigate('Main')
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
              navigation.navigate('Main')
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  useFocusEffect(
    useCallback(() => {
      setIsUnfocused(false)
      return () => {
        setIsUnfocused(true)
      }
    }, [])
  )

  const apply = async () => {
    completeTask(exerciseNumber, groupId, navigation)
  }

  if (loading) {
    return <AppLoader />
  }

  return (
    <View style={bodyStyles.main}>
      <ScrollView style={styles.tasks}>
        <View style={styles.minHeight}>
          {exerciseTasks &&
            exerciseTasks.length !== 0 &&
            exerciseTasks.map((task) => (
              <TaskVideoCard
                key={task.id}
                task={task}
                isUnfocusedProp={isUnfocusedParentProp}
              />
            ))}
        </View>
      </ScrollView>
      {!isComplete && !error && (
        <View style={styles.action}>
          <SCButton onPress={apply} text="Посмотрел" />
        </View>
      )}
    </View>
  )
}

TasksScreen.navigationOptions = {
  headerTitle: 'Упражнения'
}

const styles = StyleSheet.create({
  tasks: {
    flexDirection: 'column',
    height: 620,
    padding: 18
  },
  // NEW
  minHeight: {
    height: 820
  },
  action: {
    alignItems: 'center',
    backgroundColor: '#eae9e9',
    elevation: 24,
    height: 120,

    paddingTop: 30,
    shadowColor: '#eae9e9',
    shadowOffset: {
      width: 0,
      height: -70
    },
    shadowOpacity: 1,
    shadowRadius: 40,

    width: '100%'
  }
})
