import { THEME } from '../../../theme';
import { KeyboardAvoidingView, Platform, ScrollView, Text, TouchableOpacity, View, Button } from 'react-native';
import React from 'react';
import { SCButton } from '../../../components/ui/button/SCButton';

export const ChooseSubscription = ({ navigation }) => {
  const level = navigation.getParam('level');

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : null} style={{ height: '100%', justifyContent: 'center' }}>
      <ScrollView style={styles.mainScroll}>
        {level && (
          <View style={styles.body}>
            <View style={styles.card}>
              <Text style={{ textAlign: 'center', fontSize: 15, color: '#616161', marginBottom: 15 }}>
                Вы должны купить доступ к следующему
              </Text>
              <SCButton onPress={() => navigation.navigate('Subscription', { level })} text={`Купить уровень ${level}`} />
            </View>
          </View>
        )}
        {!level && (
          <View style={styles.body}>
            <TouchableOpacity style={styles.card}>
              <Text style={{ textAlign: 'center', fontSize: 15, color: '#616161', marginBottom: 15 }}>
                Вы можете купить доступ к уровню 1 или курс полностью
              </Text>
              <View>
                <SCButton
                  onPress={() => {
                    navigation.navigate('Subscription', { level: 1 });
                  }}
                  text={'КУПИТЬ УРОВЕНЬ 1'}
                />
              </View>
              <View>
                <SCButton
                  onPress={() => {
                    navigation.navigate('Subscription', { level: 0 });
                  }}
                  text="КУПИТЬ КУРС ПОЛНОСТЬЮ"
                />
              </View>
              <View>
                <SCButton
                  onPress={() => {
                    navigation.navigate('Subscription', { level: 0, installment: true });
                  }}
                  text="КУПИТЬ КУРС В РАССРОЧКУ"
                />
              </View>
            </TouchableOpacity>
          </View>
        )}
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

ChooseSubscription.navigationOptions = {
  headerTitle: 'Выберите подписку',
};

const styles = {
  price: {
    marginBottom: 8,
    marginTop: 5,
  },
  textPromoCode: {
    color: THEME.MAIN_DARK,
    marginBottom: 5,
    marginTop: 10,
  },
  textInputPromoCode: {
    borderRadius: 10,
    borderWidth: 1,
    width: 100, // 100
    paddingVertical: 5,
    borderColor: THEME.MAIN_DARK,
    fontSize: 14,
    marginBottom: 0,
    marginLeft: 10,
    paddingLeft: 15,
    // alignItems: "left",
  },
  inputContainer: {
    flexDirection: 'row',
    textAlign: 'left',
    marginTop: 20,
    marginBottom: 10,
  },

  checkboxContainer: {
    width: 300,
    flexDirection: 'row',

    marginTop: 0,
    marginLeft: 80,
    marginBottom: 0,
  },
  textDoc: {
    textDecorationLine: 'underline',
    textDecorationColor: 'gray',
    fontSize: 12,
    marginTop: 10,
    textAlign: 'left',
    color: '#262626',
  },
  body: {
    marginTop: Platform.OS === 'ios' ? 20 : 50,
    paddingTop: 35,
    backgroundColor: THEME.MAIN_GREY,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    marginBottom: 15,
    maxWidth: 400,
    width: '85%',
    height: '100%', // 380
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  mainScroll: {
    width: '100%',
    height: '100%',
    backgroundColor: THEME.MAIN_GREY,
  },
  button: {
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    paddingVertical: 15,
    width: 270,
  },
};
