import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  ScrollView,
  Alert,
  Platform
} from 'react-native'
import { WheelContext } from '../../../context/wheel/wheelContext'
import { BlurView } from 'expo-blur'
import { Formik, getIn, useFormikContext } from 'formik'
import { DatePickerField } from '../../../components/ui/DatePickerField'
import { DropdownField } from '../../../components/ui/DropdownField'
import { AppLoader } from '../../../components/ui/AppLoader'
import { getTargetsBySectorId } from '../../../screens/wheel/wheelService'
import { DiaryContext } from '../../../context/diary/diaryContext'
import { SCInput } from '../../../components/ui/inputs/SCInput'
import { SCButton } from '../../../components/ui/button/SCButton'

export const StepAddScreen = ({ navigation }) => {
  const {
    success,
    clearSuccess,
    addDiaryItem,
    loading,
    showLoader,
    hideLoader,
    error,
    clearError
  } = useContext(DiaryContext)
  const { wheelGetExt } = useContext(WheelContext)

  const [areas, setAreas] = useState([])
  const [tasks, setTasks] = useState([])
  const [loadingTarget, setLoadingTarget] = useState(false)

  const [currentForm, setCurrentForm] = useState({
    date: new Date(), // дата
    area: '', // область сферы - дропдаун
    targetName: '', // цель - дропдаун
    name: '', // название шага
    stepTime: new Date()
  })

  const loadWheel = useCallback(async () => {
    showLoader()
    const wheelFromAPI = await wheelGetExt()
    const convertedAreas = []
    wheelFromAPI.forEach((x) => {
      convertedAreas.push({ value: x.id, label: x.name })
    })
    setAreas(convertedAreas)
    hideLoader()
  }, [wheelGetExt])

  useEffect(() => {
    loadWheel()
  }, [])

  const getTargets = async (circleBalanceSectorId) => {
    setLoadingTarget(true)
    try {
      const response = await getTargetsBySectorId(circleBalanceSectorId)
      const tasks = response.map((x) => ({
        value: x.id,
        label: x.description,
        deadlineDate: x.deadlineDate
      }))
      setTasks(tasks)
    } catch (error) {
      alert(error)
    } finally {
      setLoadingTarget(false)
    }
  }

  const apply = (values) => {
    if (!values.date || !values.area || !values.targetName || !values.name || !values.stepTime) {
      if (Platform.OS === 'web') {
        alert('Пожалуйста, заполните поля')
      } else {
        Alert.alert('Ошибка!', 'Пожалуйста, заполните поля', [
          {
            text: 'Ок',
            style: 'cancel'
          }
        ])
      }
      return
    }

    console.log('VALUES', values)
    const data = {
      circleBalanceSectorTargetId: values.targetName,
      description: values.name,
      deadlineDate: values.date,
      stepTime: values.stepTime
    }
    setCurrentForm({
      date: values.date, // дата
      area: values.area, // область сферы - дропдаун
      targetName: values.targetName, // цель - дропдаун
      name: values.name, // название шага
      stepTime: values.stepTime
    })
    addDiaryItem(data, navigation)
  }

  useEffect(() => {
    if (error) {
      if (Platform.OS === 'web') {
        alert(error)
        clearError()
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  useEffect(() => {
    if (success) {
      if (Platform.OS === 'web') {
        alert('Шаг успешно добавлен!')
        clearSuccess()
        navigation.navigate('Steps')
      } else {
        Alert.alert('Успешно!', 'Шаг успешно добавлен!', [
          {
            text: 'Ок',
            onPress: () => {
              clearSuccess()
              navigation.navigate('Steps')
            },
            style: 'default'
          }
        ])
      }
    }
  }, [success])

  if (loading) {
    return <AppLoader />
  }

  return (
    <View style={styles.center}>
      <ScrollView style={styles.body}>
        <Formik
          initialValues={currentForm}
          onSubmit={(values) => apply(values)}
        >
          {({ handleChange, handleBlur, handleSubmit, values }) => (
            <View style={styles.form}>
              <DropdownField
                onChangeText={handleChange('area')}
                onBlur={handleBlur('area')}
                onSelectOption={(id) => getTargets(id)}
                value={values.area}
                placeholder="Область"
                label="Область"
                items={areas}
                name="area"
                id={'area'}
                style={{ zIndex: 11 } }
              />

              <DropdownField
                onChangeText={handleChange('targetName')}
                onBlur={handleBlur('targetName')}
                onSelectOption={(id) => {
                  const foundtask = tasks.find(task => task.value === id)
                  handleChange('stepTime')(foundtask.deadlineDate)
                }}
                value={values.targetName}
                placeholder="Название цели"
                label="Название цели"
                items={tasks}
                name="targetName"
                id="targetName"
                loading={loadingTarget}
                style={{ zIndex: 10 } }
              />

              <DatePickerField
                onChangeText={handleChange('stepTime')}
                onBlur={handleBlur('stepTime')}
                placeholder="Дата цели"
                label="Дата цели"
                value={values.stepTime}
                style={styles.marginButton}
                name="stepTime"
                disabled={true}
              />

              <SCInput
                onChangeText={handleChange('name')}
                onBlur={handleBlur('name')}
                placeholder="Название шага"
                label="Название шага"
                autoCapitalize="none"
                value={values.name}
              />

              <DatePickerField
                onChangeText={handleChange('date')}
                onBlur={handleBlur('date')}
                placeholder="Дедлайн шага"
                label="Дедлайн шага"
                value={values.date}
                name="date"
              />

              <View style={styles.actions}>
                <SCButton text="Добавить" onPress={handleSubmit} />
              </View>
            </View>
          )}
        </Formik>
      </ScrollView>
    </View>
  )
}

StepAddScreen.navigationOptions = ({ navigation }) => {
  return {
    headerTitle: 'Добавление Шага'
  }
}

const styles = StyleSheet.create({
  body: {},
  center: {
    display: 'flex',
    flex: 1,
    paddingVertical: 40,
    width: '100%'
  },
  form: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  marginButton: {
    marginBottom: 41
  }
})
