import {
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  HIDE_LOADER,
  SHOW_ERROR,
  SHOW_LOADER,
  SHOW_SUCCESS,
  USER_LOGIN,
  USER_LOGOUT,
  USER_UPDATE
} from '../types'

const handlers = {
  [USER_LOGIN]: (state, action) => ({ ...state, user: action.user }),
  [USER_LOGOUT]: (state) => ({ ...state, user: null }),
  [USER_UPDATE]: (state, action) => ({
    ...state,
    user: { ...action.user }
  }),
  [SHOW_LOADER]: (state) => ({ ...state, loading: true }),
  [HIDE_LOADER]: (state) => ({ ...state, loading: false }),
  [CLEAR_ERROR]: (state) => ({ ...state, error: null }),
  [SHOW_ERROR]: (state, { error }) => ({ ...state, error }),
  [SHOW_SUCCESS]: (state) => ({ ...state, success: true }),
  [CLEAR_SUCCESS]: (state) => ({ ...state, success: false }),
  DEFAULT: (state) => state
}

export const userReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT
  return handler(state, action)
}
