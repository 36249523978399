import {
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  EXERCISE_GET_LIST_FROM_API,
  EXERCISE_GET_TASKS_FROM_API,
  EXERCISE_GET_TEACH_FROM_API,
  EXERCISE_UPDATE,
  HIDE_LOADER,
  SHOW_ERROR,
  SHOW_LOADER,
  SHOW_SUCCESS

} from '../types'

const handlers = {
  [EXERCISE_GET_LIST_FROM_API]: (state, action) => ({
    ...state,
    exercise: action.exercise
  }),
  [EXERCISE_GET_TEACH_FROM_API]: (state, action) => ({
    ...state,
    exerciseTeach: action.exerciseTeach
  }),
  [EXERCISE_GET_TASKS_FROM_API]: (state, action) => ({
    ...state,
    exerciseTasks: action.exerciseTasks
  }),
  [EXERCISE_UPDATE]: (state, action) => ({
    ...state,
    exercise: action.exercise
  }),
  [SHOW_LOADER]: (state) => ({ ...state, loading: true }),
  [HIDE_LOADER]: (state) => ({ ...state, loading: false }),
  [SHOW_SUCCESS]: (state) => ({ ...state, success: true }),
  [CLEAR_SUCCESS]: (state) => ({ ...state, success: false }),
  [CLEAR_ERROR]: (state) => ({ ...state, error: null }),
  [SHOW_ERROR]: (state, { error }) => ({ ...state, error }),
  DEFAULT: (state) => state
}

export const exerciseReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT
  return handler(state, action)
}
