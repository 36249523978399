import {
  HIDE_LOADER,
  SHOW_LOADER,
  INSIDE_UPDATE,
  INSIDE_GET_FROM_API,
  SHOW_SUCCESS,
  CLEAR_SUCCESS,
  CLEAR_ERROR,
  SHOW_ERROR
} from '../types'

const handlers = {
  [INSIDE_GET_FROM_API]: (state, action) => ({
    ...state,
    insideList: action.insideList
  }),
  [INSIDE_UPDATE]: (state, action) => ({
    ...state,
    diary: action.updatedDiary
  }),
  [SHOW_LOADER]: (state) => ({ ...state, loading: true }),
  [HIDE_LOADER]: (state) => ({ ...state, loading: false }),
  [SHOW_SUCCESS]: (state) => ({ ...state, success: true }),
  [CLEAR_SUCCESS]: (state) => ({ ...state, success: false }),
  [CLEAR_ERROR]: (state) => ({ ...state, error: null }),
  [SHOW_ERROR]: (state, { error }) => ({ ...state, error }),
  DEFAULT: (state) => state
}

export const insideReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT
  return handler(state, action)
}
