import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View, Text, StyleSheet, ScrollView, Platform } from 'react-native'
import { THEME } from '../../../theme'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { AntDesign } from '@expo/vector-icons'
import { AppLoader } from '../../../components/ui/AppLoader'
import { ArchiveContext } from '../../../context/archive/archiveContext'

export const ArchiveGroupViewScreen = ({ navigation }) => {
  // const [modalVisible, setModalVisible] = useState(false);
  const { photoList, getPhotoList, loading, error } =
    useContext(ArchiveContext)

  const currentId = navigation.getParam('currentGroupId')
  console.log('NAVIGATION OPTION ITEM: ', currentId)

  const removeSuccess = navigation.getParam('removeSuccess')
  console.log('NAVIGATION OPTION ITEM: ', removeSuccess)

  useEffect(() => {
    getPhotoList(currentId)
  }, [])

  const toPhoto = (item) => {
    navigation.navigate('PhotoView', {
      photoId: item.id,
      photoName: item.name,
      groupId: currentId
    })
  }

  if (loading) {
    return <AppLoader />
  }

  return (
    <ScrollView>
      <View style={styles.groups}>
        {photoList &&
            photoList.map((photo) => (
              <TouchableOpacity
                key={photo.id}
                onPress={() => toPhoto(photo)}
                style={styles.groupRow}
              >
                <View>
                  <Text style={styles.coreInfo}>{photo.name}</Text>
                </View>
              </TouchableOpacity>
            ))}
      </View>
    </ScrollView>
  )
}

ArchiveGroupViewScreen.navigationOptions = ({ navigation }) => {
  const currentId = navigation.getParam('currentGroupId')

  const onAdd = () => {
    navigation.navigate('PhotoAdd', { groupId: currentId })
  }

  return {
    headerTitle: 'Просмотр группы',
    headerRight: () => (
      <TouchableOpacity style={styles.headerRight} onPress={onAdd}>
        <AntDesign
          name="plus"
          color={Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR}
          size={24}
        />
      </TouchableOpacity>
    )
  }
}
const styles = StyleSheet.create({
  headerRight: {
    marginRight: 26
  },
  groups: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 18,
    width: '100%'
  },
  groupRow: {
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 2,
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    height: 140,
    justifyContent: 'space-between',
    marginBottom: 20,
    marginLeft: 20,
    padding: 16,
    width: 140
  },
  coreInfo: {
    alignSelf: 'center',
    marginBottom: 5
  },
  additionalInfo: {
    color: '#787878',
    fontSize: 12
  },
  // MODAL

  centeredView: {
    backgroundColor: THEME.MAIN_DARK,
    height: '100%',
    justifyContent: 'center',
    padding: 26,
    position: 'absolute',
    width: '100%'
  },
  modalView: {
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 3,
    margin: 20,
    padding: 35
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30
  },
  button: {
    color: THEME.MAIN_DARK
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center'
  }
})
