import React, { useReducer } from 'react'
import {
  HIDE_LOADER,
  SHOW_LOADER,
  INSIDE_GET_FROM_API,
  INSIDE_UPDATE,
  SHOW_SUCCESS,
  CLEAR_SUCCESS,
  CLEAR_ERROR,
  SHOW_ERROR
} from '../types'
import { Http } from '../../services/http'
import { getData } from '../../services/storage.service'
import { CONFIG } from '../../config'
import { insideReducer } from './insideReducer'
import { InsideContext } from './insideContext'

export const InsideState = ({ children }) => {
  const initialState = {
    insideList: [],
    loading: false,
    error: null,
    success: false
  }
  const [state, dispatch] = useReducer(insideReducer, initialState)

  const getInsidelist = async () => {
    // Показываем загрузку
    showLoader()
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const insideResponse = await Http.get(
        `${CONFIG.API_URL}UserInside`,
        token
      )

      const data = insideResponse

      const insideList = data

      // Сохраняем в стейт
      dispatch({ type: INSIDE_GET_FROM_API, insideList })
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }
  }

  const addInsideItem = async (insideItem) => {
    showLoader()

    let responseResult = false
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    const data = insideItem
    console.log('DATA IS: ', insideItem)
    try {
      // Отправляем запрос на бек
      const insideResponse = await Http.post(
        `${CONFIG.API_URL}UserInside`,
        data,
        token
      )
      console.log('RESULT: ', insideResponse)
      responseResult = true
    } catch (e) {
      responseResult = false
      console.log('ERRROR', e)
    } finally {
    }

    // let updatedWheel = [...wheel];

    // Сохраняем в стейт
    // dispatch({ type: DIARY_UPDATE, updatedWheel });
    hideLoader()
    await getInsidelist()
    if (responseResult) {
      showSuccess()
    } else {
      showError('Что-то пошло не так...')
    }
  }

  const updateInsideItem = async (insideItem) => {
    showLoader()
    let responseResult = false
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    const data = insideItem
    console.log('DATA IS: ', insideItem)
    try {
      // Отправляем запрос на бек
      const insideResponse = await Http.put(
        `${CONFIG.API_URL}UserInside`,
        data,
        token
      )
      console.log('RESULT: ', insideResponse)
      responseResult = true
    } catch (e) {
      responseResult = false
      console.log('ERRROR', e)
    } finally {
    }

    // let updatedWheel = [...wheel];

    // Сохраняем в стейт
    // dispatch({ type: DIARY_UPDATE, updatedWheel });
    await getInsidelist()
    hideLoader()
    if (responseResult) {
      showSuccess()
    } else {
      showError('Что-то пошло не так...')
    }
  }

  const showLoader = () => dispatch({ type: SHOW_LOADER })

  const hideLoader = () => dispatch({ type: HIDE_LOADER })

  const showError = (error) => dispatch({ type: SHOW_ERROR, error })

  const clearError = () => dispatch({ type: CLEAR_ERROR })

  const showSuccess = () => dispatch({ type: SHOW_SUCCESS })

  const clearSuccess = () => dispatch({ type: CLEAR_SUCCESS })

  return (
    <InsideContext.Provider
      value={{
        insideList: state.insideList,
        loading: state.loading,
        success: state.success,
        clearError,
        getInsidelist,
        updateInsideItem,
        clearSuccess,
        addInsideItem
      }}
    >
      {children}
    </InsideContext.Provider>
  )
}
