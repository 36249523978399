import {
  GET_USERS_USE_PROMO_COD,
  GET_COUNT_USE_PROMO_COD,
  GET_PROMO_CODS,
  // ADD_PROMO_COD,
  // DELETE_PROMO_COD,
  HIDE_LOADER,
  SHOW_LOADER,
  SHOW_SUCCESS,
  CLEAR_SUCCESS,
  SHOW_ERROR,
  CLEAR_ERROR, ARCHIVE_GET_GROUP_LIST
} from '../types'
import { Http } from '../../services/http'
import { getData } from '../../services/storage.service'
import { CONFIG } from '../../config'
import { adminReducer } from './adminReducer'
import { AdminContext } from './adminContext'
import React, { useReducer } from 'react'

export const AdminState = ({ children }) => {
  const initialState = {
    usersList: [],
    promoCodsList: [],
    countUsers: 0,
    price: 0,
    loading: false,
    error: null,
    success: false
  }

  const [state, dispatch] = useReducer(adminReducer, initialState)

  const removePromoCod = async (id) => {
    showLoader()
    let token = ''

    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      await Http.delete(
        `${CONFIG.API_URL}UserAdministration/delete_promocode?id=` + id,
        token
      )
    } catch (e) {
      console.log('ERRROR', e)
    } finally {
      hideLoader()
    }
  }

  const updatePrice = async (price, level) => {
    showLoader()
    let token = ''

    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const result = await Http.get(
        `${CONFIG.API_URL}UserAdministration/set_price/${price}_${level}`,
        token
      )

      console.log(result)

      if (result.message) {
        showError(result.message)
      }
    } catch (e) {
      console.log('ERRROR', e)
    } finally {
      hideLoader()
    }
  }

  const getUsers = async () => {
    showLoader()
    let token = ''

    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const usersList = await Http.get(
        `${CONFIG.API_URL}UserAdministration/getAllUsersWithProm`,
        token
      )

      console.log('PROMO FROM API ', usersList)
      // Сохраняем в стейт
      dispatch({ type: GET_USERS_USE_PROMO_COD, usersList })
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }
  }

  const addPromoCod = async (value) => {
    showLoader()
    let token = ''

    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const result = await Http.post(
        `${CONFIG.API_URL}UserAdministration/add_promocode`, value,
        token
      )

      if (result.message) {
        showError(result.message)
      }
    } catch (e) {
      console.log('ERRROR', e)
    } finally {
      hideLoader()
    }
  }

  const getPromoCods = async () => {
    showLoader()
    let token = ''

    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const promoCodsList = await Http.get(
        `${CONFIG.API_URL}UserAdministration/getAllPromocodes`,
        token
      )

      console.log('PROMO FROM API ', promoCodsList)
      // Сохраняем в стейт
      dispatch({ type: GET_PROMO_CODS, promoCodsList })
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }
  }

  const showLoader = () => dispatch({ type: SHOW_LOADER })

  const hideLoader = () => dispatch({ type: HIDE_LOADER })

  const showError = (error) => dispatch({ type: SHOW_ERROR, error })

  const clearError = () => dispatch({ type: CLEAR_ERROR })

  const showSuccess = () => dispatch({ type: SHOW_SUCCESS })

  const clearSuccess = () => dispatch({ type: CLEAR_SUCCESS })

  return (
    <AdminContext.Provider
      value={{
        usersList: state.usersList,
        promoCodsList: state.promoCodsList,
        countUsers: state.currentPhoto,
        loading: state.loading,
        error: state.error,
        success: state.success,
        price: state.price,
        getPromoCods,
        updatePrice,
        getUsers,
        removePromoCod,
        addPromoCod,
        clearError,
        clearSuccess
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}
