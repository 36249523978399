import React, { useContext, useEffect, useState } from 'react'
import { View, StatusBar } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import { UserContext } from '../../context/user/userContext'
import { getData } from '../../services/storage.service'
import { Http } from '../../services/http'
import { CONFIG } from '../../config'
import { AppLoader } from '../../components/ui/AppLoader'

export const AuthLoadingScreen = ({ navigation }) => {
  const { userFill } = useContext(UserContext)
  const [send, setSend] = useState(true)

  useEffect(() => {
    getData('currentUser').then((response) => {
      if (response) {
        userFill(response)
        getData('isUserExist').then(async (isUser) => {
          // if (isUser) {
          const checkPay = await Http.get(
            `${CONFIG.API_URL}Payment/get_state/1`, response.token
          )

          // console.log(checkPay)

          if (checkPay) {
            if (response.roleId === 1) {
              navigation.navigate('Admin')
            } else if (checkPay.status === 'CONFIRMED' && response.roleId === 2) {
              navigation.navigate('App')
            } else if (response.roleId === 2) {
              navigation.navigate('Loading')
            } else {
              navigation.navigate('Auth')
            }
          }
        })
      } else {
        navigation.navigate('Auth')
      }
    })
  }, [])

  return (
    <AppLoader/>
  )
}
