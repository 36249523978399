import { CONFIG } from '../../../config'
import { Http } from '../../../services/http'
import { getData } from '../../../services/storage.service'

export const getInsideById = async (id) => {
  let token = ''
  await getData('currentUser').then((response) => {
    if (response) {
      token = response.token
    }
  })

  try {
    // Отправляем запрос на бек
    const data = await Http.get(
      `${CONFIG.API_URL}UserInside/${id}`,
      token
    )
    console.log(`ID: ${id}, DATA: `, data)
    return data
  } catch (e) {
    console.log('ERRROR', e)
  } finally {
  }
}
