import React from 'react'
import { View, Text, StyleSheet, Button, TouchableOpacity } from 'react-native'
import { THEME } from '../../theme'

export const DiaryScreen = ({ navigation }) => {
  const onSteps = () => {
    navigation.navigate('Steps')
  }
  const onTargets = () => {
    navigation.navigate('Targets')
  }

  return (
    <View style={styles.center}>
      <TouchableOpacity style={styles.card} onPress={onSteps}>
        <Text>Шаги</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.card} onPress={onTargets}>
        <Text>Цели</Text>
      </TouchableOpacity>
    </View>
  )
}

DiaryScreen.navigationOptions = {
  headerTitle: 'Календарь'
}

const styles = StyleSheet.create({
  card: {
    alignItems: 'center',
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 2,
    height: 180,
    justifyContent: 'center',
    marginBottom: 15,
    width: '80%'
  },
  center: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 20
  }
})
