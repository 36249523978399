import { Image, Text, TouchableOpacity, View } from 'react-native'
import { THEME } from '../../../theme'
import React from 'react'
export const BeforeStartScreen = ({ navigation }) => {
  const timeoutID = setTimeout(() => {
    navigation.navigate('VideoAfterRegistration')
  }, 4000)

  return (
    <View style={styles.body}>
      <TouchableOpacity style={styles.card}>
        <Text style={{ textAlign: 'center' }}>Прежде чем приступить к работе с приложением, {'\n'}
                    посмотрите, пожалуйста, 2 {'\n'} вводных видео.</Text>
        <Image source={require('../../../../assets/icons/rotatePhone.png')} />
        <Text>ПОСМОТРЕТЬ</Text>

      </TouchableOpacity>
    </View>
  )
}

BeforeStartScreen.navigationOptions = {
  headerTitle: 'Прежде чем приступить',
  headerShown: false,
  headerLeft: () => null
}

const styles = {
  body: {
    paddingTop: 35,
    backgroundColor: THEME.MAIN_GREY,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    marginBottom: 15,
    width: '80%',
    height: 280,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30
  }
}

export default BeforeStartScreen
