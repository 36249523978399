import React from 'react'
import { View, Text, StyleSheet, Button } from 'react-native'
import { storeData } from '../../services/storage.service'

export const GreetingsScreen = ({ navigation }) => {
  const gotInfo = () => {
    storeData('isUserExist', true)
    navigation.navigate('App')
  }

  return (
    <View style={styles.body}>
      <Text>Добро пожаловать!</Text>
      <Button title="Отключить приветствие" onPress={gotInfo} />
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  }
})
