import { StyleSheet } from 'react-native'
import { THEME } from '../theme'

export default StyleSheet.create({
  main: {
    backgroundColor: THEME.MAIN_GREY,
    height: '100%',
    width: '100%'
  }
})
