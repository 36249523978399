import { FlatList, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import React, { useContext, useState } from 'react'
import { AdminContext } from '../../../context/admin/adminContext'
import { AppLoader } from '../../../components/ui/AppLoader'
export const UsersPromoCodsScreen = ({ navigation }) => {
  const { success, clearSuccess, loading, error, clearError, usersList, getUsers } =
        useContext(AdminContext)

  const viewUsers = () => {
    getUsers()
    console.log(usersList)
  }

  if (loading) {
    return <AppLoader/>
  }

  return (
    <View style={styles.container}>
      <View>
        <TouchableOpacity onPress={viewUsers}>
          <View>
            <Text style={styles.textButton}>{'Показать список пользователей'}</Text>
          </View>
        </TouchableOpacity>
      </View>

      <FlatList initialNumToRender={10} data={usersList} renderItem={({ item }) => <Text style={styles.item}>{item.userName + ': ' + item.promocodeName}</Text>}/>
    </View>
  )
}

UsersPromoCodsScreen.navigationOptions = {
  headerTitle: 'Пользователи'
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'gray',
    paddingTop: 22
  },
  item: {
    padding: 10,
    fontSize: 16
  },
  textButton: {
    padding: 10,
    fontSize: 18,
    height: 44
  }
}
