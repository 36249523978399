import React, { useState } from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import { THEME } from '../../../theme'

export const SCInput = ({
  onChangeText,
  onBlur,
  iconPosition,
  placeholder,
  icon,
  style,
  value,
  label,
  error,
  description,
  disabled = false,
  ...props
}) => {
  const getFlexDirection = () => {
    const [focused, setFocused] = React.useState(false)

    if (icon && iconPosition) {
      if (iconPosition === 'left') {
        return 'row'
      } else if (iconPosition === 'right') {
        return 'row-reverse'
      }
    }
  }

  const getBorderColor = () => {
    // if (error) {
    //   return colors.danger;
    // }
    // if (focused) {
    //   return colors.primary;
    // } else {
    //   return colors.grey;
    // }
  }

  return (
    <View>
      {label && <Text style={styles.label}>{label}</Text>}

      <View
        style={[
          styles.wrapper,
          { alignItems: icon ? 'center' : 'baseline' },
          { borderColor: getBorderColor(), flexDirection: getFlexDirection() }
        ]}
      >
        <View>{icon && icon}</View>
        <TextInput
          style={[styles.textInput, style]}
          onChangeText={onChangeText}
          value={value}
          onBlur={onBlur}
          allowFontScaling={false}
          {...props}
        />
      </View>

      {description && <Text style={styles.description}>{description}</Text>}
      {error && <Text style={styles.error}>{error}</Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  description: {
    fontSize: 10,
    marginBottom: 13,
    marginTop: -5
  },

  error: {
    // color: colors.danger,
    paddingTop: 4,
    fontSize: 12
  },
  label: {
    color: THEME.MAIN_DARK,
    marginBottom: 10
  },
  textInput: {
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    fontSize: 14,
    paddingLeft: 18,
    paddingVertical: 18,
    width: 300
  },

  wrapper: {
    // height: 42,
    // borderWidth: 1,
    // borderRadius: 4,
    // paddingHorizontal: 5,
    // marginTop: 5,
  }
})
