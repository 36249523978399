import React, { useReducer } from 'react'
import {
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  HIDE_LOADER,
  SHOW_ERROR,
  SHOW_LOADER,
  SHOW_SUCCESS,
  WHEEL_GET_FROM_API,
  WHEEL_UPDATE
} from '../types'
import { WheelContext } from './wheelContext'
import { Http } from '../../services/http'
import { wheelReducer } from './wheelReducer'
import { getData } from '../../services/storage.service'
import { CONFIG } from '../../config'

export const WheelState = ({ children }) => {
  const initialState = {
    wheel: [],
    loading: false,
    success: false,
    error: null
  }
  const [state, dispatch] = useReducer(wheelReducer, initialState)

  const wheelGet = async () => {
    // Показываем загрузку
    showLoader()
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const circleBalance = await Http.get(
        `${CONFIG.API_URL}CircleBalanceUserScore`,
        token
      )
      const circleBalanceSector = await Http.get(
        `${CONFIG.API_URL}CircleBalanceSector`,
        token
      )
      const data = []

      const newCircleBalance = []
      let newCircleSector = []

      for (let x = 0; x < circleBalance.length; x++) {
        newCircleBalance.push(circleBalance[x])
      }
      console.log('CIRCLE BALANCE: ', newCircleBalance)

      newCircleSector = circleBalanceSector
      console.log('BALANCE SECTOR: ', newCircleSector)

      newCircleBalance.forEach((balance) => {
        newCircleSector.forEach((sector) => {
          if (balance.circleBalanceSectorId === sector.id) {
            const mergedObject = {
              circleBalanceSectorId: balance.circleBalanceSectorId,
              createdDateUtc: balance.createdDateUtc,
              score: balance.score,
              userId: balance.userId,
              color: sector.color,
              id: sector.id,
              name: sector.name,
              order: sector.order,
              isSliderChanged: false
            }
            data.push(mergedObject)
          }
        })
      })
      // Если ошибка, возвращаем ошибку
      //  if (data.error) {
      //    showError(data.error);
      //    return;
      //  }
      console.log('WHEEL FROM API ', data)
      const wheel = data.sort(({ order: a }, { order: b }) => a - b)
      // Сохраняем в стейт
      dispatch({ type: WHEEL_GET_FROM_API, wheel })
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }
  }

  const wheelGetExt = async () => {
    let result
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const circleBalanceSector = await Http.get(
        `${CONFIG.API_URL}CircleBalanceSector`,
        token
      )
      result = circleBalanceSector
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
    }
    return result
  }

  // Обновляет значения колеса (сектор от 1 до 10) (STORE)
  const wheelUpdateSliderValue = async (data, updatedWheel) => {
    console.log('SHOULD UPDATE SLIDER', data)
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })
    try {
      dispatch({ type: WHEEL_UPDATE, updatedWheel })
    } catch (e) {
      console.log('ERRROR', e)
    } finally {
    }
  }

  // Обновляет массив целей в колесе (только АПИ)
  const wheelUpdateTargets = async (data) => {
    console.log('SHOULD UPDATE TASKS', data)
    let responseResult = false
    const errorMessage = null
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })
    try {
      // Отправляем запрос на бек
      const circleBalance = await Http.post(
        `${CONFIG.API_URL}CircleBalanceSectorTarget`,
        data,
        token
      )

      return circleBalance
    } catch (e) {
      responseResult = false
      console.log('ERRROR', e)
    } finally {
    }
  }

  // Обновляет значения колеса (сектор от 1 до 10) (только АПИ)
  const wheelUpdateSliderValueToAPI = async (wheel) => {
    showLoader()
    let responseResult = false
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    // Находим сектор Id и собираем sliderValueArray

    for (const w of wheel) {
      // Если значения слайдера не изменялись, то запрос на апдейт не отправляем
      if (w.isSliderChanged) {
        const data = {
          score: w.score,
          sectorBalanceId: w.circleBalanceSectorId
        }
        console.log('DATA IS: ', data)
        try {
          // Отправляем запрос на бек
          const circleBalance = await Http.post(
            `${CONFIG.API_URL}CircleBalanceUserScore`,
            data,
            token
          )
          responseResult = true
          console.log('RESULT: ', circleBalance)
        } catch (e) {
          responseResult = false
          console.log('ERRROR', e)
        } finally {
        }
      }
    }
    // Ресетим все слейдеры в False, для детекта изменений
    const updatedWheel = [...wheel]
    updatedWheel.forEach((x) => {
      x.isSliderChanged = false
    })

    // Сохраняем в стейт
    dispatch({ type: WHEEL_UPDATE, updatedWheel })
    hideLoader()

    if (responseResult) {
      showSuccess()
    } else {
      showError('Что-то пошло не так...')
    }
  }

  const showLoader = () => dispatch({ type: SHOW_LOADER })

  const hideLoader = () => dispatch({ type: HIDE_LOADER })

  const showSuccess = () => dispatch({ type: SHOW_SUCCESS })

  const clearSuccess = () => dispatch({ type: CLEAR_SUCCESS })

  const showError = (error) => dispatch({ type: SHOW_ERROR, error })

  const clearError = () => dispatch({ type: CLEAR_ERROR })

  return (
    <WheelContext.Provider
      value={{
        wheel: state.wheel,
        loading: state.loading,
        success: state.success,
        wheelGet,
        wheelGetExt,
        wheelUpdateSliderValue,
        wheelUpdateSliderValueToAPI,
        wheelUpdateTargets,
        clearSuccess,
        showLoader,
        hideLoader,
        clearError
      }}
    >
      {children}
    </WheelContext.Provider>
  )
}
