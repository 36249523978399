import {
  HIDE_LOADER,
  SHOW_LOADER,
  DIARY_UPDATE,
  DIARY_GET_ACTUAL_FROM_API,
  DIARY_GET_FINISHED_FROM_API,
  SHOW_SUCCESS,
  CLEAR_SUCCESS,
  CLEAR_ERROR,
  SHOW_ERROR,
  TARGET_UPDATE,
  TARGETS_GET_FINISHED_FROM_API,
  TARGETS_GET_ACTUAL_FROM_API
} from '../types'

const handlers = {
  [DIARY_GET_ACTUAL_FROM_API]: (state, action) => ({
    ...state,
    diaryActual: action.diaryActual
  }),
  [DIARY_GET_FINISHED_FROM_API]: (state, action) => ({
    ...state,
    diaryFinished: action.diaryFinished
  }),
  [DIARY_UPDATE]: (state, action) => ({
    ...state,
    diary: action.updatedDiary
  }),
  [TARGETS_GET_ACTUAL_FROM_API]: (state, action) => ({
    ...state,
    targetsActual: action.targetsActual
  }),
  [TARGETS_GET_FINISHED_FROM_API]: (state, action) => ({
    ...state,
    targetsFinished: action.targetsFinished
  }),
  [TARGET_UPDATE]: (state, action) => ({
    ...state,
    target: action.updatedTarget
  }),
  [SHOW_LOADER]: (state) => ({ ...state, loading: true }),
  [HIDE_LOADER]: (state) => ({ ...state, loading: false }),
  [SHOW_SUCCESS]: (state) => ({ ...state, success: true }),
  [CLEAR_SUCCESS]: (state) => ({ ...state, success: false }),
  [CLEAR_ERROR]: (state) => ({ ...state, error: null }),
  [SHOW_ERROR]: (state, { error }) => ({ ...state, error }),
  DEFAULT: (state) => state
}

export const diaryReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT
  return handler(state, action)
}
