import { Alert, Platform } from 'react-native'

export default function alertMultiplatform ({ message, title }) {
  if (Platform.OS === 'web') {
    alert(message)
  } else {
    Alert.alert(title, message, [
      {
        text: 'Ок',
        style: 'default'
      }
    ])
  }
}
