import React, { useReducer } from 'react'
import {
  HIDE_LOADER,
  SHOW_LOADER,
  DIARY_GET_FROM_API,
  DIARY_UPDATE,
  DIARY_GET_ACTUAL_FROM_API,
  DIARY_GET_FINISHED_FROM_API,
  SHOW_SUCCESS,
  CLEAR_SUCCESS,
  SHOW_ERROR,
  CLEAR_ERROR,
  TARGETS_GET_ACTUAL_FROM_API,
  TARGETS_GET_FINISHED_FROM_API
} from '../types'
import { Http } from '../../services/http'
import { diaryReducer } from './diaryReducer'
import { getData } from '../../services/storage.service'
import { CONFIG } from '../../config'
import { DiaryContext } from './diaryContext'

export const DiaryState = ({ children }) => {
  const initialState = {
    diaryActual: [],
    diaryFinished: [],
    targetsActual: [],
    targetsFinished: [],
    loading: false,
    error: null,
    success: false
  }
  const [state, dispatch] = useReducer(diaryReducer, initialState)

  const getDiaryActualList = async () => {
    // Показываем загрузку
    showLoader()
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const actualList = await Http.get(
        `${CONFIG.API_URL}DailyStepToTarget/actual`,
        token
      )

      const data = actualList.data

      // Если ошибка, возвращаем ошибку
      //  if (data.error) {
      //    showError(data.error);
      //    return;
      //  }
      console.log('ACTUAL DATA FROM API ', data)
      const diaryActual = data
      // Сохраняем в стейт
      dispatch({ type: DIARY_GET_ACTUAL_FROM_API, diaryActual })
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }
  }

  const getDiaryFinishedList = async () => {
    // Показываем загрузку
    showLoader()
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const finishedList = await Http.get(
        `${CONFIG.API_URL}DailyStepToTarget/finished`,
        token
      )

      const data = finishedList.data

      // Если ошибка, возвращаем ошибку
      //  if (data.error) {
      //    showError(data.error);
      //    return;
      //  }
      console.log('FINISHED DATA FROM API ', data)
      const diaryFinished = data
      // Сохраняем в стейт
      dispatch({ type: DIARY_GET_FINISHED_FROM_API, diaryFinished })
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }
  }

  const addDiaryItem = async (diaryItem) => {
    let responseResult = false
    let responseResultError = ''
    showLoader()

    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    const data = diaryItem

    try {
      // Отправляем запрос на бек
      const diaryResponse = await Http.post(
        `${CONFIG.API_URL}DailyStepToTarget`,
        data,
        token
      )

      if (diaryResponse?.status === 'Error') {
        throw new Error(diaryResponse?.message?.DeadlineDate[0])
      }
      console.log('RESULT: ', diaryResponse)
      responseResult = true
    } catch (e) {
      responseResult = false
      responseResultError = e

      responseResultError = e ? e.message : 'Что-то пошло не так...'

      console.log('-------------------')
      console.log('Result error: ', responseResultError)
    } finally {
    }

    await getDiaryActualList()

    hideLoader()

    if (responseResult) {
      showSuccess()
    } else {
      showError(responseResultError)
    }
  }

  const updateDiaryItemStatus = async (
    itemId,
    status,
    updatedItem,
    navigation
  ) => {
    let responseResult = false
    showLoader()

    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    const data = updatedItem

    try {
      // Отправляем запрос на бек
      let response
      switch (status) {
      case 'complete':
        response = await Http.put(
          `${CONFIG.API_URL}DailyStepToTarget/complete/${itemId}`,
          data,
          token
        )
        break
      case 'fail':
        response = await Http.put(
          `${CONFIG.API_URL}DailyStepToTarget/fail/${itemId}`,
          data,
          token
        )
        break
      case 'update':
        response = await Http.put(
          `${CONFIG.API_URL}DailyStepToTarget`,
          data,
          token
        )
        break
      }

      console.log('RESULT: ', response)
      responseResult = true
    } catch (e) {
      responseResult = false
      console.log('ERRROR DIARY', e)
    } finally {
    }

    await getDiaryActualList()
    await getDiaryFinishedList()
    hideLoader()
    if (responseResult) {
      showSuccess()
    } else {
      showError('Что-то пошло не так...')
    }
  }

  // targets

  const getTargetsActualList = async () => {
    // Показываем загрузку
    showLoader()
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const actualList = await Http.get(
        `${CONFIG.API_URL}CircleBalanceSectorTarget/actual`,
        token
      )

      const data = actualList.map((item) => {
        return {
          ...item,
          status: 'В Процессе'
        }
      })

      console.log('ACTUAL DATA FROM API ', data)
      const targetsActual = data
      // Сохраняем в стейт
      dispatch({ type: TARGETS_GET_ACTUAL_FROM_API, targetsActual })
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }
  }

  const getTargetsFinishedList = async () => {
    // Показываем загрузку
    showLoader()
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const finishedList = await Http.get(
        `${CONFIG.API_URL}CircleBalanceSectorTarget/finished`,
        token
      )

      const data = finishedList

      // Если ошибка, возвращаем ошибку
      //  if (data.error) {
      //    showError(data.error);
      //    return;
      //  }
      console.log('FINISHED DATA FROM API ', data)
      const targetsFinished = data
      // Сохраняем в стейт
      dispatch({ type: TARGETS_GET_FINISHED_FROM_API, targetsFinished })
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }
  }

  const updateTargetItemStatus = async (
    itemId,
    status,
    updatedItem,
    navigation
  ) => {
    let responseResult = false
    showLoader()

    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    const data = updatedItem

    try {
      // Отправляем запрос на бек
      let response
      switch (status) {
      case 'complete':
        response = await Http.put(
          `${CONFIG.API_URL}CircleBalanceSectorTarget/completed`,
          {
            id: itemId
          },
          token
        )
        break
      case 'fail':
        response = await Http.put(
          `${CONFIG.API_URL}CircleBalanceSectorTarget/failed`,
          {
            id: itemId
          },
          token
        )
        break
      }

      console.log('RESULT: ', response)
      responseResult = true
    } catch (e) {
      responseResult = false
      console.log('ERRROR DIARY', e)
    } finally {
    }

    await getTargetsActualList()
    await getTargetsFinishedList()
    hideLoader()
    if (responseResult) {
      showSuccess()
    } else {
      showError('Что-то пошло не так...')
    }
  }

  // end targets

  const showLoader = () => dispatch({ type: SHOW_LOADER })

  const hideLoader = () => dispatch({ type: HIDE_LOADER })

  const showError = (error) => dispatch({ type: SHOW_ERROR, error })

  const clearError = () => dispatch({ type: CLEAR_ERROR })

  const showSuccess = () => dispatch({ type: SHOW_SUCCESS })

  const clearSuccess = () => dispatch({ type: CLEAR_SUCCESS })

  return (
    <DiaryContext.Provider
      value={{
        diaryActual: state.diaryActual,
        diaryFinished: state.diaryFinished,
        targetsActual: state.targetsActual,
        targetsFinished: state.targetsFinished,
        loading: state.loading,
        error: state.error,
        success: state.success,
        clearError,
        getDiaryActualList,
        getDiaryFinishedList,
        addDiaryItem,
        updateDiaryItemStatus,
        getTargetsActualList,
        getTargetsFinishedList,
        updateTargetItemStatus,
        clearSuccess,
        showLoader,
        hideLoader
      }}
    >
      {children}
    </DiaryContext.Provider>
  )
}
