import React, { useContext, useEffect } from 'react'
import { View, StyleSheet, ScrollView, Alert, Platform, Keyboard } from 'react-native'
import { AppLoader } from '../../../components/ui/AppLoader'
import { SCButton } from '../../../components/ui/button/SCButton'
import { SCInput } from '../../../components/ui/inputs/SCInput'
import { Formik } from 'formik'
import { ArchiveContext } from '../../../context/archive/archiveContext'

export const GroupAddScreen = ({ navigation }) => {
  const { success, clearSuccess, error, clearError, addGroup, loading } =
    useContext(ArchiveContext)

  const apply = (values) => {
    addGroup(values)
    Keyboard.dismiss()
  }

  useEffect(() => {
    if (error) {
      if (Platform.OS === 'web') {
        alert(error)
        clearError()
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  useEffect(() => {
    if (success) {
      if (Platform.OS === 'web') {
        alert('Группа успешно создана')
        clearSuccess()
        navigation.navigate('Groups')
      } else {
        Alert.alert('Успешно!', 'Группа успешно создана', [
          {
            text: 'Ок',
            onPress: () => {
              clearSuccess()
              navigation.navigate('Groups')
            },
            style: 'default'
          }
        ])
      }
    }
  }, [success])

  if (loading) {
    return <AppLoader />
  }

  return (
    <View style={styles.center}>
      <Formik
        initialValues={{
          name: '' // название шага
        }}
        onSubmit={(values) => apply(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <View style={styles.bodyForm}>
            <ScrollView style={styles.form}>
              <View style={styles.formContent}>
                <SCInput
                  onChangeText={handleChange('name')}
                  onBlur={handleBlur('name')}
                  placeholder="Название группы"
                  label="Название группы"
                  autoCapitalize="none"
                  style={styles.marginButton}
                  value={values.name}
                />
              </View>
            </ScrollView>
            <View style={styles.actions}>
              <SCButton text="Добавить" onPress={handleSubmit} />
            </View>
          </View>
        )}
      </Formik>
    </View>
  )
}

GroupAddScreen.navigationOptions = ({ navigation }) => {
  return {
    headerTitle: 'Добавление группы'
  }
}

const styles = StyleSheet.create({
  actions: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  body: {},
  bodyForm: {
    flex: 1
  },
  center: {
    display: 'flex',
    flex: 1,
    paddingVertical: 40,
    width: '100%'
  },
  form: {
    flex: 1,
    width: '100%'
  },
  formContent: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  marginButton: {
    marginBottom: 41
  }
})
