import { Formik } from 'formik'
import React, { useCallback, useContext, useEffect } from 'react'
import {
  View,
  StyleSheet,
  ScrollView,
  Alert,
  KeyboardAvoidingView,
  Platform
} from 'react-native'
import { SCButton } from '../../components/ui/button/SCButton'
import { SCInput } from '../../components/ui/inputs/SCInput'
import { UserContext } from '../../context/user/userContext'
import { AppLoader } from '../../components/ui/AppLoader'
import { DropdownField } from '../../components/ui/DropdownField'
import { DatePickerField } from '../../components/ui/DatePickerField'

export const ProfileScreen = ({ navigation }) => {
  const {
    user,
    loading,
    userUpdate,
    success,
    clearSuccess,
    getUserInfo,
    error,
    clearError
  } = useContext(UserContext)

  const loadUser = useCallback(async () => {
    await getUserInfo(user.id)
  }, [user, getUserInfo])

  useEffect(() => {
    loadUser()
  }, [])

  const update = (values) => {
    userUpdate(user.id, values)
  }

  useEffect(() => {
    if (error === true) {
      if (Platform.OS === 'web') {
        alert(error)
        clearError()
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  useEffect(() => {
    if (success === true) {
      if (Platform.OS === 'web') {
        alert('Данные успешно обновлены')
        clearSuccess()
      } else {
        Alert.alert('Успешно!', 'Данные успешно обновлены', [
          {
            text: 'Ок',
            onPress: () => {
              clearSuccess()
            },
            style: 'default'
          }
        ])
      }
    }
  }, [success])

  if (loading) {
    return <AppLoader />
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 0}
    >
      <ScrollView>
        <View style={styles.body}>
          {!loading && (
            <Formik
              initialValues={{
                firstName: user.firstName,
                lastName: user.lastName,
                middleName: user.middleName ? user.middleName : '',
                profession: user.profession ? user.profession : '',
                dateBirthday: user.dateBirthday ? user.dateBirthday : '',
                email: user.email ? user.email : '',
                gender: user.gender ? user.gender : 1
              }}
              onSubmit={(values) => update(values)}
            >
              {({ handleChange, handleBlur, handleSubmit, values }) => (
                <View style={styles.form}>
                  <SCInput
                    style={styles.marginInputs}
                    onChangeText={handleChange('firstName')}
                    onBlur={handleBlur('firstName')}
                    label="Имя"
                    value={values.firstName}
                  />
                  <SCInput
                    style={styles.marginInputs}
                    onChangeText={handleChange('lastName')}
                    onBlur={handleBlur('lastName')}
                    label="Фамилия"
                    value={values.lastName}
                  />
                  <SCInput
                    onChangeText={handleChange('middleName')}
                    onBlur={handleBlur('middleName')}
                    label="Отчество"
                    value={values.middleName}
                    style={styles.marginInputs}
                  />

                  <DatePickerField
                    style={styles.marginInputs}
                    onChangeText={handleChange('dateBirthday')}
                    onBlur={handleBlur('dateBirthday')}
                    label="Дата рождения"
                    value={values.dateBirthday}
                    name="dateBirthday"
                  />

                  <DropdownField
                    onChangeText={handleChange('gender')}
                    onBlur={handleBlur('gender')}
                    value={values.gender}
                    placeholder="Пол"
                    label="Пол"
                    items={[
                      {
                        label: 'Мужской',
                        value: 1
                      },
                      {
                        label: 'Женский',
                        value: 2
                      }
                    ]}
                    checkerDisable={true}
                    name="gender"
                    id={'gender'}
                  ></DropdownField>

                  <SCInput
                    style={styles.marginInputs}
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    label="Email"
                    autoCorrect={false}
                    autoCapitalize="none"
                    value={values.email}
                  />

                  <SCInput
                    onChangeText={handleChange('profession')}
                    onBlur={handleBlur('profession')}
                    label="Профессия"
                    value={values.profession}
                    style={styles.marginButton}
                  />

                  <View>
                    <SCButton
                      text="Сохранить"
                      onPress={handleSubmit}
                      disabled={loading}
                    />
                  </View>
                </View>
              )}
            </Formik>
          )}
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
}

ProfileScreen.navigationOptions = {
  headerTitle: 'Профиль'
}

const styles = StyleSheet.create({
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%'
  },
  body: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 40
  },
  form: {
    alignItems: 'center',
    minHeight: 300,
    width: '100%'
  },
  marginButton: {
    marginBottom: 41
  },
  marginInputs: {
    marginBottom: 13
  }
})
