import { ExerciseContext } from '../../../context/exercise/exerciseContext';
import { Button, Dimensions, Platform, Text, TouchableOpacity, View } from 'react-native';
import { Audio, Video } from 'expo-av';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { AppLoader } from '../../../components/ui/AppLoader';
import { THEME } from '../../../theme';
import * as ScreenOrientation from 'expo-screen-orientation';
import Player from 'react-player';

export const VideoScreen = ({ navigation }) => {
  const [soundObj, setSound] = useState();
  const video = React.useRef(null);
  const [playing, setPlaying] = useState(true);
  const player = React.useRef(null);
  const [status, setStatus] = React.useState({});
  const { exerciseTeach, getExerciseVideo, loading, success } = useContext(ExerciseContext);
  const [stage, setStage] = useState('');
  const [show, setShow] = useState(true);
  let url = 'https://drive.google.com/file/d/1Y-MnYde88HNxYzTnoNSA6Gdxwp7UdI85';

  const onFullSize = async ({ fullscreenUpdate }) => {
    if (Platform.OS === 'android') {
      if (fullscreenUpdate === Video.FULLSCREEN_UPDATE_PLAYER_DID_PRESENT) {
        await ScreenOrientation.unlockAsync();
      } else if (fullscreenUpdate === Video.FULLSCREEN_UPDATE_PLAYER_WILL_DISMISS) {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getExerciseVideo(navigation);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await Audio.requestPermissionsAsync();
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        await Audio.setAudioModeAsync({
          staysActiveInBackground: true,
          interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
          shouldDuckAndroid: false,
          playThroughEarpieceAndroid: false,
          allowsRecordingIOS: false,
          interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
          playsInSilentModeIOS: true,
        });
      } else {
        await Audio.setAudioModeAsync({
          staysActiveInBackground: true,
          shouldDuckAndroid: false,
          playThroughEarpieceAndroid: false,
          allowsRecordingIOS: false,
          playsInSilentModeIOS: true,
        });
      }
    })();

    // Tricks to fix that the audio doesn't work even if we set the configuration on iOS: `playsInSilentModeIOS`
    if (Platform === 'ios') {
      (async () => {
        const { sound } = await Audio.Sound.createAsync(require('../../../../assets/sounds/500-milliseconds-of-silence.mp3'));
        setSound(sound);
      })();
    }
  }, []);

  useEffect(() => {
    const playSilentSound = async () => {
      // await sound.loadAsync(
      //   require("../../../../assets/")
      // );
      if (!soundObj) return;
      await soundObj.playAsync();
      await soundObj.setIsLoopingAsync(true);
    };

    (async () => {
      await playSilentSound();
    })();

    return () => {
      (async () => {
        if (!soundObj) return;
        soundObj.stopAsync();
        soundObj.unloadAsync();
      })();
    };
  }, [soundObj]);

  if (loading) {
    return <AppLoader />;
  }

  const next = (newStatus) => {
    if (stage === 3 && newStatus.didJustFinish) {
      if (show) {
        setShow(false);
      }
    }

    if (stage === 2) {
      status.uri = exerciseTeach[1].url;
    }

    if (stage === '') {
      status.positionMillis = 0;
    } else if (stage === 2) {
      if (Platform.OS === 'web') {
        if (show) {
          setShow(false);
        }
      } else {
        video.current.replayAsync();
        setStage(3);
      }
    } else if (stage !== 2) {
      setStatus(newStatus);
    }
  };

  const toHome = () => {
    if (Platform.OS === 'web') {
      setPlaying(false);
    } else {
      video.current.stopAsync();
      video.current.unloadAsync();
    }
    navigation.navigate('Auth');
  };

  const replayVideo = () => {
    setPlaying(false); // Stop the video
    player.current.seekTo(0);
    setTimeout(() => setPlaying(true), 100); // Start the video after a short delay
  };

  const repeat = () => {
    if (Platform.OS === 'web') {
      replayVideo();
    } else {
      setStage('');
      video.current.replayAsync();
    }
  };

  const toPay = async () => {
    if (Platform.OS !== 'web') {
      video.current.stopAsync();
      video.current.unloadAsync();
    } else {
      setPlaying(false);
    }
    navigation.navigate('ChooseSubscriptionBegin');
  };

  const isURL = () => {
    if (success) {
      if (stage === '' || stage === 1) {
        if (status.didJustFinish) {
          if (stage !== 2) {
            setStage(2);
          }
          status.positionMillis = 0;
          return exerciseTeach[1].url;
        }

        if (!show) {
          setShow(true);
        }

        if (stage !== 1) {
          setStage(1);
        }
        url = exerciseTeach[0]?.url;
        return exerciseTeach[0]?.url;
      } else if (stage === 1) {
        if (status.didJustFinish) {
          if (stage !== 2) {
            setStage(2);
          }
          status.positionMillis = 0;
          return exerciseTeach[1].url;
        }

        if (!show) {
          setShow(true);
        }
        url = exerciseTeach[0].url;
        return exerciseTeach[0].url;
      } else if (stage >= 2) {
        if (status.didJustFinish) {
          if (stage === 2) {
            status.positionMillis = 0;
            status.didJustFinish = false;
          }
          if (stage === 3 && status.durationMillis - status.positionMillis <= 1000 && status.positionMillis > 0) {
            if (show) {
              setShow(false);
            }
            video.current.stopAsync();
            video.current.unloadAsync();
          }
        }

        url = exerciseTeach[1].url;
        return exerciseTeach[1].url;
      }
    }
    return '';
  };

  return (
    <View style={styles.container}>
      {Platform.OS === 'web' ? (
        <View style={{ position: 'relative', minHeight: '300px', paddingTop: '56.25%' }}>
          <Player
            url={isURL()}
            playing={playing}
            style={{ position: 'absolute', top: -100, left: 0 }}
            width="100%"
            height="100%"
            controls
            ref={player}
            onEnded={() => next({ didJustFinish: true })}
          />
        </View>
      ) : (
        <View>
          <Video
            ref={video}
            source={{
              uri: `${isURL()}`,
              headers: {
                'User-Agent': '4E38221A-8BF3-4302-8C49-0F6E5B91B64B',
              },
            }}
            downloadFirst={false}
            shouldPlay={true}
            useNativeControls={true}
            ignoreSilentSwitch="ignore"
            resizeMode="contain"
            style={{ width: Dimensions.get('window').width, height: '80%' }}
            isLooping={false}
            onFullscreenUpdate={onFullSize}
            onPlaybackStatusUpdate={(status) => next(status)}
          />
        </View>
      )}
      <View style={styles.buttons}>
        <TouchableOpacity onPress={repeat}>
          <View style={styles.button}>
            <Text style={styles.text}>{'Посмотреть еще раз'}</Text>
          </View>
        </TouchableOpacity>

        <TouchableOpacity disabled={show} onPress={toPay}>
          <View style={styles.button}>
            <Text style={[styles.text, { color: show ? 'gray' : 'black' }]}>{'Продолжить после просмотра видео'}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={toHome}>
          <View style={styles.button}>
            <Text style={styles.text}>{'Главная'}</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = {
  button: {
    borderRadius: 20,
    borderWidth: 1,
    width: 270,
    paddingVertical: 15,
    marginBottom: 5,
    borderColor: THEME.MAIN_DARK,
  },
  buttons: {
    marginTop: -50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: THEME.MAIN_DARK,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#ecf0f1',
  },
};
