import { Formik } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Platform,
  Alert,
  ScrollView
} from 'react-native'
import { AppLoader } from '../../components/ui/AppLoader'
import { SCButton } from '../../components/ui/button/SCButton'
import { DropdownField } from '../../components/ui/DropdownField'
import { SCInput } from '../../components/ui/inputs/SCInput'
import { UserContext } from '../../context/user/userContext'

export const ErrorMessageScreen = ({}) => {
  const {
    sendIssue,
    getIssueTypes,
    loading,
    success,
    clearSuccess,
    error,
    clearError
  } = useContext(UserContext)

  const [reportTypes, setReportTypesId] = useState([])
  const [reportTypeId, setReportTypeId] = useState(0)

  const send = (values) => {
    sendIssue({
      issueReportTypeId: reportTypeId,
      text: values.text
    })
  }

  const loadTypes = useCallback(async () => {
    const types = await getIssueTypes()
    console.log('TYPES: ', types)

    const convertedTypes = []
    types.forEach((x) => {
      convertedTypes.push({ value: x.id, label: x.name })
    })

    setReportTypesId(convertedTypes)
  }, [getIssueTypes])

  useEffect(() => {
    loadTypes()
  }, [])

  useEffect(() => {
    if (error) {
      if (Platform.OS === 'web') {
        alert(error)
        clearError()
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  useEffect(() => {
    if (success) {
      if (Platform.OS === 'web') {
        alert('Данные об ошибке успешно отправлены')
        clearSuccess()
      } else {
        Alert.alert('Успешно!', 'Данные об ошибке успешно отправлены', [
          {
            text: 'Ок',
            onPress: () => {
              clearSuccess()
            },
            style: 'default'
          }
        ])
      }
    }
  }, [success])

  if (loading) {
    return <AppLoader />
  }

  return (
    <View style={styles.center}>
      <ScrollView style={styles.body}>
        <Formik
          initialValues={{
            issueReportTypeId: '', // цель - дропдаун
            text: '' // название шага
          }}
          onSubmit={(values) => send(values)}
        >
          {({ handleChange, handleBlur, handleSubmit, values }) => (
            <View style={styles.form}>
              <DropdownField
                onChangeText={handleChange('issueReportTypeId')}
                onBlur={handleBlur('issueReportTypeId')}
                onSelectOption={(id) => setReportTypeId(id)}
                value={values.issueReportTypeId}
                placeholder="Тип ошибки"
                label="Тип ошибки"
                items={reportTypes}
                name="issueReportTypeId"
                id={'issueReportTypeId'}
              ></DropdownField>

              <SCInput
                onChangeText={handleChange('text')}
                onBlur={handleBlur('text')}
                placeholder="Описание"
                label="Описание"
                style={styles.marginButton}
                value={values.text}
                maxLength={1000}
                returnKeyType="done"
                multiline
                numberOfLines={Platform.OS === 'ios' ? null : 4}
                minHeight={Platform.OS === 'ios' && 4 ? 20 * 4 : null}
                underlineColorAndroid={'rgba(0,0,0,0)'}
              />

              <View style={styles.actions}>
                <SCButton text="Отправить" onPress={handleSubmit} />
              </View>
            </View>
          )}
        </Formik>
      </ScrollView>
    </View>
  )
}

ErrorMessageScreen.navigationOptions = {
  headerTitle: 'Сообщить об ошибке'
}

const styles = StyleSheet.create({
  body: {},
  center: {
    display: 'flex',
    flex: 1,
    paddingVertical: 40,
    width: '100%'
  },
  form: {
    alignItems: 'center',
    height: 320,
    justifyContent: 'space-between',
    width: '100%'
  },
  marginButton: {
    marginBottom: 41
  }
})
