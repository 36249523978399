import React, { useState } from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { THEME } from '../../../theme'

export const SCButton = ({ text, onPress, style, disabled }) => {
  return (
    <TouchableOpacity onPress={onPress} style={style} disabled = {disabled}>
      <View style={styles.button}>
        <Text style={styles.text}>{text}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {

    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    paddingVertical: 15,
    width: 270
  },
  text: {
    color: THEME.MAIN_DARK,
    textAlign: 'center'
  }
})
