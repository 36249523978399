export const THEME = {
  MAIN_GREY: '#eae9e9',
  MAIN_COLOR: '#724dea',
  MAIN_DARK: '#262626',
  GRADIENT_LEFT: '#6e38dc',
  GRADIENT_RIGHT: '#e77af4',
  ACCENT_RED: '#ea4d4d',
  ACCENT_DARK_BLUE: '#4d79ea',
  ACCENT_PINK: '#ea4dc7',
  ACCENT_LIGHT_BLUE: '#4db8ea',
  ACCENT_LIGHT_PURPLE: '#a64dea',
  ACCENT_GREEN: '#4dea6b',
  ACCENT_DARK_PURPLE: '#724dea',
  ACCENT_YELLOW: '#eae14d'
}
