import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  Text,
  Alert,
  TouchableOpacity,
  Modal,
  ScrollView,
  Platform
} from 'react-native'
import { THEME } from '../../../theme'
import { AppLoader } from '../../../components/ui/AppLoader'
import { ArchiveContext } from '../../../context/archive/archiveContext'
import { SCButton } from '../../../components/ui/button/SCButton'
import { CONFIG } from '../../../config'
import { getData } from '../../../services/storage.service'
import * as FileSystem from 'expo-file-system'
import ImageViewer from 'react-native-image-zoom-viewer'

async function downloadFile (currentId) {
  let token = ''

  await getData('currentUser').then((response) => {
    if (response) {
      token = response.token
    }
  })

  const fileLocation =
    FileSystem.documentDirectory + 'tempImage' + new Date().getTime() + '.jpg'
  return FileSystem.downloadAsync(
    `${CONFIG.API_URL}UserPhotoArchive/photoFS/${currentId}`,
    fileLocation,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
    .then(({ uri }) => {
      return uri
    })
    .catch((error) => {
      console.error(error)
    })
}

export const PhotoViewScreen = ({ navigation }) => {
  const { removePhotoById, loading, error, clearError, getPhotoById, getFSPhtoto } =
    useContext(ArchiveContext)

  const [image, setImage] = useState()
  const [isImageFullSize, setIsImageFullSize] = useState(false)
  const [imageLoading, setImageLoading] = useState()

  const groupId = navigation.getParam('groupId')
  const currentId = navigation.getParam('photoId')
  const currentName = navigation.getParam('photoName')

  useEffect(() => {
    loadPhoto()
  }, [])

  const loadPhoto = useCallback(async () => {
    let response = null
    setImageLoading(true)
    if (Platform.OS === 'web') {
      response = await getFSPhtoto(currentId)
    } else {
      response = await downloadFile(currentId)
    }
    setImage(response)
    setImageLoading(false)
  }, [])

  const openImage = () => {
    setIsImageFullSize(true)
  }

  const closeImage = () => {
    setIsImageFullSize(false)
  }

  if (error) {
    if (Platform.OS === 'web') {
      alert(error)
      clearError()
    } else {
      Alert.alert('Ошибка!', error, [
        {
          text: 'Ок',
          onPress: () => {
            clearError()
          },
          style: 'cancel'
        }
      ])
    }
  }

  const remove = () => {
    removePhotoById(currentId, navigation, groupId)
  }

  if (loading) {
    return <AppLoader />
  }

  return (
    <ScrollView>
      <View style={styles.center}>
        <View style={styles.bodyForm}>
          <TouchableOpacity onPress={openImage} style={styles.form}>
            <Text style={styles.label}>{currentName}</Text>
            {imageLoading && <AppLoader />}
            {!imageLoading && (
              <View style={styles.image}>
                <Image
                  key={currentId}
                  style={{ alignSelf: 'stretch', flex: 1, width: 'auto' }}
                  resizeMode={Platform.OS === 'ios' ? 'contain' : 'contain'}
                  source={{
                    uri: image
                  }}
                />
              </View>
            )}
            {!imageLoading && (
              <Text style={styles.tooltip}>
                Для просмотра нажмите на фотографию
              </Text>
            )}
          </TouchableOpacity>
          <View style={styles.actions}>
            {!imageLoading && <SCButton text="Удалить" onPress={remove} />}
          </View>
        </View>
        <Modal visible={isImageFullSize} transparent={true}>
          <TouchableOpacity style={styles.closeArea} onPress={closeImage}>
            <Text style={styles.closeButton}>Закрыть</Text>
          </TouchableOpacity>
          <ImageViewer imageUrls={[{ url: image }]} />
        </Modal>
      </View>
    </ScrollView>
  )
}

PhotoViewScreen.navigationOptions = ({ navigation }) => {
  return {
    headerTitle: 'Просмотр фотографии'
  }
}
const styles = StyleSheet.create({
  actions: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  body: {},
  bodyForm: {
    flex: 1
  },
  center: {
    alignSelf: 'center',
    display: 'flex',
    flex: 1,
    marginBottom: 10,
    marginTop: 10,

    paddingHorizontal: 30,
    paddingVertical: 40,
    width: '100%',
    width: '100%'
  },
  closeArea: {
    backgroundColor: '#fff',
    paddingTop: 60,
    width: '100%'
  },
  closeButton: {
    color: THEME.MAIN_COLOR,
    fontSize: 16,
    fontWeight: '500',
    paddingBottom: 10,
    paddingLeft: 20
  },
  form: {
    flex: 1,
    width: '100%'
  },
  formContent: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  image: {
    //  flex: 1,
    height: 300,
    width: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    resizeMode: 'contain'
  },
  label: {
    color: THEME.MAIN_DARK,
    fontSize: 18,
    marginBottom: 18
  },
  marginButton: {
    marginBottom: 41
  },
  tooltip: {
    color: THEME.MAIN_DARK,
    fontSize: 14,
    fontStyle: 'italic',
    marginBottom: 40,
    marginTop: 10,
    textAlign: 'center'
  }
})
