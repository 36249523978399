import { Formik } from 'formik'
import React, { useContext } from 'react'
import {
  View,
  StyleSheet,
  Alert,
  Keyboard,
  ImageBackground,
  ScrollView,
  KeyboardAvoidingView,
  Text,
  Platform
} from 'react-native'
import { AppLoader } from '../../components/ui/AppLoader'
import { UserContext } from '../../context/user/userContext'
import bodyStyles from '../../styles/bodyStyles'
import { SCButton } from '../../components/ui/button/SCButton'
import { SCInput } from '../../components/ui/inputs/SCInput'
import alertMultiplatform from '../../utils/alertMultiplatform'

export const LoginScreen = ({ navigation }) => {
  const { userLogin, loading, error, clearError } = useContext(UserContext)

  const login = (values) => {
    if (values.username !== '' && values.password !== '') {
      userLogin(values, navigation)
    } else {
      alertMultiplatform({ title: 'Ошибка!', message: 'Заполните логин и пароль' })
      Keyboard.dismiss()
    }
  }

  const toRegistration = () => {
    navigation.navigate('Registration')
  }

  const toRecovery = () => {
    navigation.navigate('Recovery')
  }

  if (loading) {
    return <AppLoader />
  }

  if (error) {
    if (Platform.OS === 'web') {
      alert(error)
      clearError()
    } else {
      Alert.alert('Ошибка!', error, [
        {
          text: 'Ок',
          onPress: () => {
            clearError()
          },
          style: 'cancel'
        }
      ])
    }
  }
  //  username: "test@mail.ru", password: "test"
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 0}
      style={{ height: '100%' }}
    >
      <ScrollView style={bodyStyles.main}>
        <View style={styles.logoContainer}>
          <ImageBackground
            resizeMode="contain"
            style={styles.logo}
            source={require('../../../assets/logo.png')}
          />
        </View>

        <View style={styles.body}>
          <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={(values) => login(values)}
          >
            {({ handleChange, handleBlur, handleSubmit, values }) => (
              <View style={styles.form}>

                <SCInput
                  label="Логин/e-mail"
                  onChangeText={handleChange('username')}
                  onBlur={handleBlur('username')}
                  placeholder="Логин"
                  autoCorrect={false}
                  autoCapitalize="none"
                  style={styles.formMarginBottom}
                  value={values.username}
                />
                <SCInput
                  label="Пароль"
                  onChangeText={handleChange('password')}
                  onBlur={handleBlur('password')}
                  secureTextEntry={true}
                  autoCorrect={false}
                  autoCapitalize="none"
                  value={values.password}
                />
                <Text onPress={toRecovery}>Забыли пароль?</Text>
                <View style={styles.actions}>
                  <SCButton
                    text="Войти"
                    onPress={handleSubmit}
                    disabled={loading}
                    style={styles.buttonMargin}
                  />
                  <SCButton text="Регистрация" onPress={toRegistration} />
                </View>
              </View>
            )}
          </Formik>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
}

LoginScreen.navigationOptions = {
  headerTitle: 'Авторизация'
}

const styles = StyleSheet.create({
  actions: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 140,
    width: '100%'
  },
  body: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 40
  },
  buttonMargin: {
    marginBottom: 41
  },
  form: {
    alignItems: 'center',
    width: '100%'
  },
  formMarginBottom: {
    marginBottom: 24
  },
  logo: {
    alignItems: 'center',
    height: 75,
    justifyContent: 'center',
    resizeMode: 'stretch',
    width: 320
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 96
  }
})
