import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import { THEME } from '../../theme'
import { SimpleLineIcons } from '@expo/vector-icons'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { ExerciseContext } from '../../context/exercise/exerciseContext'
import { AppLoader } from '../../components/ui/AppLoader'

export const MainScreen = ({ navigation }) => {
  const { exercise, getExerciseList, loading } = useContext(ExerciseContext)
  const forceRender = navigation.getParam('forceRender') ?? 0
  const [openLevels, setOpenLevels] = useState([])

  useEffect(() => {
    const isFocused = navigation.isFocused()
    if (isFocused) {
      getExerciseList(navigation)
    }
  }, [forceRender])

  useEffect(() => {
    const openLevels = exercise.filter(ex => ex.items.some(item => item.isBuyed === true)).map(ex => ex.level)
    setOpenLevels(openLevels)
  }, [exercise])

  if (loading) {
    return <AppLoader />
  }

  const generateKey = (pre) => {
    let firstPart = (Math.random() * 46656) | 0
    let secondPart = (Math.random() * 46656) | 0
    firstPart = ('000' + firstPart.toString(36)).slice(-3)
    secondPart = ('000' + secondPart.toString(36)).slice(-3)
    return firstPart + secondPart
  }

  const toTasks = (exerciseNumber, groupId, isComplete) => {
    navigation.navigate('Tasks', {
      exerciseNumber,
      isComplete,
      groupId
    })
  }

  const toSubscription = (level) => {
    const nextLevel = openLevels[openLevels.length - 1] + 1
    if (level > nextLevel) {
      alert(`Вы должны купить предыдущий уровень для того, чтобы открыть уровень ${level}`)
      return
    }
    navigation.navigate('ChooseLevel', {
      level
    })
  }

  const completedRow = (groupId, item) => {
    return (
      <TouchableOpacity
        onPress={() => toTasks(item.exerciseNumber, groupId, true)}
        style={styles.taskRow}
      >
        <Text style={styles.taskRowCompleted}>{item.name}</Text>
      </TouchableOpacity>
    )
  }

  const availableRow = (groupId, item) => {
    return (
      <TouchableOpacity
        onPress={() => toTasks(item.exerciseNumber, groupId, false)}
        style={styles.taskRow}
      >
        <Text style={styles.taskRowActive}>{item.name}</Text>
      </TouchableOpacity>
    )
  }

  const disabledRow = (item, groupId) => {
    const isOpen = exercise.filter(ex => ex.level === item.level).some(ex => ex.items.some(item => item.isBuyed === true))
    return (
      <TouchableOpacity onPress={() => {
        isOpen ? toTasks(item.exerciseNumber, groupId, false) : toSubscription(item.level)
      }}>
        <View style={styles.taskRow}>
          <Text>{item.name}</Text>
          <SimpleLineIcons name="lock" size={18} color={THEME.MAIN_DARK} />
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <View style={styles.container}>
      <ScrollView style={styles.taskList}>
        {exercise.length !== 0 &&
          exercise.map((group) => (
            <View key={generateKey(group.id)}>
              <Text style={styles.level}>{group.title}</Text>
              {group.items.length !== 0 &&
                group.items.map((item, index) => (
                  <View key={generateKey(item.exerciseNumber)}>
                    {
                      item.isBuyed === true &&
                      item.isWatched === true &&
                      completedRow(group.id, item)
                    }
                    {
                      item.isBuyed === true &&
                      item.isWatched === false &&
                      availableRow(group.id, item)
                    }
                    {
                      item.isBuyed === false && disabledRow(item, group.id)
                    }
                  </View>
                ))}
            </View>
          ))}
      </ScrollView>
    </View>
  )
}

MainScreen.navigationOptions = {
  headerTitle: 'Упражнения'
}

const styles = StyleSheet.create({
  blur: {
    backgroundColor: 'black',
    height: 200
  },
  container: {
    flex: 1 // Add flex: 1 to make the container take up the available space
  },
  level: {
    fontSize: 18,
    marginBottom: 18,
    marginLeft: 14
  },
  taskList: {
    flexDirection: 'column',
    padding: 18
  },
  taskRow: {
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 2,
    color: THEME.MAIN_DARK,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 18,
    padding: 14
  },
  taskRowActive: {
    color: THEME.ACCENT_PINK
  },
  taskRowCompleted: {
    color: '#949494'
  }
})
