// REACT
import React from 'react';
import { Image, Platform, StyleSheet } from 'react-native';
import { createAppContainer, createSwitchNavigator } from 'react-navigation';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createStackNavigator } from 'react-navigation-stack';
import { createMaterialBottomTabNavigator } from 'react-navigation-material-bottom-tabs';
// SCREENS
import { MainScreen } from '../screens/main/MainScreen';
import { WheelScreen } from '../screens/wheel/WheelScreen';
import { LoginScreen } from '../screens/auth/LoginScreen';
import { AuthLoadingScreen } from '../screens/auth/AuthLoadingScreen';
import { RegisterScreen } from '../screens/auth/RegisterScreen';
import { ErrorMessageScreen } from '../screens/common/ErrorMessageScreen';
import { FAQScreen } from '../screens/common/FAQScreen';
import { TasksScreen } from '../screens/main/TasksScreen';
import { RecoveryScreen } from '../screens/auth/RecoveryScreen';

// COMMON
import { THEME } from '../theme';
import { CommonScreen } from '../screens/common/CommonScreen';
import { ProfileScreen } from '../screens/common/ProfileScreen';
import { GreetingsScreen } from '../screens/auth/GreetingsScreen';
import { DiaryScreen } from '../screens/diary/DiaryScreen';
import { PracticeScreen } from '../screens/practice/PracticeScreen';
import { DevelopScreen } from '../screens/common/DevelopScreen';
import { WheelTargetsScreen } from '../screens/wheel/WheelTargetsScreen';
import { TargetsScreen } from '../screens/diary/targets/TargetsScreen';
import { StepsScreen } from '../screens/diary/steps/StepsScreen';
import { StepAddScreen } from '../screens/diary/steps/StepAddScreen';
import { StepViewScreen } from '../screens/diary/steps/StepViewScreen';
import { InsiteViewScreen } from '../screens/practice/insites/InsiteViewScreen';
import { InsitesScreen } from '../screens/practice/insites/InsitesScreen';
import { InsiteAddScreen } from '../screens/practice/insites/InsiteAddScreen';
import { ArchiveGroupsScreen } from '../screens/practice/archive/ArchiveGroupsScreen';
import { ArchiveGroupViewScreen } from '../screens/practice/archive/ArchiveGroupViewScreen';
import { GroupAddScreen } from '../screens/practice/archive/GroupAddScreen';
import { PhotoAddScreen } from '../screens/practice/archive/PhotoAddScreen';
import { PhotoViewScreen } from '../screens/practice/archive/PhotoViewScreen';
import { TargetViewScreen } from '../screens/diary/targets/TargetViewScreen';
import { ChangePasswordScreen } from '../screens/common/ChangePasswordScreen';

// Admin
import { AdminScreen } from '../screens/auth/adminWindows/AdminScreen';
import { UsersPromoCodsScreen } from '../screens/auth/adminWindows/UsersPromocodsScreen';
import { PromoCodsScreen } from '../screens/auth/adminWindows/PromoCodsScreen';
import BeforeStartScreen from '../screens/auth/RegistrationWindows/BeforeStartScreen';
import { VideoScreen } from '../screens/auth/RegistrationWindows/VideoScreen';
import { SubscriptionConfirmScreen } from '../screens/auth/RegistrationWindows/SubscriptionConfirmScreen';
import Animation from '../screens/auth/RegistrationWindows/Animation';
import { ChooseSubscription } from '../screens/auth/RegistrationWindows/ChooseSubscription';
// Навигация авторизации
const AuthStack = createStackNavigator(
  {
    Login: LoginScreen,
    Registration: RegisterScreen,
    Recovery: RecoveryScreen,
  },
  {
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: Platform.OS === 'android' ? THEME.MAIN_COLOR : '#fff',
      },
      headerTintColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
    },
  }
);

// Навигация после регистрации
const AnimationBeforeStart = createStackNavigator(
  {
    Loading: Animation,
  },
  {
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: Platform.OS === 'android' ? THEME.MAIN_COLOR : '#fff',
      },
      headerTintColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
    },
  }
);

const Admin = createStackNavigator(
  {
    AdminScreen,
    UsersPromoCods: UsersPromoCodsScreen,
    PromoCods: PromoCodsScreen,
  },
  {
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: Platform.OS === 'android' ? THEME.MAIN_COLOR : '#fff',
      },
      headerTintColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
    },
  }
);
// Навигация видео
const VideoAfterRegistration = createStackNavigator(
  {
    Video: VideoScreen,
    ChooseSubscriptionBegin: {
      screen: ChooseSubscription,
      navigationOptions: {
        headerBackTitle: 'Назад',
      },
    },
    Subscription: {
      screen: SubscriptionConfirmScreen,
      navigationOptions: {
        headerBackTitle: 'Назад',
      },
    },
  },
  {
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: Platform.OS === 'android' ? THEME.MAIN_COLOR : '#fff',
      },
      headerTintColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
    },
  }
);

const AfterRegistration = createStackNavigator(
  {
    BeforeStart: BeforeStartScreen,
  },
  {
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: Platform.OS === 'android' ? THEME.MAIN_COLOR : '#fff',
      },
      headerTintColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
    },
  }
);

// Навигация главной страницы
const AppNavigator = createStackNavigator(
  {
    Main: MainScreen,
    ChooseLevel: {
      screen: ChooseSubscription,
      navigationOptions: {
        headerBackTitle: 'Назад',
      },
    },
    Subscription: {
      screen: SubscriptionConfirmScreen,
      navigationOptions: {
        headerBackTitle: 'Назад',
      },
    },
    Tasks: {
      screen: TasksScreen,
      navigationOptions: {
        headerBackTitle: 'Назад',
      },
    },
  },
  {
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: Platform.OS === 'android' ? THEME.MAIN_COLOR : '#fff',
      },
      headerTintColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
    },
  }
);

// Навигация страницы Колесо целей
const WheelNavigator = createStackNavigator(
  {
    Wheel: WheelScreen,
    WheelTargets: {
      screen: WheelTargetsScreen,
      navigationOptions: {
        headerBackTitle: 'Назад',
      },
    },
  },
  {
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: Platform.OS === 'android' ? THEME.MAIN_COLOR : '#fff',
      },
      headerTintColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
    },
  }
);

// Навигация страницы Дневник
const DiaryNavigator = createStackNavigator(
  {
    Diary: DiaryScreen,
    Targets: TargetsScreen,
    TargetView: TargetViewScreen,
    Steps: StepsScreen,
    StepView: StepViewScreen,
    StepAdd: StepAddScreen,
  },
  {
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: Platform.OS === 'android' ? THEME.MAIN_COLOR : '#fff',
      },
      headerTintColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
    },
  }
);

// Навигация страницы Дневник
const PracticeNavigator = createStackNavigator(
  {
    Practice: PracticeScreen,
    Insite: InsiteViewScreen,
    InsiteAdd: InsiteAddScreen,
    Insites: InsitesScreen,
    Groups: ArchiveGroupsScreen, // Архив
    GroupView: ArchiveGroupViewScreen, // Просмотр группы
    GroupAdd: GroupAddScreen, // Добавление группы
    PhotoAdd: {
      screen: PhotoAddScreen,
      navigationOptions: {
        headerBackTitle: 'Назад',
      },
    }, // Добавление фото
    PhotoView: {
      screen: PhotoViewScreen,
      navigationOptions: {
        headerBackTitle: 'Назад',
      },
    }, // Просмотр фото
  },
  {
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: Platform.OS === 'android' ? THEME.MAIN_COLOR : '#fff',
      },
      headerTintColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
    },
  }
);

const CommonNavigator = createStackNavigator(
  {
    Common: CommonScreen,
    Profile: ProfileScreen,
    ChangePassword: ChangePasswordScreen,
    FAQ: FAQScreen,
    ErrorReport: ErrorMessageScreen,
    Develop: DevelopScreen,
  },
  {
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: Platform.OS === 'android' ? THEME.MAIN_COLOR : '#fff',
      },
      headerTintColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
    },
  }
);

const bottomTabsConfig = {
  App: {
    screen: AppNavigator,
    navigationOptions: {
      tabBarLabel: ' ',
      tabBarIcon: ({ focused }) => (
        <Image
          style={styles.icon}
          source={
            focused
              ? require('../../assets/icons/bottom-nav/pngs/exersise_active.png')
              : require('../../assets/icons/bottom-nav/pngs/exersise.png')
          }
        />
      ),
    },
  },
  Diary: {
    screen: DiaryNavigator,
    navigationOptions: {
      tabBarLabel: ' ',
      tabBarIcon: ({ focused }) => (
        <Image
          style={styles.icon}
          source={
            focused
              ? require('../../assets/icons/bottom-nav/pngs/calendar_active.png')
              : require('../../assets/icons/bottom-nav/pngs/calendar.png')
          }
        />
      ),
    },
  },
  Wheel: {
    screen: WheelNavigator,
    navigationOptions: {
      tabBarLabel: ' ',
      tabBarIcon: ({ focused }) => (
        <Image
          style={styles.icon}
          source={
            focused
              ? require('../../assets/icons/bottom-nav/pngs/wheel_active.png')
              : require('../../assets/icons/bottom-nav/pngs/wheel.png')
          }
        />
      ),
    },
  },
  Practice: {
    screen: PracticeNavigator,
    navigationOptions: {
      tabBarLabel: ' ',
      tabBarIcon: ({ focused }) => (
        <Image
          style={styles.icon}
          source={
            focused
              ? require('../../assets/icons/bottom-nav/pngs/diary_active.png')
              : require('../../assets/icons/bottom-nav/pngs/diary.png')
          }
        />
      ),
    },
  },
  Common: {
    screen: CommonNavigator,
    navigationOptions: {
      tabBarLabel: ' ',
      tabBarIcon: ({ focused }) => (
        <Image
          style={styles.icon}
          source={
            focused
              ? require('../../assets/icons/bottom-nav/pngs/account_active.png')
              : require('../../assets/icons/bottom-nav/pngs/account.png')
          }
        />
      ),
    },
  },
};

const navigator =
  Platform.OS === 'android'
    ? createMaterialBottomTabNavigator(bottomTabsConfig, {
        activeTintColor: '#fff',
        barStyle: {
          backgroundColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
        },
      })
    : createBottomTabNavigator(bottomTabsConfig, {
        tabBarOptions: {
          activeTintColor: Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR,
        },
      });

export const AppNavigation = createAppContainer(
  createSwitchNavigator(
    {
      AuthLoading: AuthLoadingScreen,
      Greetings: GreetingsScreen,
      Auth: AuthStack,
      App: navigator,
      AnimationBeforeStart,
      AfterRegistration,
      VideoAfterRegistration,
      Admin,
    },
    {
      initialRouteName: 'AuthLoading',
    }
  )
);

const styles = StyleSheet.create({
  icon: {
    height: 32,
    marginTop: Platform.OS === 'android' || Platform.OS === 'web' ? 0 : 18,
    width: 32,
  },
});
