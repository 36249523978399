import { StatusBar } from 'expo-status-bar'
import React, { useState } from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import { Checkbox, Divider, List } from 'react-native-paper'

export const DevelopScreen = ({}) => {
  const [check, setCheck] = useState('checked')

  const handleCheck = () => {
    if (check === 'checked') {
      setCheck('unchecked')
    } else {
      setCheck('checked')
    }
  }

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <Text style={styles.headerFirst}>UI KIT</Text>
        <Text style={styles.headerSecond}>Accordion</Text>
        <List.Section title="Accordion">
          <List.Accordion
            title="Accordion"
            left={(props) => <List.Icon {...props} icon="folder" />}
          >
            <Text>There is the Accordion</Text>
          </List.Accordion>
        </List.Section>
        <Divider />
        <Text style={styles.headerSecond}>CheckBox</Text>
        <Checkbox.Item label="Item" status={check} onPress={handleCheck} />
        <Divider />
      </ScrollView>
    </View>
  )
}

DevelopScreen.navigationOptions = {
  headerTitle: 'DEVELOP ONLY'
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: StatusBar.currentHeight
  },
  headerFirst: {
    fontSize: 28
  },
  headerSecond: {
    fontSize: 16
  },
  scrollView: {
    backgroundColor: 'white',
    paddingHorizontal: 10,
    paddingTop: 20
  },
  text: {
    fontSize: 12
  }
})
