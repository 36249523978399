import { THEME } from '../../../theme';
import {
  ActivityIndicator,
  KeyboardAvoidingView,
  Linking,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { CheckBox } from 'react-native-elements';
import { CONFIG } from '../../../config';
import { Http } from '../../../services/http';
import { getData } from '../../../services/storage.service';
import WebView from 'react-native-webview';
import alertMultiplatform from '../../../utils/alertMultiplatform';
import { getPrice } from '../../../api/subscribe';
import ResponsiveBtn from '../../../components/ui/button/ResponsiveBtn';

export const SubscriptionConfirmScreen = ({ navigation }) => {
  const [price, setPrice] = useState(null);
  const [isSelected, setSelected] = useState();
  const [isColor, setColor] = useState('gray');
  const [promoCode, setPromoCode] = useState('');
  const [token, setToken] = useState('');
  const [isPay, setPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const level = navigation.getParam('level', 1);
  const isInstallment = navigation.getParam('installment', false);
  const months = [4, 6];
  const [selectedMonth, setSelectedMonth] = useState(4);

  const next = async () => {
    if (!loading) {
      setLoading(true);

      const checkPay = await Http.get(`${CONFIG.API_URL}Payment/get_state/${level}`, token);

      setLoading(false);
      if (checkPay.status === 'CONFIRMED') {
        navigation.navigate('Main', { forceRender: Math.random() });
      } else {
        alertMultiplatform({ title: 'Ошибка!', message: 'Вы не оплатили' });
      }
      setPay(false);
    }
  };

  useEffect(() => {
    (async () => {
      console.log(token);
      setLoading(true);
      const response = await getData('currentUser');
      console.log('responce', response);
      if (response) {
        setToken(response.token);
      }
      const price = await getPrice(response.token, level);
      setPrice(price);
      setLoading(false);
    })();
  }, []);

  const checkPromo = async (value) => {
    setLoading(true);
    const result = await Http.get(`${CONFIG.API_URL}Payment/setPromocode?promocode=${value}&level=${level}`, token);
    if (result.price >= 0) {
      setPrice(result.price);
      setLoading(false);
    }
  };

  const buy = async () => {
    if (!loading) {
      setLoading(true);
      let pay;
      if (isInstallment) {
        pay = await Http.get(`${CONFIG.API_URL}Payment/add_payment/${level}?installment=${isInstallment}&term=${selectedMonth}`, token);
      } else {
        pay = await Http.get(`${CONFIG.API_URL}Payment/add_payment/${level}`, token);
      }

      if (price === 0) {
        navigation.navigate('App');
      } else {
        setPay(true);
        setLoading(false);
        setUrl(pay.paymentURL);
      }
    }
  };

  const agree = () => {
    if (isSelected) {
      setColor('#8F8E8E');
    } else {
      setColor('black');
    }
    setSelected(!isSelected);
  };

  const openDocument = (URL) => {
    Linking.canOpenURL(URL)
      .then((supported) => {
        if (!supported) {
          console.log('Unsupported URL: ' + URL);
        } else {
          return Linking.openURL(URL);
        }
      })
      .catch((err) => console.error('An error occurred ', err));
  };
  const openFirstDoc = () => {
    const url = 'https://static.selfconstruct.ru/personal_data_policy.pdf';
    openDocument(url);
  };
  const openSecondDoc = () => {
    const url = 'https://static.selfconstruct.ru/offert_and_copyright.pdf';
    openDocument(url);
  };
  const openThirdDoc = () => {
    const url = 'https://static.selfconstruct.ru/user_agreement.pdf';
    openDocument(url);
  };

  if (isPay) {
    return (
      <View style={{ flex: 1, width: '100%', height: '100%' }}>
        {Platform.OS === 'web' ? (
          <iframe src={url} width="100%" height="100%"></iframe>
        ) : (
          <WebView source={{ uri: url }} style={{ marginTop: 20 }}></WebView>
        )}

        <View style={{ width: '100%', height: '10%', justifyContent: 'center' }}>
          <TouchableHighlight style={{ alignItems: 'center', justifyContent: 'center' }} onPress={next}>
            <Text style={{ fontSize: 19 }}>ГОТОВО</Text>
          </TouchableHighlight>
        </View>
      </View>
    );
  } else {
    return (
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : null} style={{ height: '100%', justifyContent: 'center' }}>
        <ScrollView style={styles.mainScroll}>
          {loading && (
            <View style={{ width: '100%', height: '100%' }}>
              <ActivityIndicator style={{ marginTop: 50, size: 'large', height: '100%' }} animating={loading} />
            </View>
          )}
          {!loading && (
            <View style={styles.body}>
              <TouchableOpacity style={styles.card}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 15,
                    color: '#616161',
                  }}
                >
                  Я приглашаю вас продолжить путешествие к своим целям с полным набором авторских инструментов
                </Text>

                <CheckBox
                  containerStyle={{
                    backgroundColor: THEME.MAIN_GREY,
                    borderWidth: 0,
                  }}
                  size={12}
                  checked={isSelected}
                  onPress={agree}
                  checkedIcon="circle"
                  uncheckedIcon="circle-o"
                  checkedColor={THEME.MAIN_DARK}
                  uncheckedColor={THEME.MAIN_DARK}
                  textStyle={{ fontSize: 12, color: '#616161' }}
                  title="Я подтверждаю свое согласие с условиями"
                />

                <View>
                  <TouchableOpacity onPress={openFirstDoc}>
                    <View>
                      <Text style={styles.textDoc}>Положение об обработке персональных данных </Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={openSecondDoc}>
                    <View>
                      <Text style={styles.textDoc}>Договор-оферта на оказание услуг</Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={openThirdDoc}>
                    <View>
                      <Text style={styles.textDoc}>Пользовательское соглашение </Text>
                    </View>
                  </TouchableOpacity>
                  <View style={styles.inputContainer}>
                    <Text style={styles.textPromoCode}>Промокод:</Text>
                    <TextInput style={styles.textInputPromoCode} value={promoCode} onChangeText={setPromoCode} />
                    <ResponsiveBtn
                      text="Применить промокод"
                      onPress={() => checkPromo(promoCode)}
                      disabled={loading}
                      style={{
                        fontSize: 14,
                        paddingVertical: 5,
                        borderRadius: 10,
                      }}
                    />
                  </View>
                  <Text style={styles.price}>Цена: {price} р </Text>
                  {isInstallment && (
                    <>
                      <Text style={styles.textPeriod}>Выберите период рассрочки:</Text>
                      <View style={styles.months}>
                        {months.map((m) => (
                          <Text
                            onPress={() => {
                              setSelectedMonth(m);
                            }}
                            key={m}
                            style={[styles.monthNum, selectedMonth === m ? styles.monthNumActive : null]}
                          >
                            {m}
                          </Text>
                        ))}
                      </View>
                    </>
                  )}
                </View>

                <View>
                  {!isInstallment ? (
                    <TouchableOpacity onPress={buy} disabled={!isSelected}>
                      <View style={styles.button}>
                        <Text
                          style={{
                            color: isColor,
                            fontSize: 19,
                            textAlign: 'center',
                          }}
                        >
                          КУПИТЬ
                        </Text>
                      </View>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity onPress={buy} disabled={!isSelected}>
                      <View>
                        <View style={styles.button}>
                          <Text
                            style={{
                              color: isColor,
                              fontSize: 19,
                              textAlign: 'center',
                            }}
                          >
                            ОФОРМИТЬ В РАССРОЧКУ
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  )}
                  <TouchableOpacity onPress={next} disabled={!isPay}>
                    <View style={styles.button}>
                      <Text
                        style={{
                          color: isPay ? 'black' : 'gray',
                          fontSize: 19,
                          textAlign: 'center',
                        }}
                      >
                        ДАЛЬШЕ
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
};

SubscriptionConfirmScreen.navigationOptions = {
  headerTitle: 'Соглашение',
};

const styles = {
  textPeriod: {
    color: '#000',
    marginBottom: 5,
    marginTop: 5,
  },
  months: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    columnGap: 10,
    marginTop: 10,
  },
  monthNum: {
    width: 27,
    height: 25,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#8F8E8E',
    borderRadius: 5,
    color: '#8F8E8E',
    fontSize: 19,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  monthNumActive: {
    borderColor: '#000',
    color: '#000',
  },
  price: {
    marginBottom: 8,
    marginTop: 5,
  },
  textPromoCode: {
    color: THEME.MAIN_DARK,
    marginBottom: 5,
    marginTop: 10,
  },
  textInputPromoCode: {
    borderRadius: 10,
    borderWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    borderColor: THEME.MAIN_DARK,
    fontSize: 14,
    marginBottom: 15,
    // alignItems: "left",
  },
  inputContainer: {
    flexDirection: 'column',
    textAlign: 'left',
    marginTop: 20,
    marginBottom: 10,
  },

  checkboxContainer: {
    width: 300,
    flexDirection: 'row',

    marginTop: 0,
    marginLeft: 80,
    marginBottom: 0,
  },
  textDoc: {
    textDecorationLine: 'underline',
    textDecorationColor: 'gray',
    fontSize: 12,
    marginTop: 10,
    textAlign: 'left',
    color: '#262626',
  },
  button: {
    marginBottom: -20,
    width: 270,
    paddingVertical: 15,
  },
  body: {
    marginTop: Platform.OS === 'ios' ? 20 : 50,
    paddingTop: 35,
    backgroundColor: THEME.MAIN_GREY,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    marginBottom: 15,
    maxWidth: 400,
    width: '85%',
    height: '100%', // 380
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  mainScroll: {
    width: '100%',
    height: '100%',
    backgroundColor: THEME.MAIN_GREY,
  },
};
