import React, { useContext } from 'react'
import { Formik } from 'formik'
import {
  View,
  StyleSheet,
  Alert,
  Keyboard,
  Image,
  ScrollView,
  KeyboardAvoidingView,
  Platform
} from 'react-native'
import { AppLoader } from '../../components/ui/AppLoader'
import { UserContext } from '../../context/user/userContext'
import bodyStyles from '../../styles/bodyStyles'
import { SCButton } from '../../components/ui/button/SCButton'
import { SCInput } from '../../components/ui/inputs/SCInput'
import alertMultiplatform from '../../utils/alertMultiplatform'

export const RecoveryScreen = ({ navigation }) => {
  const { recoverPassword, loading, error, clearError } = useContext(UserContext)

  const send = (values) => {
    if (values.email !== '') {
      recoverPassword(values, navigation)
    } else {
      alertMultiplatform({ title: 'Ошибка!', message: 'Заполните почту' })
    }
    Keyboard.dismiss()
  }

  if (loading) {
    return <AppLoader />
  }

  if (error) {
    if (Platform.OS === 'web') {
      alert(error)
      clearError()
    } else {
      Alert.alert('Ошибка!', error, [
        {
          text: 'Ок',
          onPress: () => {
            clearError()
          },
          style: 'cancel'
        }
      ])
    }
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 0}
    >
      <ScrollView style={bodyStyles.main}>
        <View style={styles.logoContainer}>
          <Image
            style={styles.logo}
            source={require('../../../assets/logo.png')}
          />
        </View>

        <View style={styles.body}>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={(values) => send(values)}
          >
            {({ handleChange, handleBlur, handleSubmit, values }) => (
              <View style={styles.form}>

                <SCInput
                  label="Введите Ваш e-mail"
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                  placeholder="Логин"
                  autoCorrect={false}
                  autoCapitalize="none"
                  style={styles.formMarginBottom}
                  value={values.email}
                />
                <View style={styles.actions}>
                  <SCButton
                    text="Получить письмо на почту"
                    onPress={handleSubmit}
                    disabled={loading}
                    style={styles.buttonMargin}
                  />
                </View>
              </View>
            )}
          </Formik>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
}

RecoveryScreen.navigationOptions = {
  headerTitle: 'Восстановление пароля'
}

const styles = StyleSheet.create({
  actions: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 140,
    width: '100%'
  },
  body: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 40
  },
  buttonMargin: {
    marginBottom: 41
  },
  form: {
    alignItems: 'center',
    width: '100%'
  },
  formMarginBottom: {
    marginBottom: 24
  },
  logo: {
    alignItems: 'center',
    height: 75,
    justifyContent: 'center',
    resizeMode: 'stretch',
    width: 320
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 96
  }
})
