import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import { UserContext } from '../../context/user/userContext'
import { AppLoader } from '../../components/ui/AppLoader'

export const FAQScreen = ({}) => {
  const { getFAQList, loading } = useContext(UserContext)

  const [faqList, setFaqList] = useState([])

  const loadFAQs = useCallback(async () => {
    const faqs = await getFAQList()
    console.log('FAQs: ', faqs)
    setFaqList(faqs)
  }, [getFAQList])

  useEffect(() => {
    loadFAQs()
  }, [])

  const generateKey = () => {
    let firstPart = (Math.random() * 46656) | 0
    let secondPart = (Math.random() * 46656) | 0
    firstPart = ('000' + firstPart.toString(36)).slice(-3)
    secondPart = ('000' + secondPart.toString(36)).slice(-3)
    return firstPart + secondPart
  }

  if (loading) {
    return <AppLoader />
  }

  return (
    <View style={styles.center}>
      {faqList.length === 0 && <Text>Данных нет</Text>}
      {faqList.length > 0 && (
        <ScrollView>
          {faqList.length !== 0 &&
            faqList.map((item) => <Text key={generateKey}>{item}</Text>)}
        </ScrollView>
      )}
    </View>
  )
}

FAQScreen.navigationOptions = {
  headerTitle: 'FAQ'
}

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  }
})
