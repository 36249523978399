import React, { useEffect, useState } from 'react'
import { Audio } from 'expo-av'
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'
import { Feather } from '@expo/vector-icons'
import { THEME } from '../../theme'
import { IosVideo } from './VideoModule/IosVideo'
import { AndroidVideo } from './VideoModule/AndroidVideo'
import Player from 'react-player'

export const TaskVideoCard = ({ task, isUnfocusedProp }) => {
  const [status, setStatus] = useState({})
  const video = React.useRef(null)
  const player = React.useRef(null)
  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    (async () => {
      await Audio.requestPermissionsAsync()
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        await Audio.setAudioModeAsync({
          staysActiveInBackground: true,
          interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
          shouldDuckAndroid: false,
          playThroughEarpieceAndroid: false,
          allowsRecordingIOS: false,
          interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
          playsInSilentModeIOS: true
        })
      } else {
        await Audio.setAudioModeAsync({
          staysActiveInBackground: true,
          shouldDuckAndroid: false,
          playThroughEarpieceAndroid: false,
          allowsRecordingIOS: false,
          playsInSilentModeIOS: true
        })
      }
    })()
  }, [])

  useEffect(() => {
    if (video.current && isUnfocusedProp === true && Platform.OS !== 'web') {
      video.current.pauseAsync()
    } else if (Platform.OS === 'web' && isUnfocusedProp === true) {
      setPlaying(false)
    }
  }, [isUnfocusedProp])

  return (
    <View style={styles.taskView}>
      <View style={styles.videoView}>
        {Platform.OS === 'android' && (
          <AndroidVideo
            task={task}
            setStatus={setStatus}
            video={video}
          ></AndroidVideo>
        )}
        {Platform.OS === 'ios' && (
          <IosVideo task={task} setStatus={setStatus} video={video}></IosVideo>
        )}
        {Platform.OS === 'web' && (
          <Player
            url={task.url}
            playing={playing}
            width='100%'
            height='100%'
            controls
            ref={player}
            onEnded={() => setPlaying(false)}
          />
        )}
      </View>
      <TouchableOpacity
        style={styles.videoPlay}
        onPress={() => {
          if (Platform.OS === 'web') {
            playing ? setPlaying(false) : setPlaying(true)
          } else {
            status.isPlaying
              ? video.current.pauseAsync()
              : video.current.playAsync()
          }
        }
        }
      >
        {((status.isPlaying && Platform.OS !== 'web') || (playing && Platform.OS === 'web')) && (
          <Feather
            style={styles.videoIcon}
            name="pause-circle"
            size={31}
            color="black"
          />
        )}
        {((!status.isPlaying && Platform.OS !== 'web') || (!playing && Platform.OS === 'web')) && (
          <Feather
            style={styles.videoIcon}
            name="play-circle"
            size={31}
            color="black"
          />
        )}

        <Text style={styles.videoName} numberOfLines={1}>
          {task.name}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  taskView: {
    backgroundColor: THEME.MAIN_GREY,
    borderRadius: 20,
    color: THEME.MAIN_DARK,
    flexDirection: 'column',
    marginBottom: 36
  },
  videoIcon: {
    marginRight: 10
  },
  videoName: {
    maxWidth: 200
  },
  videoPlay: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: 20
  },
  videoView: {
    backgroundColor: '#202020',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    width: '100%'
  }
})
