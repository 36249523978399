import { Formik } from 'formik'
import React, { useContext } from 'react'
import {
  View,
  StyleSheet,
  Alert,
  Keyboard,
  ScrollView,
  KeyboardAvoidingView,
  Platform
} from 'react-native'
import { AppLoader } from '../../components/ui/AppLoader'
import { UserContext } from '../../context/user/userContext'
import bodyStyles from '../../styles/bodyStyles'
import { SCButton } from '../../components/ui/button/SCButton'
import { SCInput } from '../../components/ui/inputs/SCInput'
import alertMultiplatform from '../../utils/alertMultiplatform'

export const ChangePasswordScreen = ({ navigation }) => {
  const { userChangePassword, loading, error, clearError } = useContext(UserContext)

  const changePassword = (values) => {
    if (values.password === '' || values.repeatPassword === '') {
      alertMultiplatform({ title: 'Ошибка!', message: 'Заполните поля ввода' })
    } else if (values.password !== values.repeatPassword) {
      alertMultiplatform({ title: 'Ошибка!', message: 'Пароли должны совпадать' })
    } else if ((values.password !== '' && values.repeatPassword !== '') && (values.password === values.repeatPassword)) {
      if (values.password.length < 8) {
        alertMultiplatform({ title: 'Ошибка!', message: 'Минимум 8 символов' })
      } else {
        userChangePassword(values, navigation)
      }
    } else {
      alertMultiplatform({ title: 'Ошибка!', message: 'Что-то пошло не так...' })
    }
    Keyboard.dismiss()
  }

  if (loading) {
    return <AppLoader />
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 0}
    >
      <ScrollView style={bodyStyles.main}>
        <View style={styles.body}>
          <Formik
            initialValues={{ password: '', repeatPassword: '' }}
            onSubmit={(values) => changePassword(values)}
          >
            {({ handleChange, handleBlur, handleSubmit, values }) => (
              <View style={styles.form}>
                <SCInput
                  label="Введите новый пароль"
                  onChangeText={handleChange('password')}
                  onBlur={handleBlur('password')}
                  secureTextEntry={true}
                  placeholder="Новый пароль"
                  autoCorrect={false}
                  autoCapitalize="none"
                  style={styles.formMarginBottom}
                  value={values.password}
                />
                <SCInput
                  label="Повторите пароль"
                  onChangeText={handleChange('repeatPassword')}
                  onBlur={handleBlur('repeatPassword')}
                  secureTextEntry={true}
                  autoCorrect={false}
                  autoCapitalize="none"
                  value={values.repeatPassword}
                />
                <View style={styles.actions}>
                  <SCButton
                    text="Изменить пароль"
                    onPress={handleSubmit}
                    disabled={loading}
                    style={styles.buttonMargin}
                  />
                </View>
              </View>
            )}
          </Formik>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
}

ChangePasswordScreen.navigationOptions = {
  headerTitle: 'Смена пароля'
}

const styles = StyleSheet.create({
  actions: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 140,
    width: '100%'
  },
  body: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 40
  },
  buttonMargin: {
    marginBottom: 41
  },
  form: {
    alignItems: 'center',
    width: '100%'
  },
  formMarginBottom: {
    marginBottom: 24
  },
  logo: {
    alignItems: 'center',
    height: 75,
    justifyContent: 'center',
    resizeMode: 'stretch',
    width: 320
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 96
  }
})
