import {
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  HIDE_LOADER,
  SHOW_ERROR,
  SHOW_LOADER,
  SHOW_SUCCESS,
  WHEEL_GET_FROM_API,
  WHEEL_UPDATE
} from '../types'

const handlers = {
  [WHEEL_GET_FROM_API]: (state, action) => ({
    ...state,
    wheel: action.wheel
  }),
  [WHEEL_UPDATE]: (state, action) => ({
    ...state,
    wheel: action.updatedWheel
  }),
  [SHOW_LOADER]: (state) => ({ ...state, loading: true }),
  [HIDE_LOADER]: (state) => ({ ...state, loading: false }),
  [SHOW_SUCCESS]: (state) => ({ ...state, success: true }),
  [CLEAR_SUCCESS]: (state) => ({ ...state, success: false }),
  [CLEAR_ERROR]: (state) => ({ ...state, error: null }),
  [SHOW_ERROR]: (state, { error }) => ({ ...state, error }),
  DEFAULT: (state) => state
}

export const wheelReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT
  return handler(state, action)
}
