import {
  ActivityIndicator,
  Alert,
  Button,
  FlatList,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native'
import React, { useContext, useEffect, useState } from 'react'
import { THEME } from '../../../theme'
import { AdminContext } from '../../../context/admin/adminContext'
import { Http } from '../../../services/http'
import { CONFIG } from '../../../config'
import { getData } from '../../../services/storage.service'
import Icon from 'react-native-vector-icons/FontAwesome'
import PrimaryBtn from '../../../components/ui/button/PrimaryBtn'
import { SCButton } from '../../../components/ui/button/SCButton'
import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'

const PromoInputs = ({ level, name, percent, onUpdate, addPromoCode }) => {
  return (
    <View style={styles.body}>
      <View style={styles.info}>
        <Text style={styles.labelName}>Название промокода</Text>
        <TextInput
          style={styles.textInputName}
          value={name}
          onChangeText={(value) => onUpdate(level, 'name', value)}
        />
      </View>

      <View style={styles.info}>
        <Text style={styles.labelPercent}>Процент</Text>
        <TextInput
          style={styles.textInputPercent}
          value={percent}
          onChangeText={(value) =>
            onUpdate(level, 'percent', value)
          }
        />
      </View>

      <View style={styles.info}>
        <Text style={styles.labelPercent}>Уровень</Text>
        <TextInput
          editable={false}
          style={styles.textInputPercent}
          value={ level === 0 ? 'Все' : level}
        />
      </View>

      <View style={styles.info}>
        <TouchableOpacity onPress={() => addPromoCode(name, percent, level)} style={styles.button}>
          <Icon name="plus" size={10} color="#fff" />
        </TouchableOpacity>
      </View>

    </View>
  )
}

const PriceInputs = ({ level, price, onUpdate, priceUpdate }) => {
  return (
    <View style={[styles.body, { marginTop: 10 }]}>
      <View style={styles.info}>
        <Text style={styles.labelPercent}>Цена за подписку:</Text>
        <TextInput
          style={styles.textInputPrice}
          value={price}
          onChangeText={(value) => onUpdate(level, 'price', value)}
        />
      </View>

      <View style={styles.info}>
        <Text style={styles.labelPercent}>Уровень</Text>
        <TextInput
          editable={false}
          style={styles.textInputPercent}
          value={level === 0 ? 'Все' : level}
        />
      </View>

      <View style={{ ...styles.info, marginLeft: 45, marginTop: 20 }}>
        <PrimaryBtn title='Обновить' onPress={() => {
          priceUpdate(price, level)
        }}/>
      </View>
    </View>
  )
}

export const PromoCodsScreen = ({ navigation }) => {
  const { updatePrice, getPromoCods, addPromoCod, removePromoCod, promoCodsList, loading, error, clearError } =
        useContext(AdminContext)

  const [price, setPrice] = useState('0')
  const [token, setToken] = useState()
  const [level, setLevel] = useState(0)
  const [levels, setLevels] = useState([1, 2, 3, 4, 0])
  const [promoInputs, setPromoInputs] = useState([])
  const [priceInputs, setPriceInputs] = useState([])
  const [tempPriceInputs, setTempPriceInputs] = useState([])
  const [load, setLoad] = useState(false)

  useEffect(() => {
    (async () => {
      viewPromoCods()
      const response = await getData('currentUser')
      if (response) {
        setToken(token)
        const res = await Http.get(
          `${CONFIG.API_URL}UserAdministration/get_price/${level}`, response.token
        )

        if (res.price) {
          setPrice(`${res.price}`)
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (loading) {
      if (!load) {
        setLoad(true)
      }
    } else {
      if (load) {
        setLoad(false)
      }
    }
  }, [loading, load])

  useEffect(() => {
    if (error) {
      if (Platform.OS === 'web') {
        alert(error)
        clearError()
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  const getPrice = async () => {
    await getData('currentUser')
    .then(async (response) => {
      if (response) {
        setToken(token);

        for (let i = 0; i < levels.length; i++) {
          await Http.get(`${CONFIG.API_URL}UserAdministration/get_price/${levels[i]}`, response.token)
          .then((resp) => {
            tempPriceInputs.push({level: levels[i], price: resp.price});
          })
        }
        
      }
      
    })
    .then(() => {
      setPriceInputs(tempPriceInputs);
    })

  }

  useEffect(() => {
    (async () => {
      getPrice();
    })()
  }, [])

  useEffect(() => {
    setPromoInputs(levels.map(level => ({
      level,
      name: '',
      percent: 0
    })))
    /*
    setPriceInputs(levels.map(level => ({
      level,
      price: 0
    })))
    */
  }, [levels])

  const priceUpdate = async (price, level) => {
    if (price > 0) {
      updatePrice(Number(price), level)
    }
  }

  const viewPromoCods = () => {
    getPromoCods()
    //console.log(promoCodsList)
  }

  const deletePromo = (id) => {
    removePromoCod(id)
  }

  const addPromoCode = (name, percent, level) => {
    if (name !== '' && percent > 0 && percent <= 100) {
      addPromoCod({ promocodeName: name, percent: Number(percent), level })
    }
  }

  const handleUpdatePromo = (level, key, value) => {
    setPromoInputs(inputs => inputs.map((input) =>
      input.level === level ? { ...input, [key]: value } : input
    ))
  }

  const handleUpdatePrice = (level, key, value) => {
    setPriceInputs(inputs => inputs.map((input) =>
      input.level === level ? { ...input, [key]: value } : input
    ))
  }

  return (
    <View>
      {load && <View style={{ marginTop: 20, marginLeft: 20 }}>
        <ActivityIndicator style={{ color: THEME.MAIN_COLOR, size: 'large' }} animating={load}/>
      </View>}
      {!load &&
      <View style={{ alignItems: 'center' }}>
        {promoInputs.map((promoInput) => (
          <PromoInputs key={promoInput.level} level={promoInput.level} name={promoInput.name} percent={promoInput.percent}
            onUpdate={handleUpdatePromo} addPromoCode={addPromoCode}/>
        ))}

        {priceInputs.map((input) => (
          <PriceInputs key={input.level} level={input.level} price={input.price} onUpdate={handleUpdatePrice} priceUpdate={priceUpdate}/>
        ))}

        <View style={{
          height: 1,
          width: '100%',
          backgroundColor: '#000',
          marginTop: 10,
          marginBottom: 10
        }}></View>

        <View style={styles.containerList}>
          <SCButton text={'Показать список промокодов'} onPress={viewPromoCods}/>
        </View>

        <View style={styles.list}>
          <FlatList data={promoCodsList} renderItem={({ item }) =>
            <View style={styles.body}>
              <Text style={styles.item}>{`Уровень: ${item.level === 0 ? 'все' : item.level}`}</Text>
              <Text style={styles.item}>{item.promocodeName + ': ' + item.percent + '%'}</Text>
              <Button title={'Удалить'} onPress={ () => deletePromo(item.id)}/>
            </View>
          }/>
        </View>
      </View>}

    </View>

  )
}

PromoCodsScreen.navigationOptions = {
  headerTitle: 'Промокоды'
}

const styles = StyleSheet.create({
  body: {
    // flex: 1,
    // padding: 40,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 10,
    // justifyContent: "center",
    alignItems: 'center',
    color: 'gray'
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#2196f3',
    borderColor: 'none',
    borderRadius: 20,
    height: 40,
    justifyContent: 'center',
    marginLeft: 10,
    marginTop: 20,
    paddingVertical: 10,
    width: 40
  },
  buttonDelete: {
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    marginLeft: 10,
    marginTop: 20,
    paddingVertical: 10,
    width: 40
  },

  container: {
    alignItems: 'center',
    color: 'gray',
    flex: 1,
    justifyContent: 'center',
    paddingTop: 22
  },
  containerList: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30
  },
  info: {
    alignItems: 'center',
    color: 'gray',
    justifyContent: 'center'
  },
  item: {
    fontSize: 18,
    height: 44,
    padding: 10
  },

  labelName: {
    color: THEME.MAIN_DARK,
    marginBottom: 5,
    marginTop: 10
    // paddingLeft: 18,
    // marginLeft: 10,
  },

  labelPercent: {
    color: THEME.MAIN_DARK,
    marginBottom: 5,
    marginTop: 10,
    // paddingLeft: 18,
    marginLeft: 20
  },
  list: {
    alignItems: 'center',
    marginTop: 10
  },
  text: {

    color: THEME.MAIN_DARK,
    textAlign: 'center'
  },
  textInputName: {

    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    fontSize: 14,
    marginBottom: 10,
    paddingLeft: 18,
    paddingVertical: 10,
    width: 150
  },
  textInputPercent: {
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    fontSize: 14,
    marginBottom: 10,
    marginLeft: 20,
    paddingLeft: 10,
    paddingVertical: 10,
    width: 50
  },
  textInputPrice: {
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    fontSize: 14,
    height: 40,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 18,
    paddingVertical: 8,
    width: 70
  }
})
