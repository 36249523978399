import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View, Text, StyleSheet, ScrollView, Platform } from 'react-native'
import { THEME } from '../../../theme'
import { AntDesign } from '@expo/vector-icons'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { DiaryContext } from '../../../context/diary/diaryContext'
import moment from 'moment'
import { AppLoader } from '../../../components/ui/AppLoader'

export const StepsScreen = ({ navigation }) => {
  const {
    diaryActual,
    diaryFinished,
    getDiaryFinishedList,
    getDiaryActualList,
    loading
  } = useContext(DiaryContext)

  const loadActualDiary = useCallback(
    async () => await getDiaryActualList(),
    [getDiaryActualList]
  )
  const loadFinishedDiary = useCallback(
    async () => await getDiaryFinishedList(),
    [getDiaryFinishedList]
  )

  useEffect(() => {
    [loadActualDiary(), loadFinishedDiary()]
  }, [])

  //* Если выбран таб "Завершенные шаги" - показывать список завершенных шагов
  //* Если выбран таб "Активные шаги" - показывать список активных шагов
  const [activeTab, setActiveTab] = useState('activeSteps')

  const onEdit = (item) => {
    navigation.navigate('StepView', {
      currentItem: item,
      viewType: 'inprogress'
    })
  }

  const onView = (item) => {
    navigation.navigate('StepView', {
      currentItem: item,
      viewType: 'completed'
    })
  }

  const onSetActiveTab = (tabId) => {
    setActiveTab(tabId)
  }

  const activeSteps = (
    <ScrollView style={styles.cardsList}>
      {diaryActual.length !== 0 &&
        diaryActual.map((item) => (
          <TouchableOpacity
            key={item.id}
            onPress={() => onEdit(item)}
            style={styles.card}
          >
            <View style={styles.rowInfo}>
              <View style={styles.cardDate}>
                <Text>{moment(item.deadlineDate).format('DD.MM.YYYY')}</Text>
              </View>
            </View>
            <View style={styles.rowActions}>
              <View style={styles.cardTarget}>
                <Text style={styles.description}>{item.description}</Text>
              </View>
              <View style={styles.action}>
                <Text>Посмотреть</Text>
              </View>
            </View>
          </TouchableOpacity>
        ))}
    </ScrollView>
  )

  const completedSteps = (
    <ScrollView style={styles.cardsList}>
      {diaryFinished.length !== 0 &&
        diaryFinished.map((item) => (
          <TouchableOpacity
            key={item.id}
            onPress={() => onView(item)}
            style={styles.card}
          >
            <View style={styles.rowInfo}>
              <View style={styles.cardDate}>
                <Text>{moment(item.deadlineDate).format('DD.MM.YYYY')}</Text>
              </View>
            </View>
            <View style={styles.rowActions}>
              <View style={styles.cardTarget}>
                <Text style={styles.description}>{item.description}</Text>
              </View>
              <View style={styles.action}>
                <Text>Посмотреть</Text>
              </View>
            </View>
          </TouchableOpacity>
        ))}
    </ScrollView>
  )

  if (loading) {
    return <AppLoader />
  }

  return (
    <View style={styles.center}>
      <View style={styles.tabs}>
        <TouchableOpacity onPress={() => onSetActiveTab('activeSteps')}>
          <Text
            style={[
              styles.tabsElement,
              activeTab === 'activeSteps' ? styles.active : styles.default
            ]}
          >
            Активные Шаги
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => onSetActiveTab('completedSteps')}>
          <Text
            style={[
              styles.tabsElement,
              styles.tabsElementLast,
              activeTab === 'completedSteps' ? styles.active : styles.default
            ]}
          >
            Завершенные Шаги
          </Text>
        </TouchableOpacity>
      </View>
      {activeTab === 'activeSteps' && activeSteps}
      {activeTab === 'completedSteps' && completedSteps}
    </View>
  )
}

StepsScreen.navigationOptions = ({ navigation }) => {
  const onAdd = () => {
    navigation.navigate('StepAdd', {
      isEdit: false
    })
  }

  return {
    headerTitle: 'Шаги',
    headerRight: () => (
      <TouchableOpacity style={styles.headerRight} onPress={onAdd}>
        <AntDesign
          name="plus"
          color={Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR}
          size={24}
        />
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  active: {
    color: '#3941F8'
  },
  card: {
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 20,
    padding: 16,
    width: '100%'
  },
  cardDate: {
    marginBottom: 10
  },
  cardsList: {
    height: '84%',
    padding: 20,
    paddingBottom: 140,
    width: '100%'
  },
  center: {
    display: 'flex',
    width: '100%'
  },
  default: {
    color: '#000000'
  },
  description: {
    maxWidth: 200
  },
  headerRight: {
    marginRight: 26
  },
  rowActions: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rowInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 40,
    width: '100%'
  },
  tabsElement: {
    padding: 20
    //   backgroundColor: 'black'
  },
  tabsElementLast: {
    marginLeft: 20
  }
})
