import React, { useContext } from 'react'
import { View, Text, StyleSheet, Button, ScrollView } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { FontAwesome } from '@expo/vector-icons'
import { UserContext } from '../../context/user/userContext'
import { THEME } from '../../theme'
import { SCButton } from '../../components/ui/button/SCButton'
import { removeData } from '../../services/storage.service'

export const CommonScreen = ({ navigation }) => {
  const { user, userLogout } = useContext(UserContext)
  console.log('USER IS', user)
  const onProfile = () => {
    navigation.navigate('Profile')
  }
  const toPasswordChange = () => {
    navigation.navigate('ChangePassword')
  }
  const onError = () => {
    navigation.navigate('ErrorReport')
  }
  const onFAQ = () => {
    navigation.navigate('FAQ')
  }

  const logout = () => {
    userLogout(navigation)
  }

  const clearGreetings = () => {
    removeData('isUserExist')
  }

  return (
    <View style={styles.body}>
      <View style={styles.header}>
        <View>
          <Text style={styles.title}>
            {user.firstName} {user.lastName}
          </Text>
        </View>
        <View>
          <TouchableOpacity style={styles.avatarButton} onPress={onProfile}>
            <FontAwesome name="user" size={24} color="black" />
          </TouchableOpacity>
        </View>
      </View>
      <ScrollView style={styles.contentBody}></ScrollView>
      <View style={styles.actionButtons}>
        <SCButton
          text="Сменить пароль"
          onPress={toPasswordChange}
          style={styles.buttonMargin} />
        <SCButton
          text="Сообщить об ошибке"
          onPress={onError}
          style={styles.buttonMargin}
        />
        {/* <SCButton text="Включить приветствие" style={styles.buttonMargin}  onPress={clearGreetings} /> */}
        <SCButton text="Выйти" onPress={logout} />
      </View>
    </View>
  )
}

CommonScreen.navigationOptions = {
  headerTitle: 'Еще',
  headerShown: false
}

const styles = StyleSheet.create({
  actionButton: {
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    color: 'black',
    fontSize: 10,
    height: 36,
    paddingLeft: 10,
    paddingVertical: 10,
    width: 200
  },
  actionButtonText: {
    textAlign: 'center'
  },
  actionButtons: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  avatarButton: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderColor: 'rgba(0,0,0,0.2)',
    borderRadius: 50,
    borderWidth: 1,
    height: 50,
    justifyContent: 'center',
    width: 50
  },
  body: {
    flex: 1,
    padding: 40
  },
  buttonMargin: {
    marginBottom: 14
  },
  contentBody: {
    flex: 1,
    marginTop: 120
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 40
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold'
  }
})
