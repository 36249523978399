import React from 'react'
import Svg, { Defs, Path } from 'react-native-svg'

export const CircleLifeBalance = ({ ...props }) => {
  const fills = []

  // check is new | if 8 sectors = 1 score === new
  props.sectors.forEach((sector) => {
    for (let i = 0; i < sector.score; i++) {
      fills['prefix__sector' + sector.order + i] = sector.color
    }
  })

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={371} height={375}>
      <Defs></Defs>
      <Path
        id="prefix__sector810"
        className="prefix__cls-1"
        fill={fills.prefix__sector89 ?? 'transparent'}
        d="M183.7 40.33c-40.4 0-76.029 16.4-102.386 42.942-4.306-4.305-9.693-10.072-11.144-11.521C99.414 42.3 139.06 24.1 183.884 24.1c0 1.989-.184 12.277-.184 16.23z"
      />
      <Path
        id="prefix__sector89"
        className="prefix__cls-1"
        fill={fills.prefix__sector88 ?? 'transparent'}
        d="M92.079 93.929c23.659-23.632 55.543-38.236 91.8-38.236 0-2.1-.185-13.164-.185-15.363-40.4 0-76.029 16.4-102.386 42.942 2.467 2.462 9.001 8.887 10.771 10.657z"
      />
      <Path
        id="prefix__sector88"
        className="prefix__cls-1"
        fill={fills.prefix__sector88 ?? 'transparent'}
        d="M92.079 93.929c23.659-23.632 55.543-38.236 91.8-38.236v15.795c-31.633 0-59.321 12.807-79.959 33.531-2.627-2.633-9.42-8.67-11.841-11.09z"
      />
      <Path
        id="prefix__sector87"
        className="prefix__cls-1"
        fill={fills.prefix__sector86 ?? 'transparent'}
        d="M183.884 87.282a96.9 96.9 0 00-69.1 28.825c-1.515-1.515-9.643-9.873-10.859-11.089 20.638-20.723 48.326-33.53 79.959-33.53v15.794z"
      />
      <Path
        id="prefix__sector86"
        className="prefix__cls-1"
        fill={fills.prefix__sector85 ?? 'transparent'}
        d="M125.642 127.2a81.977 81.977 0 0158.242-24.118v-15.8a96.9 96.9 0 00-69.1 28.825c1.459 1.459 9.053 9.283 10.858 11.093z"
      />
      <Path
        id="prefix__sector85"
        className="prefix__cls-1"
        fill={fills.prefix__sector84 ?? 'transparent'}
        d="M183.884 118.872a64.969 64.969 0 00-46.4 19.412c-1.4-1.4-7.224-6.481-11.846-11.089a81.977 81.977 0 0158.242-24.118v15.8z"
      />
      <Path
        id="prefix__sector84"
        className="prefix__cls-1"
        fill={fills.prefix__sector83 ?? 'transparent'}
        d="M148.347 149.373a50.036 50.036 0 0135.537-14.706v-15.8a64.969 64.969 0 00-46.4 19.412c3.686 3.713 9.716 9.95 10.863 11.094z"
      />
      <Path
        id="prefix__sector83"
        className="prefix__cls-1"
        fill={fills.prefix__sector82 ?? 'transparent'}
        d="M148.347 149.373a50.036 50.036 0 0135.537-14.706v15.795c-9.258 0-18.638 3.819-24.679 10-.972-.973-9.07-9.301-10.858-11.089z"
      />
      <Path
        id="prefix__sector82"
        className="prefix__cls-1"
        fill={fills.prefix__sector81 ?? 'transparent'}
        d="M159.205 160.462c6.041-6.181 15.421-10 24.679-10v15.794c-4.629 0-9.813 2.651-12.833 5.924-2.051-2.052-9.966-9.808-11.846-11.718z"
      />
      <Path
        id="prefix__sector81"
        className="prefix__cls-1"
        fill={fills.prefix__sector80 ?? 'transparent'}
        d="M183.884 166.256c-4.629 0-9.813 2.651-12.833 5.924.94 1.011 12.833 12.833 12.833 12.833v-18.757z"
      />
      <Path
        id="prefix__sector710"
        className="prefix__cls-1"
        fill={fills.prefix__sector79 ?? 'transparent'}
        d="M38.214 185.815c0-40.4 16.4-76.03 42.942-102.386-4.305-4.306-10.07-9.693-11.521-11.143C40.187 101.53 21.987 141.176 21.987 186c1.987 0 12.275-.185 16.227-.185z"
      />
      <Path
        id="prefix__sector79"
        className="prefix__cls-1"
        fill={fills.prefix__sector78 ?? 'transparent'}
        d="M91.813 94.2c-23.631 23.654-38.236 55.538-38.236 91.8-2.1 0-13.164-.185-15.363-.185 0-40.4 16.4-76.03 42.942-102.386 2.462 2.462 8.887 8.996 10.657 10.771z"
      />
      <Path
        id="prefix__sector78"
        className="prefix__cls-1"
        fill={fills.prefix__sector77 ?? 'transparent'}
        d="M91.813 94.2c-23.631 23.654-38.236 55.538-38.236 91.8h15.795c0-31.633 12.807-59.321 33.53-79.959-2.632-2.632-8.669-9.426-11.089-11.841z"
      />
      <Path
        id="prefix__sector77"
        className="prefix__cls-1"
        fill={fills.prefix__sector76 ?? 'transparent'}
        d="M85.167 186a96.9 96.9 0 0128.825-69.1c-1.515-1.515-9.873-9.643-11.089-10.859C82.179 126.679 69.372 154.367 69.372 186h15.795z"
      />
      <Path
        id="prefix__sector76"
        className="prefix__cls-1"
        fill={fills.prefix__sector75 ?? 'transparent'}
        d="M125.08 127.758A81.978 81.978 0 00100.961 186H85.167a96.9 96.9 0 0128.825-69.1c1.458 1.459 9.283 9.053 11.088 10.858z"
      />
      <Path
        id="prefix__sector75"
        className="prefix__cls-1"
        fill={fills.prefix__sector74 ?? 'transparent'}
        d="M116.756 186a64.966 64.966 0 0119.413-46.4c-1.4-1.4-6.482-7.224-11.089-11.846A81.978 81.978 0 00100.961 186h15.8z"
      />
      <Path
        id="prefix__sector74"
        className="prefix__cls-1"
        fill={fills.prefix__sector73 ?? 'transparent'}
        d="M147.257 150.463A50.033 50.033 0 00132.551 186h-15.795a64.966 64.966 0 0119.413-46.4c3.708 3.686 9.944 9.719 11.088 10.863z"
      />
      <Path
        id="prefix__sector73"
        className="prefix__cls-1"
        fill={fills.prefix__sector72 ?? 'transparent'}
        d="M147.257 150.463A50.033 50.033 0 00132.551 186h15.8c0-9.258 3.82-18.638 10-24.679-.978-.972-9.306-9.07-11.094-10.858z"
      />
      <Path
        id="prefix__sector72"
        className="prefix__cls-1"
        fill={fills.prefix__sector71 ?? 'transparent'}
        d="M158.346 161.321c-6.18 6.041-10 15.421-10 24.679h15.795c0-4.629 2.651-9.813 5.923-12.833-2.052-2.052-9.808-9.967-11.718-11.846z"
      />
      <Path
        id="prefix__sector71"
        className="prefix__cls-1"
        fill={fills.prefix__sector70 ?? 'transparent'}
        d="M164.141 186c0-4.629 2.651-9.813 5.923-12.833C171.075 174.107 182.9 186 182.9 186h-18.759z"
      />

      {/* LINE */}
      <Path
        id="prefix__sector7"
        className="prefix__cls-1"
        fill="black"
        d="M186.327 186.806l-3.616-3.626L69.884 70.026l-1.416 1.412L181.5 184.8l-160.292-.231v2l159.585.23z"
      />

      <Path
        id="prefix__sector610"
        className="prefix__cls-1"
        fill={fills.prefix__sector69 ?? 'transparent'}
        d="M38.214 187.185c0 40.4 16.4 76.03 42.942 102.386-4.305 4.3-10.07 9.692-11.521 11.143C40.187 271.47 21.987 231.824 21.987 187c1.987 0 12.275.185 16.227.185z"
      />
      <Path
        id="prefix__sector69"
        className="prefix__cls-1"
        fill={fills.prefix__sector68 ?? 'transparent'}
        d="M91.813 278.805C68.182 255.146 53.577 223.262 53.577 187c-2.1 0-13.164.185-15.363.185 0 40.4 16.4 76.03 42.942 102.386 2.462-2.462 8.887-8.996 10.657-10.766z"
      />
      <Path
        id="prefix__sector68"
        className="prefix__cls-1"
        fill={fills.prefix__sector67 ?? 'transparent'}
        d="M91.813 278.805C68.182 255.146 53.577 223.262 53.577 187h15.795c0 31.633 12.807 59.321 33.53 79.959-2.632 2.632-8.669 9.426-11.089 11.846z"
      />
      <Path
        id="prefix__sector67"
        className="prefix__cls-1"
        fill={fills.prefix__sector66 ?? 'transparent'}
        d="M85.167 187a96.9 96.9 0 0028.825 69.1c-1.515 1.515-9.873 9.643-11.089 10.859C82.179 246.321 69.372 218.633 69.372 187h15.795z"
      />
      <Path
        id="prefix__sector66"
        className="prefix__cls-1"
        fill={fills.prefix__sector65 ?? 'transparent'}
        d="M125.08 245.242A81.978 81.978 0 01100.961 187H85.167a96.9 96.9 0 0028.825 69.1c1.458-1.459 9.283-9.053 11.088-10.858z"
      />
      <Path
        id="prefix__sector65"
        className="prefix__cls-1"
        fill={fills.prefix__sector64 ?? 'transparent'}
        d="M116.756 187a64.966 64.966 0 0019.413 46.4c-1.4 1.4-6.482 7.224-11.089 11.846A81.978 81.978 0 01100.961 187h15.8z"
      />
      <Path
        id="prefix__sector64"
        className="prefix__cls-1"
        fill={fills.prefix__sector63 ?? 'transparent'}
        d="M147.257 222.537A50.033 50.033 0 01132.551 187h-15.795a64.966 64.966 0 0019.413 46.4c3.708-3.686 9.944-9.719 11.088-10.863z"
      />
      <Path
        id="prefix__sector63"
        className="prefix__cls-1"
        fill={fills.prefix__sector62 ?? 'transparent'}
        d="M147.257 222.537A50.033 50.033 0 01132.551 187h15.8c0 9.258 3.82 18.638 10 24.679-.978.972-9.306 9.07-11.094 10.858z"
      />
      <Path
        id="prefix__sector62"
        className="prefix__cls-1"
        fill={fills.prefix__sector61 ?? 'transparent'}
        d="M158.346 211.679c-6.18-6.041-10-15.421-10-24.679h15.795c0 4.629 2.651 9.813 5.923 12.833-2.052 2.052-9.808 9.967-11.718 11.846z"
      />
      <Path
        id="prefix__sector61"
        className="prefix__cls-1"
        fill={fills.prefix__sector60 ?? 'transparent'}
        d="M164.141 187c0 4.629 2.651 9.813 5.923 12.833C171.075 198.893 182.9 187 182.9 187h-18.759z"
      />

      {/* LINE */}
      <Path
        id="prefix__sector6"
        className="prefix__cls-2"
        fill="black"
        d="M186.006 185.25l-5.125-.007L21 185.011v2l160.176.232L67.6 300.577 69.012 302l113.071-112.836z"
      />

      <Path
        id="prefix__sector510"
        className="prefix__cls-1"
        fill={fills.prefix__sector59 ?? 'transparent'}
        d="M183.7 332.67c-40.4 0-76.029-16.4-102.386-42.942-4.305 4.305-9.692 10.07-11.143 11.521C99.414 330.7 139.06 348.9 183.884 348.9c0-1.989-.184-12.277-.184-16.23z"
      />
      <Path
        id="prefix__sector59"
        className="prefix__cls-1"
        fill={fills.prefix__sector58 ?? 'transparent'}
        d="M92.079 279.071c23.659 23.632 55.543 38.236 91.8 38.236 0 2.1-.185 13.164-.185 15.363-40.4 0-76.029-16.4-102.386-42.942 2.467-2.462 9.001-8.887 10.771-10.657z"
      />
      <Path
        id="prefix__sector58"
        className="prefix__cls-1"
        fill={fills.prefix__sector57 ?? 'transparent'}
        d="M92.079 279.071c23.659 23.632 55.543 38.236 91.8 38.236v-15.794c-31.633 0-59.321-12.808-79.959-33.531-2.627 2.632-9.42 8.669-11.841 11.089z"
      />
      <Path
        id="prefix__sector57"
        className="prefix__cls-1"
        fill={fills.prefix__sector56 ?? 'transparent'}
        d="M183.884 285.718a96.9 96.9 0 01-69.1-28.825c-1.515 1.515-9.643 9.873-10.859 11.089 20.638 20.723 48.326 33.531 79.959 33.531v-15.795z"
      />
      <Path
        id="prefix__sector56"
        className="prefix__cls-1"
        fill={fills.prefix__sector55 ?? 'transparent'}
        d="M125.642 245.805a81.977 81.977 0 0058.242 24.118v15.8a96.9 96.9 0 01-69.1-28.825c1.459-1.464 9.053-9.288 10.858-11.093z"
      />
      <Path
        id="prefix__sector55"
        className="prefix__cls-1"
        fill={fills.prefix__sector54 ?? 'transparent'}
        d="M183.884 254.128a64.969 64.969 0 01-46.4-19.412c-1.4 1.4-7.224 6.481-11.846 11.089a81.977 81.977 0 0058.242 24.118v-15.8z"
      />
      <Path
        id="prefix__sector54"
        className="prefix__cls-1"
        fill={fills.prefix__sector53 ?? 'transparent'}
        d="M148.347 223.627a50.036 50.036 0 0035.537 14.706v15.795a64.969 64.969 0 01-46.4-19.412c3.686-3.708 9.716-9.945 10.863-11.089z"
      />
      <Path
        id="prefix__sector53"
        className="prefix__cls-1"
        fill={fills.prefix__sector52 ?? 'transparent'}
        d="M148.347 223.627a50.036 50.036 0 0035.537 14.706v-15.795c-9.258 0-18.638-3.819-24.679-10-.972.973-9.07 9.301-10.858 11.089z"
      />
      <Path
        id="prefix__sector52"
        className="prefix__cls-1"
        fill={fills.prefix__sector51 ?? 'transparent'}
        d="M159.205 212.538c6.041 6.181 15.421 10 24.679 10v-15.794c-4.629 0-9.813-2.651-12.833-5.924-2.051 2.052-9.966 9.808-11.846 11.718z"
      />
      <Path
        id="prefix__sector51"
        className="prefix__cls-1"
        fill={fills.prefix__sector50 ?? 'transparent'}
        d="M183.884 206.744c-4.629 0-9.813-2.651-12.833-5.924.94-1.011 12.833-12.833 12.833-12.833v18.757z"
      />

      {/* LINE */}
      <Path
        id="prefix__sector5"
        className="prefix__cls-1"
        fill="black"
        d="M184.76 184.173l-3.629 3.618L67.913 300.682l1.414 1.418L182.753 189l-.171 160.446h2l.17-159.737z"
      />

      <Path
        id="prefix__sector410"
        className="prefix__cls-1"
        fill={fills.prefix__sector49 ?? 'transparent'}
        d="M184.069 332.67c40.4 0 76.03-16.4 102.386-42.942 4.306 4.305 9.693 10.07 11.144 11.521C268.354 330.7 228.708 348.9 183.884 348.9c0-1.989.185-12.277.185-16.23z"
      />
      <Path
        id="prefix__sector49"
        className="prefix__cls-1"
        fill={fills.prefix__sector48 ?? 'transparent'}
        d="M275.689 279.071c-23.658 23.632-55.543 38.236-91.805 38.236 0 2.1.185 13.164.185 15.363 40.4 0 76.03-16.4 102.386-42.942-2.462-2.462-8.996-8.887-10.766-10.657z"
      />
      <Path
        id="prefix__sector48"
        className="prefix__cls-1"
        fill={fills.prefix__sector47 ?? 'transparent'}
        d="M275.689 279.071c-23.658 23.632-55.543 38.236-91.805 38.236v-15.794c31.633 0 59.321-12.808 79.959-33.531 2.632 2.632 9.426 8.669 11.846 11.089z"
      />
      <Path
        id="prefix__sector47"
        className="prefix__cls-1"
        fill={fills.prefix__sector46 ?? 'transparent'}
        d="M183.884 285.718a96.9 96.9 0 0069.1-28.825c1.514 1.515 9.642 9.873 10.858 11.089-20.638 20.723-48.326 33.531-79.959 33.531v-15.795z"
      />
      <Path
        id="prefix__sector46"
        className="prefix__cls-1"
        fill={fills.prefix__sector45 ?? 'transparent'}
        d="M242.126 245.805a81.975 81.975 0 01-58.242 24.118v15.8a96.9 96.9 0 0069.1-28.825c-1.458-1.464-9.053-9.288-10.858-11.093z"
      />
      <Path
        id="prefix__sector45"
        className="prefix__cls-1"
        fill={fills.prefix__sector44 ?? 'transparent'}
        d="M183.884 254.128a64.969 64.969 0 0046.4-19.412c1.4 1.4 7.224 6.481 11.846 11.089a81.975 81.975 0 01-58.242 24.118v-15.8z"
      />
      <Path
        id="prefix__sector44"
        className="prefix__cls-1"
        fill={fills.prefix__sector43 ?? 'transparent'}
        d="M219.422 223.627a50.037 50.037 0 01-35.538 14.706v15.795a64.969 64.969 0 0046.4-19.412c-3.684-3.708-9.718-9.945-10.862-11.089z"
      />
      <Path
        id="prefix__sector43"
        className="prefix__cls-1"
        fill={fills.prefix__sector42 ?? 'transparent'}
        d="M219.422 223.627a50.037 50.037 0 01-35.538 14.706v-15.795c9.259 0 18.639-3.819 24.679-10 .973.973 9.07 9.301 10.859 11.089z"
      />
      <Path
        id="prefix__sector42"
        className="prefix__cls-1"
        fill={fills.prefix__sector41 ?? 'transparent'}
        d="M208.563 212.538c-6.04 6.181-15.42 10-24.679 10v-15.794c4.629 0 9.813-2.651 12.834-5.924 2.051 2.052 9.965 9.808 11.845 11.718z"
      />
      <Path
        id="prefix__sector41"
        className="prefix__cls-1"
        fill={fills.prefix__sector40 ?? 'transparent'}
        d="M183.884 206.744c4.629 0 9.813-2.651 12.834-5.924-.94-1.011-12.834-12.833-12.834-12.833v18.757z"
      />

      {/* LINE */}
      <Path
        id="prefix__sector4"
        className="prefix__cls-1"
        fill="black"
        d="M182.24 184.173l3.629 3.618 113.218 112.891-1.414 1.418L184.247 189l.171 160.446h-2l-.17-159.737z"
      />

      <Path
        id="prefix__sector310"
        className="prefix__cls-1"
        fill={fills.prefix__sector39 ?? 'transparent'}
        d="M329.567 187.185c0 40.4-16.4 76.03-42.942 102.386 4.305 4.3 10.07 9.692 11.521 11.143 29.448-29.244 47.648-68.89 47.648-113.714-1.986 0-12.274.185-16.227.185z"
      />
      <Path
        id="prefix__sector39"
        className="prefix__cls-1"
        fill={fills.prefix__sector38 ?? 'transparent'}
        d="M275.968 278.805C299.6 255.146 314.2 223.262 314.2 187c2.1 0 13.164.185 15.363.185 0 40.4-16.4 76.03-42.942 102.386-2.458-2.462-8.883-8.996-10.653-10.766z"
      />
      <Path
        id="prefix__sector38"
        className="prefix__cls-1"
        fill={fills.prefix__sector37 ?? 'transparent'}
        d="M275.968 278.805C299.6 255.146 314.2 223.262 314.2 187h-15.79c0 31.633-12.808 59.321-33.531 79.959 2.632 2.632 8.669 9.426 11.089 11.846z"
      />
      <Path
        id="prefix__sector37"
        className="prefix__cls-1"
        fill={fills.prefix__sector36 ?? 'transparent'}
        d="M282.615 187a96.9 96.9 0 01-28.825 69.1c1.515 1.515 9.873 9.643 11.089 10.859C285.6 246.321 298.41 218.633 298.41 187h-15.8z"
      />
      <Path
        id="prefix__sector36"
        className="prefix__cls-1"
        fill={fills.prefix__sector35 ?? 'transparent'}
        d="M242.7 245.242A81.977 81.977 0 00266.82 187h15.8a96.9 96.9 0 01-28.825 69.1c-1.464-1.459-9.288-9.053-11.095-10.858z"
      />
      <Path
        id="prefix__sector35"
        className="prefix__cls-1"
        fill={fills.prefix__sector34 ?? 'transparent'}
        d="M251.025 187a64.969 64.969 0 01-19.412 46.4c1.4 1.4 6.481 7.224 11.089 11.846A81.977 81.977 0 00266.82 187h-15.795z"
      />
      <Path
        id="prefix__sector34"
        className="prefix__cls-1"
        fill={fills.prefix__sector33 ?? 'transparent'}
        d="M220.524 222.537A50.033 50.033 0 00235.23 187h15.8a64.969 64.969 0 01-19.412 46.4c-3.713-3.686-9.95-9.719-11.094-10.863z"
      />
      <Path
        id="prefix__sector33"
        className="prefix__cls-1"
        fill={fills.prefix__sector32 ?? 'transparent'}
        d="M220.524 222.537A50.033 50.033 0 00235.23 187h-15.795c0 9.258-3.819 18.638-10 24.679.973.972 9.301 9.07 11.089 10.858z"
      />
      <Path
        id="prefix__sector32"
        className="prefix__cls-1"
        fill={fills.prefix__sector31 ?? 'transparent'}
        d="M209.435 211.679c6.181-6.041 10-15.421 10-24.679h-15.794c0 4.629-2.651 9.813-5.923 12.833 2.051 2.052 9.807 9.967 11.717 11.846z"
      />
      <Path
        id="prefix__sector31"
        className="prefix__cls-1"
        fill={fills.prefix__sector30 ?? 'transparent'}
        d="M203.641 187c0 4.629-2.651 9.813-5.923 12.833-1.012-.94-12.834-12.833-12.834-12.833h18.757z"
      />

      {/* LINE */}
      <Path
        id="prefix__sektor3"
        className="prefix__cls-2"
        fill="black"
        d="M180.994 185.25l5.125-.007L346 185.011v2l-160.176.232L299.4 300.577 297.988 302 184.917 189.164z"
      />

      <Path
        id="prefix__sector210"
        className="prefix__cls-1"
        fill={fills.prefix__sector29 ?? 'transparent'}
        d="M329.567 185.815c0-40.4-16.4-76.03-42.942-102.386 4.305-4.306 10.07-9.693 11.521-11.143 29.448 29.244 47.648 68.89 47.648 113.714-1.986 0-12.274-.185-16.227-.185z"
      />
      <Path
        id="prefix__sector29"
        className="prefix__cls-1"
        fill={fills.prefix__sector28 ?? 'transparent'}
        d="M275.968 94.2C299.6 117.854 314.2 149.738 314.2 186c2.1 0 13.164-.185 15.363-.185 0-40.4-16.4-76.03-42.942-102.386-2.458 2.462-8.883 8.996-10.653 10.771z"
      />
      <Path
        id="prefix__sector28"
        className="prefix__cls-1"
        fill={fills.prefix__sector27 ?? 'transparent'}
        d="M275.968 94.2C299.6 117.854 314.2 149.738 314.2 186h-15.79c0-31.633-12.808-59.321-33.531-79.959 2.632-2.632 8.669-9.426 11.089-11.841z"
      />
      <Path
        id="prefix__sector27"
        className="prefix__cls-1"
        fill={fills.prefix__sector26 ?? 'transparent'}
        d="M282.615 186a96.9 96.9 0 00-28.825-69.1c1.515-1.515 9.873-9.643 11.089-10.859C285.6 126.679 298.41 154.367 298.41 186h-15.8z"
      />
      <Path
        id="prefix__sector26"
        className="prefix__cls-1"
        fill={fills.prefix__sector25 ?? 'transparent'}
        d="M242.7 127.758A81.977 81.977 0 01266.82 186h15.8a96.9 96.9 0 00-28.825-69.1c-1.464 1.459-9.288 9.053-11.095 10.858z"
      />
      <Path
        id="prefix__sector25"
        className="prefix__cls-1"
        fill={fills.prefix__sector24 ?? 'transparent'}
        d="M251.025 186a64.969 64.969 0 00-19.412-46.4c1.4-1.4 6.481-7.224 11.089-11.846A81.977 81.977 0 01266.82 186h-15.795z"
      />
      <Path
        id="prefix__sector24"
        className="prefix__cls-1"
        fill={fills.prefix__sector23 ?? 'transparent'}
        d="M220.524 150.463A50.033 50.033 0 01235.23 186h15.8a64.969 64.969 0 00-19.412-46.4c-3.713 3.686-9.95 9.719-11.094 10.863z"
      />
      <Path
        id="prefix__sector23"
        className="prefix__cls-1"
        fill={fills.prefix__sector22 ?? 'transparent'}
        d="M220.524 150.463A50.033 50.033 0 01235.23 186h-15.795c0-9.258-3.819-18.638-10-24.679.973-.972 9.301-9.07 11.089-10.858z"
      />
      <Path
        id="prefix__sector22"
        className="prefix__cls-1"
        fill={fills.prefix__sector21 ?? 'transparent'}
        d="M209.435 161.321c6.181 6.041 10 15.421 10 24.679h-15.794c0-4.629-2.651-9.813-5.923-12.833 2.051-2.052 9.807-9.967 11.717-11.846z"
      />
      <Path
        id="prefix__sector21"
        className="prefix__cls-1"
        fill={fills.prefix__sector20 ?? 'transparent'}
        d="M203.641 186c0-4.629-2.651-9.813-5.923-12.833-1.012.94-12.834 12.833-12.834 12.833h18.757z"
      />

      {/* LINE */}
      <Path
        id="prefix__sector2"
        className="prefix__cls-1"
        fill="black"
        d="M180.673 186.806l3.616-3.626L297.116 70.026l1.417 1.412L185.5 184.8l160.292-.231v2l-159.585.23z"
      />

      <Path
        id="prefix__sector110"
        className="prefix__cls-1"
        fill={fills.prefix__sector19 ?? 'transparent'}
        d="M184.069 40.33c40.4 0 76.03 16.4 102.386 42.942 4.306-4.305 9.693-10.07 11.144-11.521C268.354 42.3 228.708 24.1 183.884 24.1c0 1.989.185 12.277.185 16.23z"
      />
      <Path
        id="prefix__sector19"
        className="prefix__cls-1"
        fill={fills.prefix__sector18 ?? 'transparent'}
        d="M275.689 93.929c-23.658-23.629-55.543-38.236-91.805-38.236 0-2.1.185-13.164.185-15.363 40.4 0 76.03 16.4 102.386 42.942-2.462 2.462-8.996 8.887-10.766 10.657z"
      />
      <Path
        id="prefix__sector18"
        className="prefix__cls-1"
        fill={fills.prefix__sector17 ?? 'transparent'}
        d="M275.689 93.929c-23.658-23.629-55.543-38.236-91.805-38.236v15.795c31.633 0 59.321 12.807 79.959 33.531 2.632-2.633 9.426-8.67 11.846-11.09z"
      />
      <Path
        id="prefix__sector17"
        className="prefix__cls-1"
        fill={fills.prefix__sector16 ?? 'transparent'}
        d="M183.884 87.282a96.9 96.9 0 0169.1 28.825c1.514-1.515 9.642-9.873 10.858-11.089-20.638-20.723-48.326-33.53-79.959-33.53v15.794z"
      />
      <Path
        id="prefix__sector16"
        className="prefix__cls-1"
        fill={fills.prefix__sector15 ?? 'transparent'}
        d="M242.126 127.2a81.975 81.975 0 00-58.242-24.118v-15.8a96.9 96.9 0 0169.1 28.825c-1.458 1.459-9.053 9.283-10.858 11.093z"
      />
      <Path
        id="prefix__sector15"
        className="prefix__cls-1"
        fill={fills.prefix__sector14 ?? 'transparent'}
        d="M183.884 118.872a64.969 64.969 0 0146.4 19.412c1.4-1.4 7.224-6.481 11.846-11.089a81.975 81.975 0 00-58.242-24.118v15.8z"
      />
      <Path
        id="prefix__sector14"
        className="prefix__cls-1"
        fill={fills.prefix__sector13 ?? 'transparent'}
        d="M219.422 149.373a50.037 50.037 0 00-35.538-14.706v-15.8a64.969 64.969 0 0146.4 19.412c-3.684 3.713-9.718 9.95-10.862 11.094z"
      />
      <Path
        id="prefix__sector13"
        className="prefix__cls-1"
        fill={fills.prefix__sector12 ?? 'transparent'}
        d="M219.422 149.373a50.037 50.037 0 00-35.538-14.706v15.795c9.259 0 18.639 3.819 24.679 10 .973-.973 9.07-9.301 10.859-11.089z"
      />
      <Path
        id="prefix__sector12"
        className="prefix__cls-1"
        fill={fills.prefix__sector11 ?? 'transparent'}
        d="M208.563 160.462c-6.04-6.181-15.42-10-24.679-10v15.794c4.629 0 9.813 2.651 12.834 5.924 2.051-2.052 9.965-9.808 11.845-11.718z"
      />
      <Path
        id="prefix__sector11"
        className="prefix__cls-1"
        fill={fills.prefix__sector10 ?? 'transparent'}
        d="M183.884 166.256c4.629 0 9.813 2.651 12.834 5.924-.94 1.011-12.834 12.833-12.834 12.833v-18.757z"
      />

      {/* LINE */}
      <Path
        id="prefix__sector1"
        className="prefix__cls-1"
        fill="black"
        d="M182.513 187.776l-.008-5.122-.231-159.792h2l.232 160.085 113.18-113.51 1.416 1.412-112.681 113.007z"
      />
    </Svg>
  )
}
