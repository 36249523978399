import { Button, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { THEME } from '../../../theme'

import { RegisterScreen } from '../RegisterScreen'

export const AdminScreen = ({ navigation }) => {
  const toPromoCods = () => {
    navigation.navigate('PromoCods')
  }

  const toUsersPromoCods = () => {
    navigation.navigate('UsersPromoCods')
  }

  const toHome = () => {
    navigation.navigate('Auth')
  }

  return (

    <View style={styles.body}>
      <TouchableOpacity onPress={toPromoCods}>
        <View style={styles.button}>
          <Text style={styles.text}>{'Промокоды'}</Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={toUsersPromoCods}>
        <View style={styles.button}>
          <Text style={styles.text}>{'Информация по пользователям'}</Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={toHome}>
        <View style={styles.button}>
          <Text style={styles.text}>{'Выйти'}</Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}

AdminScreen.navigationOptions = {
  headerTitle: 'Админ'
}

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    color: 'gray',
    flex: 1,
    justifyContent: 'center',
    padding: 40
  },
  button: {

    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    marginBottom: 10,
    paddingVertical: 15,
    width: 270
  },
  text: {
    color: THEME.MAIN_DARK,
    textAlign: 'center'
  }
})
