import { StyleSheet } from 'react-native'
import { THEME } from '../theme'

export default StyleSheet.create({
  input: {
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    color: 'black',
    height: 36,
    marginBottom: 13,
    paddingLeft: 18,
    paddingVertical: 18,
    width: 300
  },
  label: {
    color: THEME.MAIN_DARK,
    marginBottom: 10
  },
  placeholder: {
    color: '#C7C7CD'
  },
  userInput: {
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    color: 'black',
    fontSize: 12,
    height: 54,
    marginBottom: 13,
    paddingLeft: 18,
    paddingVertical: 18,
    width: 300,
    zIndex: 10
  },
  value: {
    color: 'black',
    fontSize: 14
  }
})
