import React, { useContext, useEffect } from 'react'
import {
  Alert,
  Keyboard,
  KeyboardAvoidingView,
  StyleSheet,
  View,
  Text,
  TouchableWithoutFeedback,
  Linking, Platform
} from 'react-native'
import { UserContext } from '../../context/user/userContext'
import { AppLoader } from '../../components/ui/AppLoader'
import { Formik } from 'formik'
import { DatePickerField } from '../../components/ui/DatePickerField'
import { DropdownField } from '../../components/ui/DropdownField'
import { SCButton } from '../../components/ui/button/SCButton'
import { SCInput } from '../../components/ui/inputs/SCInput'
import { ScrollView } from 'react-native-gesture-handler'

import { CheckBox } from 'react-native-elements'
import { THEME } from '../../theme'

export const RegisterScreen = ({ navigation }) => {
  const { success, clearSuccess, userRegister, loading, error, clearError } =
    useContext(UserContext)

  const register = (values) => {
    if (values.firstName !== '' && values.lastName !== '' && values.email !== '' && values.password !== '') {
      userRegister(values, navigation)
      Keyboard.dismiss()
    }
  }

  const openDoc = () => {
    const url = 'https://static.selfconstruct.ru/personal_data_policy.pdf'
    Linking.canOpenURL(url)
      .then(supported => {
        if (!supported) {
          console.log('Unsupported URL: ' + url)
        } else {
          return Linking.openURL(url)
        }
      }).catch(err => console.error('An error occurred ', err))
  }

  useEffect(() => {
    if (error === true) {
      if (Platform.OS === 'web') {
        const result = confirm(error)
        if (result) {
          clearError()
        }
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  useEffect(() => {
    if (success) {
      if (Platform.OS === 'web') {
        alert('Аккаунт успешно создан')
        clearSuccess()
        navigation.navigate('Login')
      } else {
        Alert.alert('Успешно!', 'Аккаунт успешно создан', [
          {
            text: 'Ок',
            onPress: () => {
              clearSuccess()
              navigation.navigate('Login')
            },
            style: 'default'
          }
        ])
      }
    }
  }, [success])

  if (loading) {
    return <AppLoader />
  }

  // const test = () => {
  //     // navigation.navigate("AnimationBeforeStart")
  //     navigation.navigate("Admin");
  // }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 0}
    >
      <ScrollView>
        <View style={styles.body}>
          <Formik
            initialValues={{

              firstName: '',
              lastName: '',
              middleName: '',
              email: '',
              password: '',
              dateBirthday: new Date(),
              gender: 1,
              isAgree: false,
              isDateExit: false
            }}
            onSubmit={(values) => register(values)}
          >
            {({ setFieldValue, handleChange, handleBlur, handleSubmit, values }) => (
              <View style={styles.form}>
                <SCInput
                  style={styles.marginInputs}
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                  label="Email"
                  autoCorrect={false}
                  autoCapitalize="none"
                  value={values.email}
                />
                <SCInput
                  style={styles.marginInputs}
                  onChangeText={handleChange('password')}
                  onBlur={handleBlur('password')}
                  secureTextEntry={true}
                  label="Пароль"
                  autoCorrect={false}
                  autoCapitalize="none"
                  description={'Минимум 8 символов'}
                  value={values.password}
                />
                <DatePickerField
                  style={styles.marginInputs}
                  onChangeText={handleChange('dateBirthday')}
                  onBlur={handleBlur('dateBirthday')}
                  label="Дата рождения"
                  value={values.dateBirthday}
                  name="dateBirthday"
                />

                <DropdownField
                  style={styles.marginInputs}
                  onChangeText={handleChange('gender')}
                  onBlur={handleBlur('gender')}
                  value={values.gender}
                  label="Пол"
                  items={[
                    {
                      label: 'Мужской',
                      value: 1
                    },
                    {
                      label: 'Женский',
                      value: 2
                    }
                  ]}
                  checkerDisable={true}
                  name="gender"
                  id="gender"
                />

                <SCInput
                  style={styles.marginInputs}
                  onChangeText={handleChange('firstName')}
                  onBlur={handleBlur('firstName')}
                  label="Имя"
                  value={values.firstName}
                />
                <SCInput
                  style={styles.marginInputs}
                  onChangeText={handleChange('lastName')}
                  onBlur={handleBlur('lastName')}
                  label="Фамилия"
                  value={values.lastName}
                />
                <SCInput
                  onChangeText={handleChange('middleName')}
                  onBlur={handleBlur('middleName')}
                  label="Отчество"
                  value={values.middleName}
                  style={styles.marginButton}
                />

                <View style={styles.checkboxContainer}>

                  <CheckBox

                    size={20}
                    checked={values?.isAgree}
                    onPress={() => setFieldValue('isAgree', !values?.isAgree)}
                    checkedColor={THEME.MAIN_DARK}
                    uncheckedColor={THEME.MAIN_DARK}
                  />

                  <TouchableWithoutFeedback onPress={openDoc}>
                    <View style={styles.TouchableWithoutFeedback}>
                      <Text style={styles.text}> Положение об обработке персональных данных </Text>
                    </View>
                  </TouchableWithoutFeedback>

                </View>

                <SCButton
                  text="Создать Аккаунт"
                  onPress={handleSubmit}
                  disabled={!values?.isAgree}
                />

                {/* <Button */}
                {/*    title={"testNavigation"} */}
                {/*    onPress={test} */}
                {/* /> */}

              </View>
            )}
          </Formik>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
}

RegisterScreen.navigationOptions = {
  headerTitle: 'Регистрация'
}

const styles = StyleSheet.create({

  TouchableWithoutFeedback: {

    paddingVertical: 16

  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%'
  },
  body: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 40
  },
  checkboxContainer: {

    flexDirection: 'row',
    marginBottom: 10,
    marginLeft: -30,
    marginTop: -31,
    width: 300
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  },
  form: {
    alignItems: 'center',
    minHeight: 300,
    width: '100%'
  },
  label: {
    margin: 8
  },
  marginButton: {
    marginBottom: 41
  },
  marginInputs: {
    marginBottom: 13
  },
  text: {
    color: THEME.MAIN_DARK,
    fontSize: 11,
    marginLeft: -20,
    marginTop: 2,
    textDecorationColor: 'gray',
    textDecorationLine: 'underline'
  }
})
