import React, { useContext, useEffect } from 'react'
import { View, Text, StyleSheet, Platform, ScrollView } from 'react-native'
import { THEME } from '../../../theme'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { AntDesign } from '@expo/vector-icons'
import { AppLoader } from '../../../components/ui/AppLoader'
import { ArchiveContext } from '../../../context/archive/archiveContext'
import moment from 'moment'

export const ArchiveGroupsScreen = ({ navigation }) => {
  const { groupList, loading, error, getGroupList } =
    useContext(ArchiveContext)

  useEffect(() => {
    getGroupList()
  }, [])

  const toGroup = (item) => {
    navigation.navigate('GroupView', { currentGroupId: item.id })
  }

  if (loading) {
    return <AppLoader />
  } else {
    console.log('GROUPS: ', groupList)
  }

  return (
    <View>
      <ScrollView style={styles.groups}>
        {groupList &&
          groupList.map((group, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => toGroup(group)}
              style={styles.groupRow}
            >
              <View>
                <Text style={styles.coreInfo}>{group.name}</Text>
                <Text style={styles.additionalInfo}>
                  Группа создана:{' '}
                  {moment(group.createdDateUtc).format('DD.MM.YYYY')}
                </Text>
              </View>
              <Text>Число файлов: {group.photosCount}</Text>
            </TouchableOpacity>
          ))}
      </ScrollView>
    </View>
  )
}

ArchiveGroupsScreen.navigationOptions = ({ navigation }) => {
  const onAdd = () => {
    navigation.navigate('GroupAdd', {
      isEdit: false
    })
  }

  return {
    headerTitle: 'Архив',
    headerRight: () => (
      <TouchableOpacity style={styles.headerRight} onPress={onAdd}>
        <AntDesign
          name="plus"
          color={Platform.OS === 'android' ? '#fff' : THEME.MAIN_COLOR}
          size={24}
        />
      </TouchableOpacity>
    )
  }
}
const styles = StyleSheet.create({
  additionalInfo: {
    color: '#787878',
    fontSize: 12
  },
  coreInfo: {
    marginBottom: 5
  },
  groupRow: {
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    padding: 16,
    width: '100%'
  },
  groups: {
    flexDirection: 'column',
    padding: 18
  },
  headerRight: {
    marginRight: 26
  }
})
