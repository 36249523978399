import React, { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { Audio, Video } from 'expo-av'

export const IosVideo = ({ video, task, setStatus }) => {
  const [soundObj, setSound] = useState()

  useEffect(() => {
    (async () => {
      await Audio.requestPermissionsAsync()
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        await Audio.setAudioModeAsync({
          staysActiveInBackground: true,
          interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
          shouldDuckAndroid: false,
          playThroughEarpieceAndroid: false,
          allowsRecordingIOS: false,
          interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
          playsInSilentModeIOS: true
        })
      } else {
        await Audio.setAudioModeAsync({
          staysActiveInBackground: true,
          shouldDuckAndroid: false,
          playThroughEarpieceAndroid: false,
          allowsRecordingIOS: false,
          playsInSilentModeIOS: true
        })
      }
    })();

    // Tricks to fix that the audio doesn't work even if we set the configuration on iOS: `playsInSilentModeIOS`
    (async () => {
      const { sound } = await Audio.Sound.createAsync(
        require('../../../../assets/sounds/500-milliseconds-of-silence.mp3')
      )
      setSound(sound)
    })()
  }, [])

  useEffect(() => {
    const playSilentSound = async () => {
      // await sound.loadAsync(
      //   require("../../../../assets/")
      // );
      await soundObj.playAsync()
      await soundObj.setIsLoopingAsync(true)
    };

    (async () => {
      await playSilentSound()
    })()

    return () => {
      (async () => {
        soundObj.stopAsync()
        soundObj.unloadAsync()
      })()
    }
  }, [soundObj])

  return (
    <Video
      ref={video}
      source={{
        uri: `${task.url}`,
        headers: {
          'User-Agent': '4E38221A-8BF3-4302-8C49-0F6E5B91B64B'
        }
      }}
      useNativeControls
      ignoreSilentSwitch="ignore"
      resizeMode="contain"
      style={{ width: '100%', height: '100%' }}
      isLooping={false}
      onPlaybackStatusUpdate={(status) => setStatus(status)}
    />
  )
}
