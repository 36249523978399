import { getData } from '../../services/storage.service'
import { CONFIG } from '../../config'
import { Http } from '../../services/http'

export const getTargetsBySectorId = async (sectorId) => {
  let token = ''
  await getData('currentUser').then((response) => {
    if (response) {
      token = response.token
    }
  })

  try {
    // Отправляем запрос на бек
    const data = await Http.get(
      `${CONFIG.API_URL}CircleBalanceSectorTarget/actual/${sectorId}`,
      token
    )
    console.log(`SECTOR ID: ${sectorId}, DATA: `, data)
    return data
  } catch (e) {
    console.log('ERRROR', e)
  } finally {
  }
}

export const removeTargetById = async (id) => {
  console.log('IM REMOVE: ', id)
  let token = ''
  await getData('currentUser').then((response) => {
    if (response) {
      token = response.token
    }
  })

  try {
    // Отправляем запрос на бек
    await Http.delete(
      `${CONFIG.API_URL}CircleBalanceSectorTarget/${id}`,
      token
    )
    //  return data;
  } catch (e) {
    console.log('ERRROR', e)
  } finally {
  }
}

// export const saveSliderValues = async (wheel) => {

// };

// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }
