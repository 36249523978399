import React from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { THEME } from '../../../theme'

const ResponsiveBtn = ({ text, onPress, style, disabled }) => {
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <View style={{ ...styles.button, ...style }}>
        <Text style={styles.text}>{text}</Text>
      </View>
    </TouchableOpacity>
  )
}

export default ResponsiveBtn

const styles = StyleSheet.create({
  button: {
    borderColor: THEME.MAIN_DARK,
    borderWidth: 1
  },
  text: {
    color: THEME.MAIN_DARK,
    textAlign: 'center'
  }
})
