import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TextInput,
  Alert,
  Platform
} from 'react-native'
import { THEME } from '../../../theme'
import { SCButton } from '../../../components/ui/button/SCButton'
import { InsideContext } from '../../../context/inside/insideContext'
import { getInsideById } from './insiteService'
import { AppLoader } from '../../../components/ui/AppLoader'
import moment from 'moment'

export const InsiteViewScreen = ({ navigation }) => {
  const {
    updateInsideItem,
    loading,
    success,
    clearSuccess,
    error,
    clearError
  } = useContext(InsideContext)
  const [currentItem, setCurrentItem] = useState()
  const [notice, onChangeText] = useState()
  const [insideLoading, setInsideLoading] = useState(true)

  const currentId = navigation.getParam('currentItemId')
  console.log('NAVIGATION OPTION ITEM: ', currentId)

  const loadItem = useCallback(async () => {
    const currentInside = await getInsideById(currentId)
    setCurrentItem(currentInside)
    onChangeText(currentInside.notice)
    setInsideLoading(false)
  }, [])

  useEffect(() => {
    loadItem()
  }, [])

  const apply = async () => {
    setInsideLoading(true)

    let title
    if (notice.length >= 17) {
      title = notice.split('\n')[0].substring(0, 17) + '...'
    } else {
      title = notice
    }

    const data = {
      id: currentItem.id,
      dateCreated: new Date().toISOString(),
      title,
      notice
    }
    updateInsideItem(data)

    setCurrentItem({
      title: data.title,
      dateCreated: data.dateCreated,
      notice: data.notice
    })

    setInsideLoading(false)
  }

  useEffect(() => {
    if (success) {
      if (Platform.OS === 'web') {
        alert('Данные успешно обновлены!')
        clearSuccess()
        navigation.navigate('Insites')
      } else {
        Alert.alert('Успешно!', 'Данные успешно обновлены!', [
          {
            text: 'Ок',
            onPress: () => {
              clearSuccess()
              navigation.navigate('Insites')
            },
            style: 'default'
          }
        ])
      }
    }
  }, [success])

  useEffect(() => {
    if (error) {
      if (Platform.OS === 'web') {
        alert(error)
        clearError()
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  if (insideLoading) {
    return <AppLoader />
  }

  if (loading) {
    return <AppLoader />
  }

  return (
    <View>
      <View style={styles.headStyles}>
        <Text style={styles.headerTitle}>{currentItem?.title}</Text>
        <Text style={styles.headerDate}>
          {moment(currentItem?.dateCreated).format('DD.MM.YYYY')}
        </Text>
      </View>
      <ScrollView style={styles.taskList}>
        <View style={styles.minHeight}>
          <TextInput
            multiline
            allowFontScaling={false}
            onChangeText={(text) => onChangeText(text)}
            placeholder={'Напишите свои мысли...'}
            value={notice}
            numberOfLines={20}
          ></TextInput>
        </View>
      </ScrollView>

      <View style={styles.action}>
        <SCButton onPress={apply} text="Сохранить" />
      </View>
    </View>
  )
}

InsiteViewScreen.navigationOptions = {
  headerTitle: 'Просмотр инсайта'
}

const styles = StyleSheet.create({
  taskList: {
    flexDirection: 'column',
    padding: 18
  },
  headStyles: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 18,
    width: '100%'
  },
  blur: {
    backgroundColor: 'black',
    height: 200
  },
  taskRowCompleted: {
    color: '#C9C9C9'
  },
  taskRowActive: {
    color: THEME.ACCENT_PINK
  },
  buttonCenter: {
    alignSelf: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  // new
  scrollView: {
    paddingHorizontal: 10,
    paddingTop: 20
  },
  minHeight: {
    height: 440
  },
  action: {
    alignItems: 'center',
    backgroundColor: '#eae9e9',
    elevation: 24,
    height: 120,

    paddingTop: 30,
    shadowColor: '#eae9e9',
    shadowOffset: {
      width: 0,
      height: -70
    },
    shadowOpacity: 1,
    shadowRadius: 40,

    width: '100%'
  }
})
