import React, { useContext, useState, useEffect } from 'react'
import {
  View,
  StyleSheet,
  ScrollView,
  Modal,
  Text,
  Pressable,
  Alert,
  KeyboardAvoidingView,
  Platform
} from 'react-native'
import { SCButton } from '../../components/ui/button/SCButton'
import { THEME } from '../../theme'
import bodyStyles from '../../styles/bodyStyles'
import { SCTargetAccordion } from '../../components/ui/inputs/SCTargetAccordion'
import { BlurView } from 'expo-blur'
import { WheelContext } from '../../context/wheel/wheelContext'
import { AppLoader } from '../../components/ui/AppLoader'

export const WheelTargetsScreen = ({ navigation }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const {
    error,
    clearError,
    success,
    clearSuccess,
    wheel,
    loading,
    wheelUpdateSliderValueToAPI
  } = useContext(WheelContext)

  const onAccept = async () => {
    wheelUpdateSliderValueToAPI(wheel)
    setModalVisible(false)
    navigation.goBack()
  }

  useEffect(() => {
    if (error) {
      if (Platform.OS === 'web') {
        alert(error)
        clearError()
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  useEffect(() => {
    if (success) {
      const msgSuccess = 'Данные успешно обновлены'
      if (Platform.OS === 'web') {
        alert(msgSuccess)
        clearSuccess()
      } else {
        Alert.alert('Успешно!', msgSuccess, [
          {
            text: 'Ок',
            onPress: () => {
              clearSuccess()
            },
            style: 'default'
          }
        ])
      }
    }
  }, [success])

  if (loading) {
    return <AppLoader />
  }

  return (
    <KeyboardAvoidingView
      style={bodyStyles.main}
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 0}
    >
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={{ paddingBottom: 60 }}
      >
        {wheel.length !== 0 &&
          wheel.map((item) => (
            <SCTargetAccordion
              key={item.id}
              id={item.id}
              circleBalanceSectorId={item.circleBalanceSectorId}
              userId={item.userId}
              title={item.name}
              value={item.score}
              color={item.color}
              targetChange={(data) => {
                setNewCircleValues(data)
              }}
            ></SCTargetAccordion>
          ))}
      </ScrollView>
      <View style={styles.action}>
        <SCButton onPress={() => setModalVisible(true)} text="Применить" />
      </View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible)
        }}
      >
        <BlurView intensity={90} style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>
              Вы собираетесь сохранить изменения. Вы всегда можете вернуться к
              этому экрану для редактирования оценки и целей на год!
            </Text>
            <View style={styles.actions}>
              <Pressable onPress={() => setModalVisible(!modalVisible)}>
                <Text style={styles.button}>ОТМЕНА</Text>
              </Pressable>
              <Pressable onPress={() => onAccept()}>
                <Text style={styles.button}>ПРИМЕНИТЬ</Text>
              </Pressable>
            </View>
          </View>
        </BlurView>
      </Modal>
    </KeyboardAvoidingView>
  )
}

WheelTargetsScreen.navigationOptions = ({ navigation }) => {
  return {
    headerTitle: 'Оценка и цели на год'
  }
}

const styles = StyleSheet.create({
  accordion: {
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 2,
    marginBottom: 15,
    paddingHorizontal: 14,
    paddingVertical: 7
  },
  accordionBody: {
    paddingBottom: 10,
    paddingTop: 26
  },
  action: {
    alignItems: 'center',
    backgroundColor: '#eae9e9',
    elevation: 24,
    height: 100,

    paddingTop: 30,
    shadowColor: '#eae9e9',
    shadowOffset: {
      width: 0,
      height: -30
    },
    shadowOpacity: 1,
    shadowRadius: 40,

    width: '100%'
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30
  },
  button: {
    color: THEME.MAIN_DARK
  },
  centeredView: {
    backgroundColor: THEME.MAIN_DARK,
    height: '100%',
    justifyContent: 'center',
    padding: 26,
    position: 'absolute',
    width: '100%'
  },
  circle: {
    backgroundColor: 'black',
    borderRadius: 5,
    height: 10,
    marginRight: 5,
    width: 10
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerTitle: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  minHeight: {
    height: 620
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center'
  },

  modalView: {
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 3,
    margin: 20,
    padding: 35
  },
  scrollView: {
    paddingBottom: 90,
    paddingHorizontal: 10,
    paddingTop: 20
  },
  targetRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  targetRowAdd: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  targetRowAddText: {
    fontSize: 12,
    paddingTop: 14
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center'
  }
})
