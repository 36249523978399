import React, { useContext, useEffect } from 'react'
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
  Platform
} from 'react-native'
import { AppLoader } from '../../components/ui/AppLoader'
import { CircleLifeBalance } from '../../components/ui/charts/CircleLifeBalance'
import { WheelContext } from '../../context/wheel/wheelContext'

export const WheelScreen = ({ navigation }) => {
  const { wheel, wheelGet, loading } = useContext(WheelContext)

  useEffect(() => {
    wheelGet()
  }, [])

  const onTargets = () => {
    navigation.navigate('WheelTargets')
  }

  if (loading) {
    return <AppLoader />
  }

  return (
    <ScrollView>
      <View style={styles.body}>
        <View style={styles.chart}>
          <CircleLifeBalance sectors={wheel} />
        </View>
        <View style={styles.editButton}>
          <TouchableOpacity onPress={() => onTargets()}>
            <Image style={ Platform.OS === 'web' && { width: '30px', height: '30px' }} source={require('../../../assets/icons/pencil.png')} />
          </TouchableOpacity>
        </View>
        <View style={styles.legend}>
          {wheel.length !== 0 &&
            wheel.map((item) => (
              <View key={item.id} style={styles.row}>
                <View
                  style={[styles.circle, { backgroundColor: item.color }]}
                ></View>
                <Text>{item.name}</Text>
              </View>
            ))}
        </View>
      </View>
    </ScrollView>
  )
}

WheelScreen.navigationOptions = ({ navigation }) => {
  return {
    headerTitle: 'Колесо целей'
  }
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    justifyContent: 'flex-start'
  },
  centeredView: {
    alignItems: 'flex-end',
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: 22
  },
  chart: {
    alignItems: 'center'
  },
  circle: {
    backgroundColor: 'black',
    borderRadius: 5,
    height: 10,
    marginRight: 5,
    width: 10
  },
  editButton: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingRight: 25,
    width: '100%'
  },
  legend: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: 500,
    marginLeft: 20,
    marginRight: 20,
    width: '100%'
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    width: '80%'
  }
})
