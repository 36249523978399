import {
  ARCHIVE_GET_GROUP_LIST,
  ARCHIVE_GET_GROUP_ITEM,
  ARCHIVE_ADD_GROUP_ITEM,
  ARCHIVE_UPDATE_GROUP_ITEM,
  ARCHIVE_ADD_PHOTO_ITEM,
  ARCHIVE_GET_PHOTO_ITEM,
  ARCHIVE_GET_PHOTO_LIST,
  ARCHIVE_CLEAR_PHOTO_ITEM,
  HIDE_LOADER,
  SHOW_LOADER,
  SHOW_SUCCESS,
  CLEAR_SUCCESS,
  SHOW_ERROR,
  CLEAR_ERROR
} from '../types'

const handlers = {
  [ARCHIVE_GET_GROUP_LIST]: (state, action) => ({
    ...state,
    groupList: action.groupList
  }),
  [ARCHIVE_GET_PHOTO_LIST]: (state, action) => ({
    ...state,
    photoList: action.photoList
  }),
  [ARCHIVE_GET_PHOTO_ITEM]: (state, action) => ({
    ...state,
    currentPhoto: action.currentPhoto
  }),
  [ARCHIVE_CLEAR_PHOTO_ITEM]: (state, action) => ({
    ...state,
    photoList: action.updatedPhotoList
  }),
  [ARCHIVE_UPDATE_GROUP_ITEM]: (state, action) => ({
    ...state,
    groupList: action.updatedGroups
  }),
  [SHOW_LOADER]: (state) => ({ ...state, loading: true }),
  [HIDE_LOADER]: (state) => ({ ...state, loading: false }),
  [CLEAR_ERROR]: (state) => ({ ...state, error: null }),
  [SHOW_ERROR]: (state, { error }) => ({ ...state, error }),
  [SHOW_SUCCESS]: (state) => ({ ...state, success: true }),
  [CLEAR_SUCCESS]: (state) => ({ ...state, success: false }),
  DEFAULT: (state) => state
}

export const archiveReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT
  return handler(state, action)
}
