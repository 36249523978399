import React, { useReducer } from 'react'
import { Platform } from 'react-native'
import {
  ARCHIVE_GET_GROUP_LIST,
  ARCHIVE_GET_GROUP_ITEM,
  ARCHIVE_ADD_GROUP_ITEM,
  ARCHIVE_UPDATE_GROUP_ITEM,
  ARCHIVE_ADD_PHOTO_ITEM,
  ARCHIVE_GET_PHOTO_ITEM,
  ARCHIVE_GET_PHOTO_LIST,
  HIDE_LOADER,
  SHOW_LOADER,
  ARCHIVE_CLEAR_PHOTO_ITEM,
  SHOW_ERROR,
  CLEAR_ERROR,
  SHOW_SUCCESS,
  CLEAR_SUCCESS
} from '../types'
import { Http } from '../../services/http'
import { getData } from '../../services/storage.service'
import { CONFIG } from '../../config'
import { archiveReducer } from './archiveReducer'
import { ArchiveContext } from './archiveContext'

export const ArchiveState = ({ children }) => {
  const initialState = {
    groupList: [],
    photoList: [],
    currentPhoto: null,
    loading: false,
    error: null,
    success: false
  }
  const [state, dispatch] = useReducer(archiveReducer, initialState)

  const getGroupList = async () => {
    // Показываем загрузку
    showLoader()
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const groupList = await Http.get(
        `${CONFIG.API_URL}UserPhotoArchive/groups`,
        token
      )

      console.log('GROUPS FROM API ', groupList)
      // Сохраняем в стейт
      dispatch({ type: ARCHIVE_GET_GROUP_LIST, groupList })
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }
  }

  const getPhotoList = async (groupId) => {
    // Показываем загрузку
    showLoader()
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const photoList = await Http.get(
        `${CONFIG.API_URL}UserPhotoArchive/photos/${groupId}`,
        token
      )

      console.log('PHOTOS FROM API ', photoList)
      // Сохраняем в стейт
      dispatch({ type: ARCHIVE_GET_PHOTO_LIST, photoList })
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }
  }

  const addGroup = async (values) => {
    // Показываем загрузку
    showLoader()
    let token = ''
    let responseResult = false
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      const data = new FormData()
      data.append('GroupName', values.name)

      // Отправляем запрос на бек
      const response = await Http.uploadFile(
        `${CONFIG.API_URL}UserPhotoArchive/group`,
        data,
        token
      )

      console.log('RESPONSE FROM API ', response)
      responseResult = true

      const groupList = [...state.groupList, response]
      console.log('GROUPS NEW ', groupList)
      // Сохраняем в стейт
      dispatch({ type: ARCHIVE_GET_GROUP_LIST, groupList })
    } catch (e) {
      console.log('ERRROR', e)
      responseResult = false
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }

    if (responseResult) {
      showSuccess()
    } else {
      showError('Что-то пошло не так...')
    }
  }

  const addPhoto = async (photoData) => {
    // Показываем загрузку
    let responseResult = false
    showLoader()
    const { token } = await getData('currentUser')

    const photoName = `${photoData.name.trim()}`

    try {
      const data = new FormData()
      data.append('Name', photoName)
      data.append('GroupId', photoData.groupId)
      if (Platform.OS === 'web') {
        data.append('Photo', photoData.photo, photoName)
      } else {
        data.append('Photo', {
          uri: photoData.photo,
          name: 'image.jpg',
          type: 'image/jpeg'
        })
      }

      // Отправляем запрос на бек
      const response = await Http.uploadFile(
        `${CONFIG.API_URL}UserPhotoArchive/photo`,
        data,
        token
      )

      console.log('PHOTOS FROM API ', response)
      await getPhotoList(photoData.groupId)
      await getGroupList()
      responseResult = true
    } catch (e) {
      console.log('ERRROR', e)
      responseResult = false
    } finally {
      hideLoader()
    }

    if (responseResult) {
      showSuccess()
    } else {
      showError('Что-то пошло не так...')
    }
  }

  const getPhotoById = async (photoId) => {
    // Показываем загрузку
    showLoader()
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const currentPhoto = await Http.downloadFile(
        `${CONFIG.API_URL}UserPhotoArchive/photo/${photoId}`,
        token
      )

      console.log('PHOTO FROM API ', currentPhoto)
      return currentPhoto
    } catch (e) {
      console.log('ERRROR', e)
    } finally {
      hideLoader()
    }
  }

  const getFSPhtoto = async (photoId) => {
    // Показываем загрузку
    showLoader()
    const { token } = await getData('currentUser')

    try {
      // Отправляем запрос на бек
      const currentPhoto = await Http.fetchBlob(
        `${CONFIG.API_URL}UserPhotoArchive/photoFS/${photoId}`,
        token
      )

      console.log('PHOTO FROM API ', currentPhoto)
      return currentPhoto
    } catch (e) {
      console.log('ERRROR', e)
    } finally {
      hideLoader()
    }
  }

  const removePhotoById = async (id, navigation, groupId) => {
    // Показываем загрузку
    showLoader()
    let responseResult = false
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const currentPhoto = await Http.delete(
        `${CONFIG.API_URL}UserPhotoArchive/photo/${id}`,
        token
      )

      console.log('RESULT FROM API ', currentPhoto)
      // remove photo
      // const updatedPhotoList = initialState.photoList.map((p) => p.id !== id);
      // dispatch({ type: ARCHIVE_CLEAR_PHOTO_ITEM, updatedPhotoList });
      //  console.log('NEW STATE GROUP: ', updatedPhotoList);

      const updatedGroups = state.groupList
      updatedGroups.find((x) => x.id === groupId).photosCount--
      console.log('NEW GROUPS: ', updatedGroups)

      const updatedPhotoList = state.photoList.filter((p) => p.id !== id)
      console.log('NEW PHOTOS: ', updatedPhotoList)

      dispatch({ type: ARCHIVE_CLEAR_PHOTO_ITEM, updatedPhotoList })
      dispatch({ type: ARCHIVE_UPDATE_GROUP_ITEM, updatedGroups })

      responseResult = true
      navigation.navigate('GroupView', { removeSuccess: true })
    } catch (e) {
      responseResult = false
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    } finally {
      hideLoader()
    }

    if (responseResult) {
    } else {
      showError('Что-то пошло не так...')
    }
  }

  const showLoader = () => dispatch({ type: SHOW_LOADER })

  const hideLoader = () => dispatch({ type: HIDE_LOADER })

  const showError = (error) => dispatch({ type: SHOW_ERROR, error })

  const clearError = () => dispatch({ type: CLEAR_ERROR })

  const showSuccess = () => dispatch({ type: SHOW_SUCCESS })

  const clearSuccess = () => dispatch({ type: CLEAR_SUCCESS })

  return (
    <ArchiveContext.Provider
      value={{
        groupList: state.groupList,
        photoList: state.photoList,
        currentPhoto: state.currentPhoto,
        loading: state.loading,
        error: state.error,
        success: state.success,
        getGroupList,
        getPhotoList,
        getPhotoById,
        addGroup,
        addPhoto,
        removePhotoById,
        clearError,
        clearSuccess,
        getFSPhtoto
      }}
    >
      {children}
    </ArchiveContext.Provider>
  )
}
