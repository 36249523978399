import React, { useEffect, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import {
  View,
  Button,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  LogBox
} from 'react-native'
import Modal from 'react-native-modal'
import DateTimePicker from '@react-native-community/datetimepicker'
import inputStyles from '../../styles/inputStyles'
import moment from 'moment'
import { DatePickerModal } from 'react-native-paper-dates'
LogBox.ignoreAllLogs(true)

export const DatePickerField = ({ disabled = false, ...props }) => {
  const [date, setDate] = useState(
    new Date(props.value).getTime() > new Date(1890, 1, 1).getTime()
      ? new Date(props.value)
      : new Date()
  )
  const [displayDate, setDisplayDate] = useState('')
  const [open, setOpen] = React.useState(false)

  const onDismissSingle = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false)
      setDate(params.date)
    },
    [setOpen, setDate]
  )

  const [show, setShow] = useState(false)
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date
    setShow(Platform.OS === 'ios')
    const stringDate = selectedDate.toISOString()
    setFieldValue(field.name, stringDate)
    setDate(currentDate)
    props.onSelectOption?.(stringDate)
  }

  useEffect(() => {
    setDate(props.value)
  }, [props.value])

  useEffect(() => {
    setDisplayDate(moment(date).format('DD.MM.YYYY'))

    // setShow(!show);
  }, [date])

  const onDate = () => {
    if (!disabled) setShow(!show)
  }

  return (
    <View>
      {props.label && <Text style={inputStyles.label}>{props.label}</Text>}
      <TouchableOpacity
        onPress={onDate}
        style={[inputStyles.userInput, props.inputStyle]}
      >
        {date
          ? (
            <Text style={[inputStyles.value, props.inputValueStyle]}>
              {displayDate}
            </Text>
          )
          : (
            <Text style={[inputStyles.placeholder, props.inputPlaceholderStyle]}>
              {props.placeholder}
            </Text>
          )}
      </TouchableOpacity>
      {Platform.OS === 'android' && show && (
        <DateTimePicker
          {...field}
          {...props}
          locale="ru-RU"
          value={date}
          mode="date"
          selected={(field.value && new Date(field.value)) || null}
          onChange={onChange}
        />
      )}
      {Platform.OS === 'ios' && (
        <Modal
          animationType="slide"
          transparent={true}
          onBackdropPress={onDate}
          isVisible={show}
        >
          <View style={styles.modalView}>
            {show && (
              <DateTimePicker
                {...field}
                {...props}
                locale="ru-RU"
                value={date}
                style={styles.dateInput}
                textColor="black"
                mode="date"
                selected={(field.value && new Date(field.value)) || null}
                display="spinner"
                onChange={onChange}
              />
            )}
            <Button title="Выбрать" style={styles.button} onPress={() => onDate()} />
          </View>
        </Modal>
      )}
      {Platform.OS === 'web' &&
      <DatePickerModal locale="ru"
        mode="single"
        visible={show}
        onDismiss={() => setShow(false)}
        date={date}
        inputEnabled={false}
        editIcon={undefined}
        onConfirm={(e) => onChange(null, e.date)}
        onChange={(e) => onChange(null, e.date)}/>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 20,
    elevation: 2,
    padding: 10
  },
  centeredView: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginTop: 22
  },
  dateInput: {

    borderBottomColor: '#4F4F4F',
    fontSize: 14,
    padding: 10,
    width: 240
  },
  hidden: {
    display: 'none'
  },
  modalView: {
    alignItems: 'center',
    backgroundColor: 'white',

    borderRadius: 20,
    justifyContent: 'center',
    padding: 35,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4
  }
})
