import React, { useState } from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { THEME } from '../../../theme'

export const SCLabel = ({
  onChangeText,
  onBlur,
  iconPosition,
  placeholder,
  icon,
  style,
  value,
  label,
  error,
  disabled = false,
  ...props
}) => {
  const getFlexDirection = () => {
    const [focused, setFocused] = React.useState(false)

    if (icon && iconPosition) {
      if (iconPosition === 'left') {
        return 'row'
      } else if (iconPosition === 'right') {
        return 'row-reverse'
      }
    }
  }
  const getBorderColor = () => {}

  return (
    <View>
      {label && <Text style={styles.label}>{label}</Text>}

      <View
        style={[
          styles.wrapper,
          { alignItems: icon ? 'center' : 'baseline' },
          { borderColor: getBorderColor(), flexDirection: getFlexDirection() }
        ]}
      >
        <View>{icon && icon}</View>

        <View style={[styles.textInput, style]} {...props}>
          <Text>{value}</Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  error: {
    // color: colors.danger,
    paddingTop: 4,
    fontSize: 12
  },

  label: {
    color: THEME.MAIN_DARK,
    marginBottom: 10
  },

  textInput: {
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 1,
    fontSize: 14,
    paddingLeft: 18,
    paddingVertical: 18,
    width: 300
  },

  wrapper: {}
})
