// user
export const USER_LOGIN = 'USER_LOGIN'
export const USER_UPDATE = 'USER_UPDATE'
export const USER_LOGOUT = 'USER_LOGOUT'
// admin
// export const ADD_PROMO_COD = "ADD_PROMO_COD";
// export const DELETE_PROMO_COD = "DELETE_PROMO_COD";
export const UPDATE_PRICE = 'UPDATE_PRICE'
export const GET_PROMO_CODS = 'GET_PROMO_CODS'
export const GET_COUNT_USE_PROMO_COD = 'GET_COUNT_USE_PROMO_COD'
export const GET_USERS_USE_PROMO_COD = 'GET_USERS_USE_PROMO_COD'
// wheel
export const WHEEL_GET_FROM_API = 'WHEEL_GET_FROM_API'
export const WHEEL_UPDATE = 'WHEEL_UPDATE'
// diary
export const DIARY_GET_ACTUAL_FROM_API = 'DIARY_GET_ACTUAL_FROM_API'
export const DIARY_GET_FINISHED_FROM_API = 'DIARY_GET_FINISHED_FROM_API'
export const DIARY_UPDATE = 'DIARY_UPDATE'
// targets
export const TARGETS_GET_ACTUAL_FROM_API = 'TARGETS_GET_ACTUAL_FROM_API'
export const TARGETS_GET_FINISHED_FROM_API = 'TARGETS_GET_FINISHED_FROM_API'
export const TARGET_UPDATE = 'TARGET_UPDATE'
// inside
export const INSIDE_GET_FROM_API = 'INSIDE_GET_FROM_API'
export const INSIDE_UPDATE = 'INSIDE_UPDATE'
// exercise
export const EXERCISE_GET_LIST_FROM_API = 'EXERCISE_GET_LIST_FROM_API'
export const EXERCISE_GET_TASKS_FROM_API = 'EXERCISE_GET_TASKS_FROM_API'
export const EXERCISE_UPDATE = 'EXERCISE_UPDATE'
export const EXERCISE_GET_TEACH_FROM_API = 'EXERCISE_GET_TEACH_FROM_API'
// archive
export const ARCHIVE_GET_GROUP_LIST = 'ARCHIVE_GET_GROUP_LIST'
export const ARCHIVE_GET_GROUP_ITEM = 'ARCHIVE_GET_GROUP_ITEM'
export const ARCHIVE_ADD_GROUP_ITEM = 'ARCHIVE_ADD_GROUP_ITEM'
export const ARCHIVE_UPDATE_GROUP_ITEM = 'ARCHIVE_UPDATE_GROUP_ITEM' // STATE ONLY
export const ARCHIVE_ADD_PHOTO_ITEM = 'ARCHIVE_ADD_PHOTO_ITEM'
export const ARCHIVE_GET_PHOTO_ITEM = 'ARCHIVE_GET_PHOTO_ITEM'
export const ARCHIVE_CLEAR_PHOTO_ITEM = 'ARCHIVE_CLEAR_PHOTO_ITEM' // STATE ONLY
export const ARCHIVE_GET_PHOTO_LIST = 'ARCHIVE_GET_PHOTO_LIST'
// system
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const SHOW_ERROR = 'SHOW_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const SHOW_SUCCESS = 'SHOW_SUCCESS'
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS'
