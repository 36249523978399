import React from 'react'
import { View, Platform, ImageBackground } from 'react-native'
export const Animation = ({ navigation }) => {
  const timeoutID = setTimeout(() => {
    navigation.navigate('AfterRegistration')
  }, 3000)

  return (
    <View style={Platform.OS === 'web' && { minHeight: '100%' }}>
      <ImageBackground
        resizeMode="cover"
        style={{
          width: '100%',
          height: '100%'
        }}
        source={require('../../../../assets/animation.gif')}
      />
    </View>
  )
}

Animation.navigationOptions = {
  headerTitle: 'Загрузка',
  headerShown: false,
  headerLeft: () => null
}

export default Animation
