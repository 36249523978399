import React, { useContext, useRef, useEffect } from 'react'
import { View, StyleSheet, ScrollView, Alert, Platform } from 'react-native'
import { AppLoader } from '../../../components/ui/AppLoader'
import { PhotoPicker } from '../../../components/PhotoPicker'
import { SCButton } from '../../../components/ui/button/SCButton'
import { ArchiveContext } from '../../../context/archive/archiveContext'

export const PhotoAddScreen = ({ navigation }) => {
  const { error, success, clearError, clearSuccess, addPhoto, loading } =
    useContext(ArchiveContext)
  const imgRef = useRef()

  const currentId = navigation.getParam('groupId')

  const apply = () => {
    const url = imgRef.current
    let data = {}
    if (Platform.OS === 'web') {
      const parts = url.split(',')
      const contentType = parts[0].split(':')[1].split(';')[0]
      const raw = atob(parts[1])
      const extName = contentType.split('/')[1]

      const rawLength = raw.length
      const uInt8Array = new Uint8Array(rawLength)

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }

      const blob = new Blob([uInt8Array], { type: contentType })

      data = {
        name: `image.${extName}`,
        photo: blob,
        groupId: currentId,
        type: contentType
      }
    } else {
      data = {
        name: url.substring(url.lastIndexOf('/') + 1),
        photo: url,
        groupId: currentId
      }
    }
    addPhoto(data)
  }

  const photoPickHandler = (uri) => {
    imgRef.current = uri
  }

  useEffect(() => {
    if (error) {
      if (Platform.OS === 'web') {
        alert(error)
        clearError()
      } else {
        Alert.alert('Ошибка!', error, [
          {
            text: 'Ок',
            onPress: () => {
              clearError()
            },
            style: 'cancel'
          }
        ])
      }
    }
  }, [error])

  useEffect(() => {
    if (success) {
      if (Platform.OS === 'web') {
        alert('Файл успешно добавлен')
        clearSuccess()
        navigation.navigate('GroupView', { currentGroupId: currentId })
      } else {
        Alert.alert('Успешно!', 'Файл успешно добавлен', [
          {
            text: 'Ок',
            onPress: () => {
              clearSuccess()
              navigation.navigate('GroupView', { currentGroupId: currentId })
            },
            style: 'default'
          }
        ])
      }
    }
  }, [success])

  if (loading) {
    return <AppLoader />
  }

  return (
    <View style={styles.center}>
      <View style={styles.bodyForm}>
        <ScrollView style={styles.scroller}>
          <View style={styles.form}>
            <PhotoPicker onPick={photoPickHandler}></PhotoPicker>
          </View>
        </ScrollView>
        <View style={styles.actionsForm}>
          <SCButton text="Добавить" onPress={apply} />
        </View>
      </View>
    </View>
  )
}

PhotoAddScreen.navigationOptions = ({ navigation }) => {
  return {
    headerTitle: 'Добавление фотографии'
  }
}
const styles = StyleSheet.create({
  actionsForm: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  body: {},
  bodyForm: {
    flex: 1
  },
  center: {
    display: 'flex',
    flex: 1,
    paddingVertical: 40,
    width: '100%'
  },
  form: {
    flex: 1,
    width: '100%'
  },
  formContent: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  marginButton: {
    marginBottom: 41
  },
  scroller: {
    height: 900
  }
})
