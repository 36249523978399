import React, { useState } from 'react'
import AppLoading from 'expo-app-loading'
import { bootstrap } from './src/bootstrap'
import { MainLayout } from './src/MainLayout'
import { UserState } from './src/context/user/UserState'
import { WheelState } from './src/context/wheel/WheelState'
import { DiaryState } from './src/context/diary/DiaryState'
import { InsideState } from './src/context/inside/InsideState'
import { ExerciseState } from './src/context/exercise/ExerciseState'
import { ArchiveState } from './src/context/archive/ArchiveState'
import { Text } from 'react-native'
import { AdminState } from './src/context/admin/adminState'

export default function App () {
  const [isReady, setIsReady] = useState(false)

  Text.defaultProps = Text.defaultProps || {}
  Text.defaultProps.allowFontScaling = false

  if (!isReady) {
    return (
      <AppLoading
        startAsync={bootstrap}
        onFinish={() => setIsReady(true)}
        onError={(err) => console.log(err)}
      />
    )
  }

  return (
    <UserState>
      <AdminState>
        <ArchiveState>
          <ExerciseState>
            <WheelState>
              <DiaryState>
                <InsideState>
                  <MainLayout />
                </InsideState>
              </DiaryState>
            </WheelState>
          </ExerciseState>
        </ArchiveState>
      </AdminState>
    </UserState>
  )
}
