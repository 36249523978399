import React, { useContext, useReducer } from 'react'
import {
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  EXERCISE_GET_LIST_FROM_API,
  EXERCISE_GET_TEACH_FROM_API,
  EXERCISE_GET_TASKS_FROM_API,
  HIDE_LOADER,
  SHOW_ERROR,
  SHOW_LOADER,
  SHOW_SUCCESS
} from '../types'
import { Http } from '../../services/http'
import { getData } from '../../services/storage.service'
import { CONFIG } from '../../config'
import { exerciseReducer } from './exerciseReducer'
import { ExerciseContext } from './exerciseContext'
import { UserContext } from '../user/userContext'

export const ExerciseState = ({ children }) => {
  const initialState = {
    exerciseTeach: [],
    exercise: [],
    exerciseTasks: [],
    loading: false,
    success: false,
    error: null
  }
  const [state, dispatch] = useReducer(exerciseReducer, initialState)
  const { userLogout } = useContext(UserContext)

  const getExerciseVideo = async (navigation) => {
    showLoader()

    try {
      let token = ''
      await getData('currentUser').then((response) => {
        console.log('responce', response)
        if (response) {
          token = response.token
        }
      })

      const responseData = await Http.get(`${CONFIG.API_URL}Exercise/getStartVideo`, token)
      console.log(responseData)

      if (responseData?.message === 'Unauthorized') {
        userLogout(navigation)
        return
      }

      const exerciseTeach = [responseData[0], responseData[1]]
      console.log(exerciseTeach)
      dispatch({ type: EXERCISE_GET_TEACH_FROM_API, exerciseTeach })
    } catch (e) {
      console.log('ERRROR', e)
      //  showError("Что-то пошло не так...");
    }
    hideLoader()
    showSuccess()
  }

  const getExerciseList = async (navigation) => {
    showLoader()
    const { token } = await getData('currentUser')

    try {
      const responseData = await Http.get(`${CONFIG.API_URL}Exercise`, token)

      if (responseData?.message === 'Unauthorized') {
        userLogout(navigation)
        return
      }

      console.log(responseData)
      const exercise = []
      let idCounter = 0
      for (const item of responseData) {
        let foundedGroup = exercise.find((x) => x.level === item.level)

        if (!foundedGroup) {
          foundedGroup = {
            id: idCounter++,
            level: item.level,
            title: item.level === 0 ? 'Знакомство' : `Уровень ${item.level}`,
            items: []
          }
          exercise.push(foundedGroup)
        }

        foundedGroup.items.push({
          exerciseNumber: item.exerciseNumber,
          id: item.id,
          isBuyed: item.isBuyed,
          name: item.name,
          isWatched: item.isWatched,
          level: item.level
        })
      }

      dispatch({ type: EXERCISE_GET_LIST_FROM_API, exercise })
    } catch (e) {
      console.log('ERRROR', e)
    } finally {
      hideLoader()
    }
  }

  const getExerciseTasksList = async (id) => {
    // Показываем загрузку
    showLoader()
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      // Отправляем запрос на бек
      const response = await Http.get(`${CONFIG.API_URL}Exercise/${id}`, token)

      let exerciseTasks = []

      if (response?.status === 'Error') {
        dispatch({ type: EXERCISE_GET_TASKS_FROM_API, exerciseTasks })
        throw new Error(response?.message)
      } else {
        console.log('EXERCISE FROM API ', response)
        // Сохраняем в стейт
        exerciseTasks = response
        dispatch({ type: EXERCISE_GET_TASKS_FROM_API, exerciseTasks })
      }
    } catch (e) {
      console.log('ERRROR', e.message)
      showError(e.message)
    } finally {
      hideLoader()
    }
  }

  const completeTask = async (id, groupdId, navigation) => {
    console.log('Id: ' + id + '; GroupId: ' + groupdId + ';')
    showLoader()
    const { token } = await getData('currentUser')

    try {
      const response = await Http.put(
        `${CONFIG.API_URL}Exercise/${id}`,
        {},
        token
      )

      const newExercises = state.exercise.map(ex =>
        ex.level === groupdId
          ? {
            ...ex,
            items: ex.items.map(item => item.exerciseNumber === id ? response : item)
          }
          : ex)

      dispatch({ type: EXERCISE_GET_LIST_FROM_API, exercise: newExercises })
    } catch (e) {
      console.log('ERRROR', e)
    } finally {
      hideLoader()
    }

    hideLoader()
    navigation.navigate('Main', { forceRender: Math.random() })
  }

  const showLoader = () => dispatch({ type: SHOW_LOADER })

  const hideLoader = () => dispatch({ type: HIDE_LOADER })

  const showSuccess = () => dispatch({ type: SHOW_SUCCESS })

  const clearSuccess = () => dispatch({ type: CLEAR_SUCCESS })

  const showError = (error) => dispatch({ type: SHOW_ERROR, error })

  const clearError = () => dispatch({ type: CLEAR_ERROR })

  return (
    <ExerciseContext.Provider
      value={{
        exerciseTeach: state.exerciseTeach,
        exercise: state.exercise,
        exerciseTasks: state.exerciseTasks,
        loading: state.loading,
        success: state.success,
        error: state.error,
        getExerciseList,
        showSuccess,
        clearSuccess,
        showError,
        clearError,
        getExerciseTasksList,
        completeTask,
        getExerciseVideo
      }}
    >
      {children}
    </ExerciseContext.Provider>
  )
}
