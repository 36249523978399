import React, { useReducer } from 'react'
import { CONFIG } from '../../config'
import { Http } from '../../services/http'
import { getData, removeData, storeData } from '../../services/storage.service'
import {
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  HIDE_LOADER,
  SHOW_ERROR,
  SHOW_LOADER,
  SHOW_SUCCESS,
  USER_LOGIN,
  USER_LOGOUT,
  USER_UPDATE
} from '../types'
import { UserContext } from './userContext'
import { userReducer } from './userReducer'
import alertMultiplatform from '../../utils/alertMultiplatform'

export const UserState = ({ children }) => {
  const initialState = {
    user: null,
    loading: false,
    error: null,
    success: false
  }
  const [state, dispatch] = useReducer(userReducer, initialState)

  const userFill = (user) => {
    dispatch({ type: USER_LOGIN, user })
  }

  const userUpdate = async (id, values) => {
    // Показываем загрузку
    showLoader()
    // Очищаем ошибки
    clearError()
    let responseResult = false
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    //   const fullUser = await Http.get(`${CONFIG.API_URL}Users/${id}`, token);
    //   console.log("FULL USER IS: ", fullUser);
    try {
      const requestData = {
        id,
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        profession: values.profession,
        gender: values.gender,
        dateBirthday: values.dateBirthday
      }
      // Отправляем запрос на бек, получаем объект пользователя
      const data = await Http.put(`${CONFIG.API_URL}Users`, requestData, token)
      // Если ошибка, возвращаем ошибку
      if (data.error) {
        showError(data.error)
        return
      }

      if (data?.errors) {
        responseResult = false
        for (const [key, value] of Object.entries(data.errors)) {
          data.errors[key].forEach((error) => {
            errorMessage += `${error} `
          })
        }
        console.log('error messages: ', errorMessage)
        showError(errorMessage || 'Что-то пошло не так...')
        hideLoader()
        return
      } else if (data?.status === 'Error') {
        responseResult = false
        for (const [key, value] of Object.entries(data.message)) {
          data.message[key].forEach((error) => {
            errorMessage += `${error}; `
          })
        }
        console.log('error messages: ', errorMessage)
        showError(errorMessage || 'Что-то пошло не так...')
        hideLoader()
        return
      }

      const user = { ...state.user, ...data }
      console.log('USER RESPONSE: ', user)
      if (user.status === 'Error') {
        responseResult = false
        throw new Error()
      }
      // Сохраняем в стейт
      storeData('currentUser', user)
      dispatch({ type: USER_UPDATE, user })
      responseResult = true
    } catch (e) {
      responseResult = false
    } finally {
      hideLoader()
    }

    if (responseResult) {
      showSuccess()
    } else {
      showError('Что-то пошло не так...')
    }
  }

  // Авторизация
  const userLogin = async (values, navigation) => {
    let responseResult = false
    let token
    let role
    // Показываем загрузку
    showLoader()
    // Очищаем ошибки
    clearError()
    try {
      // Отправляем запрос на бек, получаем объект пользователя

      console.log('1')

      const data = await Http.post(
        `${CONFIG.API_URL}Users/authenticate`,
        values
      )
      // Если ошибка, возвращаем ошибку

      if (data.error) {
        showError(data.error)
        return
      }

      console.log('2')
      const user = data
      token = user.token
      role = user.roleId
      console.log('USER RESPONSE: ', user)

      console.log('3')
      // Если токена есть, сохраняем его в локальное хранилище
      storeData('currentUser', user)
      // Сохраняем в стейт
      dispatch({ type: USER_LOGIN, user })
      // Если пользователь зашел с нового устойства или в первый раз - редиректим на степер, с информацией о приложении
      console.log('4')
      if (user.token) {
        responseResult = true
      }
    } catch (e) {
      responseResult = false
    }

    if (responseResult) {
      console.log('5')
      getData('isUserExist').then(async (isUserExist) => {
        console.log('is', isUserExist)
        console.log('6')
        const checkPay = await Http.get(
          `${CONFIG.API_URL}Payment/get_state/${1}`,
          token
        )
        console.log(checkPay)
        console.log('checkPay(UserState): ' + checkPay.Status)
        hideLoader()
        if (role === 1) {
          navigation.navigate('Admin')
        } else if (checkPay.status === 'CONFIRMED' && role === 2) {
          navigation.navigate('App')
        } else if (role === 2) {
          navigation.navigate('Loading')
        }
        //  if (isUserExist === true) {
        // Иначе отправляем на главную страницу

        // } else {
        //   navigation.navigate("Greetings");
        // }
      })
    } else {
      hideLoader()
      showError('Что-то пошло не так...')
    }
  }

  const recoverPassword = async (values, navigation) => {
    showLoader()
    clearError()
    console.log(values)
    try {
      const data = await Http.post(
        `${CONFIG.API_URL}Users/ChangePassword`,
        values
      )
      // Если ошибка, возвращаем ошибку

      console.log('66')
      if (data.status === 'Error') {
        // showError(data.error);
        showError(
          'Такого пользователя не существует либо email введён неверно'
        )
        // return;
      } else {
        console.log('GHHHHHHHHHH')
      }
      if (data.status !== 'Error') {
        console.log('truue')
        alertMultiplatform({
          title: 'Успех!',
          message: 'Пароль успешно изменён!'
        })
      }
    } catch (e) {
      // responseResult = false;
      navigation.navigate('Login')
    } finally {
      console.log('fin')

      hideLoader()
    }
  }

  // Регистрация
  const userRegister = async (values, navigation) => {
    let responseResult = false
    let errorMessage = 'Ошибка! '
    const example = values
    delete example.isAgree
    delete example.isDateExit
    example.id = 0
    console.log('REQUSET', values)
    // Показываем загрузку
    showLoader()
    // Очищаем ошибки
    // delete value.isAgree;
    // delete value.isDateExit;
    clearError()
    try {
      // Отправляем запрос на бек, получаем объект пользователя
      const data = await Http.post(
        `${CONFIG.API_URL}Users/registration`,
        values
      )

      console.log('RESULT', data)

      if (data?.errors) {
        responseResult = false
        for (const [key, value] of Object.entries(data.errors)) {
          data.errors[key].forEach((error) => {
            errorMessage += `${error} `
          })
        }
        console.log('error messages: ', errorMessage)
        showError(errorMessage || 'Что-то пошло не так...')
      } else if (data?.status === 'Error') {
        responseResult = false
        for (const [key, value] of Object.entries(data.message)) {
          data.message[key].forEach((error) => {
            errorMessage += `${error}; `
          })
        }
        console.log('error messages: ', errorMessage)
        showError(errorMessage || 'Что-то пошло не так...')
      } else {
        responseResult = true
      }
    } catch (e) {
      responseResult = false
    } finally {
      hideLoader()
    }

    if (responseResult) {
      await userLogin(
        { username: values.email, password: values.password },
        navigation
      )
      showSuccess()
    }
    // else {

    //   showError(errorMessage ? errorMessage : "Что-то пошло не так...");
    // }
  }

  const userChangePassword = async (values, navigation) => {
    // Показываем загрузку
    showLoader()
    // Очищаем ошибки
    clearError()
    let token = ''
    let role = ''
    const onlyPassword = { password: values.password }
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })

    try {
      console.log('POST 33333333333333')
      const response = await Http.post(
        `${CONFIG.API_URL}Users/UpdatePassword`,
        onlyPassword,
        token
      )

      // Если ошибка, возвращаем ошибку
      if (response.status === 'Error') {
        console.log('err')
        showError('Что-то пошло не так...')
        // throw new Error();
      }
      if (response.status !== 'Error') {
        console.log('truue')
        alertMultiplatform({
          title: 'Успех!',
          message: 'Пароль успешно изменён!'
        })
      }
      const user = response
      console.log('RESPONSE', response)
      token = user.token
      role = user.roleId
      console.log('USER RESPONSE: ', user)

      console.log('3')
      // Если токена есть, сохраняем его в локальное хранилище
      storeData('currentUser', user)
      // Сохраняем в стейт
      dispatch({ type: USER_LOGIN, user })
    } catch (e) {
      console.log('CATCH')
      console.log(e)
    } finally {
      hideLoader()
      // navigation.navigate("Login");
    }
  }

  const userLogout = async (navigation) => {
    navigation.navigate('Auth')
    removeData('currentUser')
    dispatch({ type: USER_LOGOUT })
  }

  // export const FAQ_GET_LIST = 'FAQ_GET_LIST';
  // Отправка ошибки на АПИ
  const sendIssue = async (data) => {
    // Показываем загрузку
    showLoader()
    // Очищаем ошибки
    clearError()
    let responseResult = false
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })
    try {
      const response = await Http.post(
        `${CONFIG.API_URL}IssueReport`,
        data,
        token
      )

      // Если ошибка, возвращаем ошибку
      if (response.status === 'Error') {
        responseResult = false
        throw new Error()
      }

      responseResult = true
    } catch (e) {
      responseResult = false
    } finally {
      hideLoader()
    }

    if (responseResult) {
      showSuccess()
    } else {
      showError('Что-то пошло не так...')
    }
  }

  // Получение списка типов Ошибок
  const getIssueTypes = async () => {
    let result
    // Показываем загрузку
    showLoader()
    // Очищаем ошибки
    clearError()
    let responseResult = false
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })
    try {
      result = await Http.get(`${CONFIG.API_URL}IssueReport/types`, token)
    } catch (e) {
      responseResult = false
    } finally {
      hideLoader()
    }

    return result
  }

  // Получение списка FAQ
  const getFAQList = async () => {
    let result
    // Показываем загрузку
    showLoader()
    // Очищаем ошибки
    clearError()
    let responseResult = false
    let token = ''
    await getData('currentUser').then((response) => {
      if (response) {
        token = response.token
      }
    })
    try {
      result = await Http.get(`${CONFIG.API_URL}FaqInfo`, token)
    } catch (e) {
      responseResult = false
    } finally {
      hideLoader()
    }

    return result
  }

  const getUserInfo = async (id) => {
    let responseResult = false
    // Показываем загрузку
    showLoader()
    // Очищаем ошибки
    clearError()
    try {
      let token = ''
      await getData('currentUser').then((response) => {
        if (response) {
          token = response.token
        }
      })
      // Отправляем запрос на бек, получаем объект пользователя
      const data = await Http.get(`${CONFIG.API_URL}Users/${id}`, token)

      console.log('FROM API: ', data)

      const user = { ...state.user, ...data }
      console.log('USER RESPONSE: ', user)
      dispatch({ type: USER_UPDATE, user })
    } catch (e) {
      responseResult = false
    } finally {
      hideLoader()
    }
  }

  const showLoader = () => dispatch({ type: SHOW_LOADER })

  const hideLoader = () => dispatch({ type: HIDE_LOADER })

  const showError = (error) => dispatch({ type: SHOW_ERROR, error })

  const clearError = () => dispatch({ type: CLEAR_ERROR })

  const showSuccess = () => dispatch({ type: SHOW_SUCCESS })

  const clearSuccess = () => dispatch({ type: CLEAR_SUCCESS })

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        error: state.error,
        success: state.success,
        clearError,
        userLogin,
        recoverPassword,
        userRegister,
        userChangePassword,
        getUserInfo,
        userFill,
        userLogout,
        clearSuccess,
        userUpdate,
        sendIssue,
        getIssueTypes,
        getFAQList
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
