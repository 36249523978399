import React from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'

const PrimaryBtn = ({ onPress, title }) => (
  <TouchableOpacity onPress={onPress} style={styles.button}>
    <Text style={styles.text}>{title}</Text>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#2196f3',
    borderRadius: 5,
    height: 40,
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingVertical: 10,
    width: '100%'
  },
  text: {
    color: '#fff',
    fontSize: 16
  }
})

export default PrimaryBtn
