import React, { useEffect, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import {
  View,
  Button,
  Platform,
  StyleSheet,
  Text,
  Alert,
  TouchableOpacity
} from 'react-native'

import { Picker } from '@react-native-picker/picker'
import Select from 'react-select'
import inputStyles from '../../styles/inputStyles'
import Modal from 'react-native-modal'
import { AppLoader } from '../../components/ui/AppLoader'

export const DropdownField = ({ checkerDisable = false, loading = false, ...props }) => {
  const [option, setOption] = useState('')
  const [show, setShow] = useState(false)
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)

  useEffect(() => {
    if (props.value) {
      setOption(props.value)
    }
  }, [])

  const selectOptionDefault = () => {
    const selectedOption = props?.items[0]?.value
    setOption(selectedOption)
    setFieldValue(field.name.toString(), +selectedOption)
    props.onSelectOption?.(selectedOption)
  }

  useEffect(() => {
    if (checkerDisable === false) {
      console.log('props are ', props.items)
      setOption('')
    }
  }, [props.items])

  const onChange = (selectedOption) => {
    console.log('SELECTED OPTION', selectedOption)
    console.log('FIELD', field.name)
    const currentOption = selectedOption || option
    setShow(Platform.OS === 'ios')
    setFieldValue(field.name.toString(), +selectedOption)
    setOption(props.items.find((item) => item.id === currentOption))
    props.onSelectOption?.(selectedOption)
  }

  const onSelect = () => {
    if (!option && props?.items) {
      selectOptionDefault()
    }
    setShow(!show)
  }

  if (loading) {
    return <AppLoader />
  }

  const dropdownItems = props.items.map((item, index) => {
    return <Picker.Item key={index} value={item.value} label={item.label} />
  })

  return (
    <View style={{
      zIndex: props.style?.zIndex ?? 10
    }}>
      {props.label && <Text style={inputStyles.label}>{props.label}</Text>}
      {Platform.OS === 'android' && show && (
        <Modal
          animationType="slide"
          transparent={true}
          isVisible={show}
          onBackdropPress={onSelect}
          onRequestClose={() => {
            const messageErr = 'Modal has been closed.'
            if (Platform.OS === 'web') {
              alert(messageErr)
            } else {
              Alert.alert(messageErr)
            }
            onSelect()
          }}
        >
          <View style={styles.androidCenteredView}>
            <View style={styles.androidModalView}>
              <Picker
                {...field}
                {...props}
                value={option}
                style={styles.dateInput}
                selectedValue={field.value}
                onValueChange={onChange}
                itemStyle={styles.dropdownLabel}
              >
                {dropdownItems}
              </Picker>
              <TouchableOpacity onPress={() => onSelect()}>
                <Text>Закрыть</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )}
      {Platform.OS === 'ios' && show && (
        <Modal
          animationType="slide"
          transparent={true}
          isVisible={show}
          onBackdropPress={onSelect}
        >
          <View style={styles.modalView}>
            <Picker
              {...field}
              {...props}
              value={option}
              style={styles.dateInput}
              selectedValue={field.value}
              onValueChange={onChange}
              itemStyle={styles.dropdownLabel}
            >
              {dropdownItems}
            </Picker>
            <Button title="Выбрать" onPress={() => onSelect()} />
          </View>
        </Modal>
      )}
      {Platform.OS === 'web' && (
        <View style={styles.androidCenteredView}>
          <Select
            value={option}
            styles={{
              control: (provided) => ({
                ...provided,
                ...inputStyles.userInput,
                ...styles.webDateInput,
                zIndex: 10,
                backgroundColor: 'transparent',
                textAlign: 'left'
              }),
              option: (provided) => ({ ...provided, ...styles.dropdownLabel, zIndex: 10, textAlign: 'left' }),
              singleValue: (provided) => ({ ...provided, fontFamily: 'regular', zIndex: 10, textAlign: 'left' }),
              placeholder: (provided) => ({ ...provided, fontFamily: 'regular', zIndex: 10, textAlign: 'left' }),
              clearIndicator: (provided) => ({ ...provided, fontFamily: 'regular', zIndex: 10, textAlign: 'left' }),
              noOptionsMessage: (provided) => ({ ...provided, fontFamily: 'regular', zIndex: 10, textAlign: 'left' }),
              dropdownIndicator: (provided) => ({ ...provided, fontFamily: 'regular', zIndex: 10, textAlign: 'left' }),
              indicatorSeparator: (provided) => ({ display: 'none' }),
              menu: (provided) => ({ ...provided, zIndex: 100, fontFamily: 'regular', textAlign: 'left' })
            }}
            noOptionsMessage={() => 'Нет данных'}
            placeholder={props.label}
            onChange={({ value }) => onChange(value)}
            options={props.items}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  dateInput: {
    borderBottomColor: '#4F4F4F',
    fontSize: 14,
    height: 200,
    marginLeft: 0,
    width: 320
  },
  webDateInput: {
    fontFamily: 'regular',
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 0
  },
  hidden: {
    display: 'none'
  },
  dropdownLabel: {
    fontFamily: 'regular',
    fontWeight: 'normal',
    textAlign: 'center'
  },
  centeredView: {
    alignItems: 'flex-end',
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: 22
  },
  modalView: {
    alignItems: 'center',
    backgroundColor: 'white',

    borderRadius: 20,
    elevation: 5,
    justifyContent: 'center',
    padding: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4
  },
  button: {
    borderRadius: 20,
    elevation: 2,
    padding: 10
  },
  // android modal
  androidCenteredView: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    zIndex: 10000000000
  },
  androidModalView: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    elevation: 5,
    height: '40%',
    marginHorizontal: 10,
    padding: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    width: '100%'
  },
  webModalView: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    elevation: 5,
    marginHorizontal: 10,
    padding: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    width: '100%'
  }
})
