import React from 'react'
import { Video } from 'expo-av'
import * as ScreenOrientation from 'expo-screen-orientation'

export const AndroidVideo = ({ video, task, setStatus }) => {
  const onFullSize = async ({ fullscreenUpdate }) => {
    if (fullscreenUpdate === Video.FULLSCREEN_UPDATE_PLAYER_DID_PRESENT) {
      await ScreenOrientation.unlockAsync()
    } else if (
      fullscreenUpdate === Video.FULLSCREEN_UPDATE_PLAYER_WILL_DISMISS
    ) {
      await ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock.PORTRAIT
      )
    }
  }

  return (
    <Video
      ref={video}
      source={{
        uri: `${task.url}`,
        headers: {
          'User-Agent': '4E38221A-8BF3-4302-8C49-0F6E5B91B64B'
        }
      }}
      useNativeControls={true}
      resizeMode="contain"
      style={{ width: '100%', height: '100%' }}
      isLooping={false}
      onPlaybackStatusUpdate={(status) => setStatus(status)}
      onFullscreenUpdate={onFullSize}
    />
  )
}
