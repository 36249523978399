import React, { useEffect, useRef, useState } from 'react'
import * as ImagePicker from 'expo-image-picker'
import * as Permissions from 'expo-permissions'
import {
  View,
  StyleSheet,
  Image,
  Alert,
  TouchableOpacity,
  Modal,
  Pressable,
  Text,
  Platform,
  TouchableWithoutFeedback
} from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { THEME } from '../theme'
import * as FileSystem from 'expo-file-system'
import { BlurView } from 'expo-blur'
import { useDropzone } from 'react-dropzone'

export const getFileInfo = async (fileURI) => {
  if (Platform.OS === 'web') {
    const file = new File([fileURI], 'filename')

    const fileInfo = {
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified
    }

    return fileInfo
  } else {
    const fileInfo = await FileSystem.getInfoAsync(fileURI)
    return fileInfo
  }
}

async function askForPermissions () {
  if (Platform.OS === 'web') {
    try {
      // Request permission to access the camera
      await navigator.mediaDevices.getUserMedia({ video: true })
      // If we reach this point, permission was granted
      return true
    } catch (error) {
      // If we reach this point, permission was not granted
      alert('Вы не дали прав на создание фото')
      return false
    }
  } else {
    const { status } = await Permissions.askAsync(
      Permissions.CAMERA,
      Permissions.CAMERA_ROLL,
      Permissions.MEDIA_LIBRARY,
      Permissions.MEDIA_LIBRARY_WRITE_ONLY
    )

    if (status !== 'granted') {
      if (Platform.OS === 'web') {
        alert('Вы не дали прав на создание фото')
      } else {
        Alert.alert('Ошибка', 'Вы не дали прав на создание фото')
      }
      return false
    }
    return true
  }
}

export const PhotoPicker = ({ onPick }) => {
  const [image, setImage] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        const imageData = e.target.result
        setImage(imageData)
        onPick(imageData)
        setModalVisible(false)
      }
      reader.readAsDataURL(file)
    }
  })

  const inputRef = useRef(null)

  useEffect(() => {
    (async () => {
      await askForPermissions()
    })()
  }, [])

  const takePhoto = async () => {
    const hasPermissions = await askForPermissions()
    const quality = Platform.OS === 'android' ? 1 : 0
    console.log('Qual', quality)
    if (!hasPermissions) {
      return
    }
    try {
      const img = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality,
        allowsEditing: false,
        aspect: [16, 9]
      })
      if (!img.canceled) {
        console.log('IMG IS: ', img)
        const fileInfo = await getFileInfo(img.assets[0].uri)
        console.log('IMG SIZE: ', fileInfo.size / 1024 / 1024)

        setImage(img.assets[0].uri)
        onPick(img.assets[0].uri)
      }
    } catch (error) {
      console.log({ error })
    }
    setModalVisible(false)
  }

  const takeGallery = async () => {
    const img = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: Platform.OS === 'android' ? 1 : 0,
      allowsEditing: false,
      aspect: [16, 9]
    })

    if (!img.canceled) {
      console.log('IMG IS: ', img)
      const fileInfo = await getFileInfo(img.assets[0].uri)
      console.log('IMG SIZE: ', fileInfo.size / 1024 / 1024)

      setImage(img.assets[0].uri)
      onPick(img.assets[0].uri)
    }
  }

  const onGallery = () => {
    setModalVisible(false)
    takeGallery()
  }

  const onCamera = () => {
    if (Platform.OS === 'web' && inputRef.current) {
      inputRef.current.click()
    } else {
      takePhoto()
    }
  }

  const onTakePhoto = () => {
    setModalVisible(true)
  }

  return (
    <View style={styles.wrapper}>
      <TouchableOpacity onPress={onTakePhoto}>
        {image && (
          <Image
            style={styles.image}
            source={{ uri: image }}
            resizeMode={Platform.OS === 'ios' ? 'contain' : 'stretch'}
          />
        )}
        {!image && (
          <View style={styles.image}>
            <Ionicons name="camera-outline" size={48} color="black" />
          </View>
        )}
      </TouchableOpacity>

      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible)
        }}
      >
        <TouchableWithoutFeedback onPress={() => {
          setModalVisible(!modalVisible)
        }}>
          <BlurView intensity={90} style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={styles.actions}>
                <Pressable style={styles.firstButton} onPress={onGallery}>
                  <Text style={styles.button}>Выбрать из галлереи</Text>
                </Pressable>
                {Platform.OS === 'web' && <div {...getRootProps()} style={{ display: 'none' }}>
                  <input {...getInputProps()} ref={inputRef} />
                </div>}
                <TouchableOpacity onPress={onCamera}>
                  <Text style={styles.button}>Сделать фото</Text>
                </TouchableOpacity>
              </View>
            </View>
          </BlurView>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  actions: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 30,
    marginTop: 30
  },
  button: {
    color: THEME.MAIN_DARK
  },
  centeredView: {
    backgroundColor: THEME.MAIN_DARK,
    height: '100%',
    justifyContent: 'center',
    padding: 26,
    position: 'absolute',
    width: '100%'
  },
  firstButton: {
    marginBottom: 24
  },
  image: {
    //  flex: 1,
    height: 300,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center'
  },
  modalView: {
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 3,
    margin: 20,
    padding: 35
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  wrapper: {
    alignSelf: 'center',
    marginBottom: 10,
    marginTop: 10,
    paddingHorizontal: 30,
    width: '100%'
  }
})
