import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  View,
  Text,
  StyleSheet,
  Button,
  ScrollView,
  Modal
} from 'react-native'
import { THEME } from '../../../theme'
import { TouchableOpacity } from 'react-native-gesture-handler'
import moment from 'moment'
import { DiaryContext } from '../../../context/diary/diaryContext'
import { AppLoader } from '../../../components/ui/AppLoader'

export const TargetsScreen = ({ navigation }) => {
  const {
    targetsActual,
    targetsFinished,
    getTargetsActualList,
    getTargetsFinishedList,
    loading
  } = useContext(DiaryContext)

  const loadActualDiary = useCallback(
    async () => await getTargetsActualList(),
    [getTargetsActualList]
  )
  const loadFinishedDiary = useCallback(
    async () => await getTargetsFinishedList(),
    [getTargetsFinishedList]
  )

  useEffect(() => {
    [loadActualDiary(), loadFinishedDiary()]
  }, [])

  //* Если выбран таб "Завершенные цели" - показывать список завершенных шагов
  //* Если выбран таб "Активные цели" - показывать список активных шагов
  const [activeTab, setActiveTab] = useState('activeSteps')

  const onEdit = (item) => {
    navigation.navigate('TargetView', {
      currentItem: item,
      viewType: 'inprogress'
    })
  }

  const onView = (item) => {
    navigation.navigate('TargetView', {
      currentItem: item,
      viewType: 'completed'
    })
  }

  const onSetActiveTab = (tabId) => {
    setActiveTab(tabId)
  }

  const activeSteps = (
    <ScrollView>
      <View style={styles.cardsList}>
        {targetsActual.length !== 0 &&
        targetsActual.map((item) => (
          <TouchableOpacity
            key={item.id}
            onPress={() => onEdit(item)}
            style={styles.card}
          >
            <View style={styles.rowInfo}>
              <View style={styles.cardDate}>
                <Text>{moment(item.deadlineDate).format('DD.MM.YYYY')}</Text>
              </View>
            </View>
            <View style={styles.rowActions}>
              <View style={styles.cardTarget}>
                <Text style={styles.description}>{item.description}</Text>
              </View>
              <View style={styles.action}>
                <Text>Посмотреть</Text>
              </View>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </ScrollView>
  )

  const completedSteps = (
    <ScrollView>
      <View style={styles.cardsList}>
        {targetsFinished.length !== 0 &&
        targetsFinished.map((item) => (
          <TouchableOpacity
            key={item.id}
            onPress={() => onView(item)}
            style={styles.card}
          >
            <View style={styles.rowInfo}>
              <View style={styles.cardDate}>
                <Text>{moment(item.deadlineDate).format('DD.MM.YYYY')}</Text>
              </View>
            </View>
            <View style={styles.rowActions}>
              <View style={styles.cardTarget}>
                <Text style={styles.description}>{item.description}</Text>
              </View>
              <View style={styles.action}>
                <Text>Посмотреть</Text>
              </View>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </ScrollView>
  )

  if (loading) {
    return <AppLoader />
  }

  return (
    <View style={styles.center}>
      <View style={styles.tabs}>
        <TouchableOpacity onPress={() => onSetActiveTab('activeSteps')}>
          <Text
            style={[
              styles.tabsElement,
              activeTab === 'activeSteps' ? styles.active : styles.default
            ]}
          >
            Активные Цели
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => onSetActiveTab('completedSteps')}>
          <Text
            style={[
              styles.tabsElement,
              styles.tabsElementLast,
              activeTab === 'completedSteps' ? styles.active : styles.default
            ]}
          >
            Завершенные Цели
          </Text>
        </TouchableOpacity>
      </View>
      {activeTab === 'activeSteps' && activeSteps}
      {activeTab === 'completedSteps' && completedSteps}
    </View>
  )
}

TargetsScreen.navigationOptions = ({ navigation }) => {
  return {
    headerTitle: 'Цели'
  }
}

const styles = StyleSheet.create({
  active: {
    color: '#3941F8'
  },
  card: {
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 20,
    padding: 16,
    width: '100%'
  },
  cardDate: {
    marginBottom: 10
  },
  cardsList: {
    height: '84%',
    overflow: 'scroll',
    padding: 20,
    paddingBottom: 140,
    width: '100%'
  },
  center: {
    display: 'flex',
    width: '100%'
  },
  default: {
    color: '#000000'
  },
  description: {
    maxWidth: 200
  },
  headerRight: {
    marginRight: 26
  },
  rowActions: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rowInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 40,
    width: '100%'
  },
  tabsElement: {
    padding: 20
    //   backgroundColor: 'black'
  },
  tabsElementLast: {
    marginLeft: 20
  }
})
