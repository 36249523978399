import React from 'react'
import { View, Text, StyleSheet, Button, TouchableOpacity } from 'react-native'
import { removeData } from '../../services/storage.service'
import { THEME } from '../../theme'

export const PracticeScreen = ({ navigation }) => {
  const onInsites = () => {
    navigation.navigate('Insites')
  }

  const onArchive = () => {
    navigation.navigate('Groups')
  }

  return (
    <View style={styles.center}>
      <TouchableOpacity style={styles.card} onPress={onInsites}>
        <Text>Инсайты</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.card} onPress={onArchive}>
        <Text>Архив</Text>
      </TouchableOpacity>
    </View>
  )
}

PracticeScreen.navigationOptions = {
  headerTitle: 'Дневник'
}

const styles = StyleSheet.create({
  card: {
    alignItems: 'center',
    backgroundColor: THEME.MAIN_GREY,
    borderColor: THEME.MAIN_DARK,
    borderRadius: 20,
    borderWidth: 2,
    height: 180,
    justifyContent: 'center',
    marginBottom: 15,
    width: '80%'
  },
  center: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 20
  }
})
